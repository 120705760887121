export const kinshipDgreesList = [
  {
    "codigo": "CGE",
    "descricao": "Cônjuge"
  },
  {
    "codigo": "CRO",
    "descricao": "Companheiro"
  },
  {
    "codigo": "PIM",
    "descricao": "Pai"
  },
  {
    "codigo": "MAE",
    "descricao": "Mãe"
  },
  {
    "codigo": "AVO",
    "descricao": "Avô"
  },
  {
    "codigo": "AVM",
    "descricao": "Avó"
  },
  {
    "codigo": "FIA",
    "descricao": "Filho (a)"
  },
  {
    "codigo": "TOA",
    "descricao": "Tio (a)"
  },
  {
    "codigo": "EDO",
    "descricao": "Enteado (a)"
  },
  {
    "codigo": "SOO",
    "descricao": "Sobrinho (a)"
  },
  {
    "codigo": "SOC",
    "descricao": "Sócio (a)"
  },
  {
    "codigo": "IRM",
    "descricao": "Irmão (a)"
  },
  {
    "codigo": "PRM",
    "descricao": "Primo (a)"
  },
  {
    "codigo": "NET",
    "descricao": "Neto (a)"
  },
  {
    "codigo": "OTR",
    "descricao": "Outros"
  }
]