export const getParams = (query: string) => {
  const parts = query.split('&');
  const cpf = parts[1].replaceAll('cpf=', '');
  const idProduct = parts[0].replace(/[^0-9,]/gi, '');
  const user = parts[2].replace(/[^0-9,]/gi, '');

  const fullParams = `product=${idProduct}&cpf=${cpf}&user=${user}`;

  return fullParams;
};
