import { createIcon } from '@chakra-ui/react';

export const AcidentesPessoaisIcon = createIcon({
  displayName: 'AcidentesPessoaisIcon',
  viewBox: '0 0 111 110',
  path: [
    <path
      key="1"
      d="M42.3973 2V32.4257M7.71091 2V32.4257M64.4887 83.5806C64.4887 83.5806 78.2635 81.9092 78.2635 93.2771C78.2635 104.645 64.4887 101.969 64.4887 101.969M44.9984 60.1721L3.99358 100.487M41.2828 32.4256L3.35676 69.7123M45.6751 79.5685H64.4895V106.986H19.1337C19.1337 106.986 2.33564 107.989 2 93.277V32.4257H45.6751V79.5685Z"
      stroke="#146CFA"
      fill="transparent"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ]
});
