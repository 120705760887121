import { Button } from '@chakra-ui/react';

type ButtonConfirmProps = {
  handleConfirm: () => void;
};

export const ButtonConfirm = ({ handleConfirm }: ButtonConfirmProps) => {
  return (
    <>
      <Button
        bg="#FF5000 "
        w='100%'
        m=" 0  !important"
        h="48px"
        p="13px 47px"
        gap="10px"
        borderRadius="0 !important"
        color="#FFFFFF"
        fontWeight="700"
        fontSize="1rem"
        lineHeight="1.375rem"
        textAlign="center"
        transition="0.4s"
        onClick={() => {
          handleConfirm();
        }}
        _disabled={{
          bg: '#D2D2D2'
        }}
        _hover={{
          WebkitFilter: ' brightness(60%)',
          transition: 'all 0.4s',
          WebkitTransition: 'all 0.4s'
        }}
        _active={{}}
        _focus={{
          '&:focus': {
            boxShadow: 'none !important'
          }
        }}
      >
        Continuar
      </Button>
    </>
  );
};
