import { createIcon } from '@chakra-ui/react';

export const AssistenciaResidencialIcon = createIcon({
  displayName: 'AssistenciaResidencialIcon',
  viewBox: '0 0 96 95',
  path: [
    <path
      key="1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.5 23.742V20C56.5 18.896 57.396 18 58.5 18H64.5C65.604 18 66.5 18.896 66.5 20V31.6L76.18 39.206C77.646 40.356 78.5 42.116 78.5 43.978V43.98C78.5 45.046 78.076 46.068 77.322 46.822C76.568 47.576 75.546 48 74.48 48H70.5V72.042C70.5 73.622 69.872 75.138 68.754 76.254C67.638 77.372 66.122 78 64.542 78C56.884 78 40.116 78 32.458 78C30.878 78 29.362 77.372 28.246 76.254C27.128 75.138 26.5 73.622 26.5 72.042V48H22.47C21.418 48 20.408 47.582 19.662 46.838C18.918 46.092 18.5 45.082 18.5 44.03V44.028C18.5 42.134 19.37 40.344 20.858 39.174C26.794 34.512 47.264 18.428 47.264 18.428C47.99 17.858 49.01 17.858 49.736 18.428L56.5 23.742ZM34.642 54H46.5V65.858C40.372 64.98 35.52 60.126 34.642 54ZM62.358 54C61.48 60.128 56.626 64.98 50.5 65.858V54H62.358ZM46.5 38.142V50H34.642C35.52 43.872 40.374 39.02 46.5 38.142ZM50.5 38.142C56.628 39.02 61.48 43.874 62.358 50H50.5V38.142Z"
      fill="white"
    />
  ]
});
