import { ApoioPsicologicoIcon } from '../assets/icons/assistencias/ApoioPsicologicoIcon';
import { AssistenciaFilhosIcon } from '../assets/icons/assistencias/AssistenciaFilhosIcon';
import { AssistenciaResidencialIcon } from '../assets/icons/assistencias/AssistenciaResidencialIcon';
import { CartaoAlimentacaoIcon } from '../assets/icons/assistencias/CartaoAlimentacaoIcon';
import { DescontosFarmaciaIcon } from '../assets/icons/assistencias/DescontosFarmaciaIcon';
import { FuneralFamiliarIcon } from '../assets/icons/assistencias/FuneralFamiliarIcon';
import { MedicoTelaIcon } from '../assets/icons/assistencias/MedicoTelaIcon';
import { SegundaOpiniaoMedica } from '../assets/icons/assistencias/SegundaOpiniaoMedicaIcon';
import { SorteiosMensaisIcon } from '../assets/icons/assistencias/SorteiosMensaisIcon';
import { VitimaCrimesIcon } from '../assets/icons/assistencias/VitimaCrimesIcon';
import { ClubeVantagemIcon } from '../assets/icons/beneficios/ClubeVantagemIcon';
import { FazTudoIcon } from '../assets/icons/beneficios/FazTudoIcon';
import { SorteioMensalIcon } from '../assets/icons/beneficios/SorteioMensalIcon';
import { AcessibilidadeFisicaIcon } from '../assets/icons/coberturas/AcessibilidadeFisicaIcon';
import { AcidenteMajoradaIcon } from '../assets/icons/coberturas/AcidenteMajoradaIcon';
import { AquisicaoJazigoIcon } from '../assets/icons/coberturas/AquisicaoJazigoIcon';
import { DespesaHospitalarIcon } from '../assets/icons/coberturas/DespesaHospitalarIcon';
import { DoencasGravesIcon } from '../assets/icons/coberturas/DoencasGravesIcon';
import { DoencaTerminalIcon } from '../assets/icons/coberturas/DoencaTerminalIcon';
import { FilhoPostumoIcon } from '../assets/icons/coberturas/FilhoPostumoIcon';
import { FuneralIcon } from '../assets/icons/coberturas/FuneralIcon';
import { InternacaoHospitalarIcon } from '../assets/icons/coberturas/InternacaoHospitalarIcon';
import { InvalidezIcon } from '../assets/icons/coberturas/InvalidezIcon';
import { MorteAcidenteIcon } from '../assets/icons/coberturas/MorteAcidenteIcon';
import { MorteConjugeIcon } from '../assets/icons/coberturas/MorteConjugeIcon';
import { MorteIcon } from '../assets/icons/coberturas/MorteIcon';

export const getIconByName = (iconName: string) => {
  const icons = {
    MorteIcon: MorteIcon,
    AquisicaoJazigoIcon: AquisicaoJazigoIcon,
    FilhoPostumoIcon: FilhoPostumoIcon,
    MorteAcidenteIcon: MorteAcidenteIcon,
    InternacaoHospitalarIcon: InternacaoHospitalarIcon,
    InvalidezAcidenteIcon: InvalidezIcon,
    AcessibilidadeFisicaIcon: AcessibilidadeFisicaIcon,
    FuneralIcon: FuneralIcon,
    DespesaHospitalarIcon: DespesaHospitalarIcon,
    DoencasGravesIcon: DoencasGravesIcon,
    FuneralFamiliarIcon: FuneralFamiliarIcon,
    AcidenteMajoradaIcon: AcidenteMajoradaIcon,
    DoencaTerminalIcon: DoencaTerminalIcon,
    MorteConjugeIcon: MorteConjugeIcon,
    SorteiosMensaisIcon: SorteiosMensaisIcon,
    DescontosFarmaciaIcon: DescontosFarmaciaIcon,
    MedicoTelaIcon: MedicoTelaIcon,
    CartaoAlimentacaoIcon: CartaoAlimentacaoIcon,
    ClubeVantagemIcon: ClubeVantagemIcon,
    VitimaCrimesIcon: VitimaCrimesIcon,
    SegundaOpiniaoMedica: SegundaOpiniaoMedica,
    FazTudoIcon: FazTudoIcon,
    AssistenciaFilhosIcon: AssistenciaFilhosIcon,
    AssistenciaResidencialIcon: AssistenciaResidencialIcon,
    ApoioPsicologicoIcon: ApoioPsicologicoIcon
  } as any;

  // console.log(icons[iconName]);
  return icons[iconName];
};
