import { createIcon } from '@chakra-ui/react';

export const BeneficiariosIcon = createIcon({
  displayName: 'BeneficiariosIcon',
  viewBox: '0 0 56 55',
  path: [
    <circle key="1" cx="28" cy="27.5" r="27.5" fill="#FF5000" />,
    <path
      key="2"
      d="M23.2791 26.0759C21.2508 26.0759 19.6065 27.7202 19.6065 29.7485C19.6065 31.7768 21.2508 33.4211 23.2791 33.4211C25.3074 33.4211 26.9517 31.7768 26.9517 29.7485C26.9517 27.7202 25.3074 26.0759 23.2791 26.0759ZM18.0393 29.7485C18.0393 26.8546 20.3852 24.5087 23.2791 24.5087C26.173 24.5087 28.5189 26.8546 28.5189 29.7485C28.5189 32.6424 26.173 34.9883 23.2791 34.9883C20.3852 34.9883 18.0393 32.6424 18.0393 29.7485Z"
      fill="white"
    />,
    <path
      key="3"
      d="M23.1413 35.1772C21.7753 35.1776 20.4513 35.6486 19.3921 36.5111C17.6849 38.5753 17.5797 40.575 17.3027 41.9126C17.2149 42.3364 16.8002 42.6088 16.3764 42.521C15.9526 42.4333 15.7753 42.0636 15.7754 41.6308C15.7758 40.485 15.9303 37.8114 18.4024 35.2958C19.7412 34.2057 21.4147 33.6104 23.1411 33.61C24.1259 33.61 25.1012 33.804 26.0111 34.1809C26.9209 34.5577 27.7477 35.1101 28.444 35.8065C29.1404 36.5029 29.6928 37.3296 30.0697 38.2394C30.4465 39.1493 30.6405 40.1244 30.6405 41.1093C30.6403 41.4467 30.618 41.7842 30.5736 42.1187C30.5167 42.5477 30.1228 42.8494 29.6938 42.7925C29.2648 42.7355 28.9631 42.3416 29.02 41.9126C29.0553 41.6462 29.0731 41.3777 29.0733 41.1089M23.1413 35.1772C23.9202 35.1773 24.6917 35.3307 25.4113 35.6288C26.131 35.9269 26.785 36.3639 27.3358 36.9147C27.8867 37.4655 28.3236 38.1195 28.6217 38.8392C28.9198 39.5588 29.0732 40.33 29.0733 41.1089"
      fill="white"
    />,
    <path
      key="4"
      d="M15.7754 42.0116C15.7754 41.5897 16.1176 41.2477 16.5397 41.2477H29.525C29.9471 41.2477 30.2893 41.5897 30.2893 42.0116C30.2893 42.4335 29.9471 42.7755 29.525 42.7755H16.5397C16.1176 42.7755 15.7754 42.4335 15.7754 42.0116Z"
      fill="white"
    />,
    <path
      key="5"
      d="M34.4279 22.502C32.3996 22.502 30.7553 24.1463 30.7553 26.1746C30.7553 28.2029 32.3996 29.8471 34.4279 29.8471C36.4562 29.8471 38.1005 28.2029 38.1005 26.1746C38.1005 24.1463 36.4562 22.502 34.4279 22.502ZM29.1881 26.1746C29.1881 23.2807 31.534 20.9348 34.4279 20.9348C37.3218 20.9348 39.6677 23.2807 39.6677 26.1746C39.6677 29.0684 37.3218 31.4144 34.4279 31.4144C31.534 31.4144 29.1881 29.0684 29.1881 26.1746Z"
      fill="white"
    />,
    <path
      key="6"
      d="M34.4273 31.5872C33.0614 31.5875 31.7373 32.0586 30.6781 32.9211C29.6188 33.7836 28.8893 34.9849 28.6122 36.3225C28.5245 36.7463 28.1098 37.0187 27.686 36.9309C27.2622 36.8431 26.9898 36.4284 27.0776 36.0046C27.4277 34.3141 28.3498 32.7959 29.6885 31.7058C31.0272 30.6157 32.7008 30.0203 34.4272 30.02C35.412 30.02 36.3873 30.2139 37.2971 30.5908C38.207 30.9677 39.0337 31.5201 39.7301 32.2164C40.4264 32.9128 40.9788 33.7395 41.3557 34.6494C41.7326 35.5592 41.9265 36.5344 41.9265 37.5192C41.9264 37.8567 41.904 38.1941 41.8597 38.5286C41.8028 38.9577 41.4088 39.2593 40.9798 39.2024C40.5508 39.1455 40.2491 38.7516 40.306 38.3226C40.3414 38.0561 40.3592 37.7876 40.3593 37.5188M34.4273 31.5872C35.2063 31.5872 35.9777 31.7407 36.6974 32.0388C37.4171 32.3369 38.071 32.7738 38.6219 33.3247C39.1727 33.8755 39.6097 34.5294 39.9078 35.2491C40.2058 35.9687 40.3593 36.74 40.3593 37.5188"
      fill="white"
    />,
    <path
      key="7"
      d="M28.6617 38.4198C28.6617 37.987 29.0126 37.6362 29.4453 37.6362H41.0847C41.5175 37.6362 41.8683 37.987 41.8683 38.4198C41.8683 38.8526 41.5175 39.2034 41.0847 39.2034H29.4453C29.0126 39.2034 28.6617 38.8526 28.6617 38.4198Z"
      fill="white"
    />,
    <path
      key="8"
      clipRule="evenodd"
      d="M25.8951 13.0252C22.6593 13.0252 20.0362 15.6483 20.0362 18.8841C20.0362 22.1199 22.6593 24.743 25.8951 24.743C29.1309 24.743 31.754 22.1199 31.754 18.8841C31.754 15.6483 29.1309 13.0252 25.8951 13.0252ZM18.469 18.8841C18.469 14.7828 21.7938 11.458 25.8951 11.458C29.9964 11.458 33.3212 14.7828 33.3212 18.8841C33.3212 22.9854 29.9964 26.3102 25.8951 26.3102C21.7938 26.3102 18.469 22.9854 18.469 18.8841Z"
      fill="white"
    />,
    <path
      key="9"
      d="M25.8964 19.3518C25.3571 19.3504 24.8404 19.1356 24.4591 18.7543C24.0778 18.373 23.863 17.8563 23.8616 17.317C23.8623 16.7776 24.0769 16.2604 24.4583 15.879C24.8398 15.4975 25.3569 15.2829 25.8964 15.2822C26.436 15.2822 26.9536 15.4966 27.3352 15.8782C27.7168 16.2598 27.9312 16.7774 27.9312 17.317C27.9312 17.4445 27.8805 17.5667 27.7904 17.6569C27.7003 17.747 27.578 17.7976 27.4505 17.7976C27.3233 17.797 27.2014 17.7461 27.1115 17.6561C27.0215 17.5661 26.9706 17.4443 26.9699 17.317C26.9699 17.176 26.9422 17.0364 26.8882 16.9062C26.8343 16.7759 26.7552 16.6576 26.6555 16.5579C26.5558 16.4582 26.4375 16.3791 26.3072 16.3252C26.177 16.2712 26.0373 16.2435 25.8964 16.2435C25.6119 16.2442 25.3392 16.3575 25.138 16.5587C24.9368 16.7599 24.8235 17.0325 24.8228 17.317C24.8235 17.6015 24.9368 17.8742 25.138 18.0754C25.3392 18.2766 25.6119 18.3899 25.8964 18.3906C26.0238 18.3906 26.1461 18.4412 26.2362 18.5314C26.3264 18.6215 26.377 18.7437 26.377 18.8712C26.377 18.9987 26.3264 19.1209 26.2362 19.2111C26.1461 19.3012 26.0238 19.3518 25.8964 19.3518Z"
      fill="white"
    />,
    <path
      key="10"
      d="M25.8964 22.4644C25.3571 22.463 24.8404 22.2482 24.4591 21.8669C24.0778 21.4856 23.863 20.9688 23.8616 20.4296C23.8616 20.3021 23.9122 20.1799 24.0023 20.0897C24.0925 19.9996 24.2147 19.949 24.3422 19.949C24.4697 19.949 24.5919 19.9996 24.682 20.0897C24.7722 20.1799 24.8228 20.3021 24.8228 20.4296C24.8235 20.7141 24.9368 20.9867 25.138 21.1879C25.3392 21.3891 25.6119 21.5024 25.8964 21.5031C26.1809 21.5024 26.4536 21.3891 26.6547 21.1879C26.8559 20.9867 26.9692 20.7141 26.9699 20.4296C26.9699 20.1448 26.8568 19.8718 26.6555 19.6705C26.4542 19.4691 26.1811 19.356 25.8964 19.356C25.7691 19.3553 25.6473 19.3045 25.5573 19.2145C25.4673 19.1245 25.4164 19.0027 25.4158 18.8754C25.4158 18.8123 25.4282 18.7498 25.4523 18.6915C25.4765 18.6332 25.5119 18.5802 25.5565 18.5355C25.6012 18.4909 25.6541 18.4555 25.7125 18.4314C25.7708 18.4072 25.8333 18.3948 25.8964 18.3948C26.436 18.3948 26.9536 18.6092 27.3352 18.9908C27.7168 19.3724 27.9312 19.8899 27.9312 20.4296C27.9305 20.969 27.7159 21.4862 27.3344 21.8676C26.953 22.2491 26.4358 22.4637 25.8964 22.4644Z"
      fill="white"
    />,
    <path
      key="11"
      d="M25.8972 23.1977H25.8711C25.7453 23.191 25.627 23.1353 25.5416 23.0426C25.4562 22.9498 25.4104 22.8274 25.414 22.7014L25.4584 21.9465C25.4658 21.8195 25.5233 21.7005 25.6183 21.6158C25.7133 21.5311 25.8381 21.4875 25.9652 21.4947C26.0922 21.5021 26.2112 21.5596 26.2959 21.6546C26.3806 21.7496 26.4242 21.8743 26.4171 22.0014L26.3753 22.7563C26.3648 22.8762 26.3101 22.9879 26.2217 23.0695C26.1333 23.1512 26.0176 23.1969 25.8972 23.1977Z"
      fill="white"
    />,
    <path
      key="12"
      d="M25.911 15.8565C25.7833 15.8531 25.6621 15.7993 25.574 15.7068C25.4859 15.6142 25.438 15.4906 25.4408 15.3629C25.4408 15.0782 25.4539 14.9998 25.4591 14.958C25.4658 14.8358 25.5191 14.7208 25.608 14.6368C25.6969 14.5527 25.8147 14.5059 25.9371 14.5061C26.0004 14.5058 26.0632 14.5179 26.1218 14.5419C26.1804 14.5659 26.2337 14.6013 26.2786 14.646C26.3235 14.6906 26.3592 14.7437 26.3835 14.8022C26.4078 14.8607 26.4203 14.9234 26.4203 14.9867C26.4238 15.0145 26.4238 15.0426 26.4203 15.0703C26.4203 15.0703 26.4203 15.1304 26.4203 15.3864C26.419 15.4511 26.4047 15.5149 26.3781 15.5739C26.3516 15.633 26.3134 15.686 26.2658 15.73C26.2182 15.7739 26.1623 15.8077 26.1013 15.8295C26.0403 15.8512 25.9756 15.8604 25.911 15.8565ZM25.4486 14.9711C25.4482 14.9763 25.4482 14.9815 25.4486 14.9867C25.4486 14.9867 25.4486 14.9763 25.4486 14.9711Z"
      fill="white"
    />
  ]
});
