import { createIcon } from '@chakra-ui/react';

export const SorteioMensalIcon = createIcon({
  displayName: 'SorteioMensalIcon',
  viewBox: '0 0 96 95',
  path: [
    <path
      key="1"
      d="M48.5 18C31.9314 18 18.5 31.4314 18.5 48C18.5 64.5686 31.9314 78 48.5 78C65.0686 78 78.5 64.5686 78.5 48C78.5 31.4314 65.0686 18 48.5 18ZM50.6935 62.9069V67.8722H45.9171V63.2484C42.6525 63.1013 39.4871 62.2246 37.6358 61.1542L39.0998 55.4545C41.1461 56.5735 44.0179 57.596 47.1866 57.596C49.9619 57.596 51.8625 56.5256 51.8625 54.5778C51.8625 52.7265 50.3041 51.5561 46.6987 50.3386C41.487 48.5852 37.9294 46.1508 37.9294 41.4263C37.9294 37.1393 40.9504 33.7802 46.1621 32.757V28.1284H50.9344V32.4155C54.1976 32.5627 56.3911 33.2402 57.9974 34.0225L56.5855 39.5271C55.3194 38.9926 53.0793 37.8695 49.569 37.8695C46.4037 37.8695 45.3812 39.2342 45.3812 40.5982C45.3812 42.2045 47.0867 43.2277 51.2253 44.7854C57.0215 46.8324 59.3621 49.5105 59.3621 53.8968C59.3635 58.2311 56.2933 61.9317 50.6935 62.9069Z"
      fill="white"
    />
  ]
});
