import { createIcon } from '@chakra-ui/react';

export const CartaoAlimentacaoIcon = createIcon({
  displayName: 'CartaoAlimentacaoIcon',
  viewBox: '0 0 42 42',
  path: [
    <path
      key="1"
      d="M8.0372 16.7927L17.3625 26.1188C18.0354 26.7896 19.124 26.7896 19.7957 26.1188M8.0372 16.7927C3.83037 12.5867 2.25963 5.54166 5.60485 2.19644M8.0372 16.7927L14.8219 23.5772C17.5801 21.1352 19.8191 19.1545 20.2741 18.7512M19.7957 26.1188L21.4172 24.4968L32.0612 38.7897C33.4544 40.1833 35.7355 40.1833 37.1296 38.7897C38.5229 37.3956 38.5229 35.1149 37.1296 33.7212L24.6616 21.2532M19.7957 26.1188C19.5557 26.3579 19.2625 26.5074 18.9554 26.5766C21.1486 24.0992 22.876 22.1477 23.2493 21.7264M24.6616 21.2532L5.60485 2.19644M24.6616 21.2532L24.2605 20.8523C23.8976 21.0802 23.5702 21.3631 23.2493 21.7264M5.60485 2.19644L21.1478 17.7396C20.9199 18.1025 20.637 18.43 20.2741 18.7512M20.2741 18.7512C18.9288 19.9424 2.04556 34.8865 2.04556 34.8865C0.651479 36.2806 0.651479 38.5609 2.04556 39.9541C3.43923 41.3486 5.71909 41.3486 7.11359 39.9541C7.11359 39.9541 22.0582 23.0714 23.2493 21.7264M20.2741 18.7512C21.6203 17.56 21.9365 16.3364 21.9365 13.1936C21.9365 11.4304 22.6014 9.66719 23.9397 8.32977L33.6712 18.06C32.3337 19.3983 30.5697 20.0632 28.8065 20.0632C25.6637 20.0632 24.4405 20.3803 23.2493 21.7264M23.9699 8.35914L23.9095 8.29831L31.2087 1L31.2687 1.06L23.9699 8.35914ZM33.7021 18.0902L33.6409 18.0298L40.94 10.7311L41 10.7915L33.7021 18.0902ZM30.4578 14.8469L30.3978 14.7861L37.6957 7.48775L37.7557 7.54775L30.4578 14.8469ZM27.2143 11.6024L27.1531 11.5425L34.4522 4.24414L34.5122 4.30455L27.2143 11.6024Z"
      stroke="#0066F3"
      strokeWidth="1.87488"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ]
});
