import { createIcon } from '@chakra-ui/react';

export const VidaIndividualIcon = createIcon({
  displayName: 'VidaIndividualIcon',
  viewBox: '0 0 111 110',
  path: [
    <path
      key="1"
      d="M55.3628 110L53.5079 108.924C28.3755 94.4055 10.4014 73.1237 2.78595 48.9914C2.19814 47.1189 1.67563 45.2325 1.2315 43.4019V43.2063C0.744544 40.706 0.499421 38.1587 0.500001 35.6047C0.500001 16.0417 14.7382 0 32.229 0C36.646 0.0147136 41.0089 1.04121 45.024 3.01043C49.0391 4.97965 52.6135 7.84592 55.5065 11.4164C58.399 7.84522 61.9732 4.97846 65.9885 3.00918C70.0038 1.0399 74.3669 0.0138063 78.784 0C96.2748 0 110.5 15.9718 110.5 35.6047C110.5 41.4037 108.763 46.8674 107.143 51.5206C99.1617 74.563 81.4357 94.9505 57.27 108.924L55.3628 110ZM8.84697 41.4037C9.23885 43.0107 9.69606 44.6596 10.2186 46.3084C16.9719 67.8277 32.9605 86.9715 55.3628 100.442C76.903 87.4886 92.6304 69.1413 99.7756 48.5861C101.16 44.6037 102.636 40.0064 102.636 35.5907C102.636 20.5831 91.925 8.37017 78.7579 8.37017C74.7442 8.4179 70.8087 9.56403 67.3263 11.6994C63.8439 13.8348 60.9305 16.8883 58.8636 20.5691L55.5327 26.3262L52.1364 20.5691C50.0712 16.8699 47.1524 13.8002 43.6596 11.6543C40.1669 9.5084 36.2174 8.35832 32.1898 8.31428C19.0097 8.31428 8.29836 20.5272 8.29836 35.5348C8.29291 37.506 8.47673 39.4727 8.84697 41.4037V41.4037Z"
      fill="#146EFA"
    />
  ]
});
