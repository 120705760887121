import { Box, Heading } from '@chakra-ui/react';

import { LayoutType } from '../../types/LayoutType';
import { Header } from './Header';
import { IndexSteps } from './IndexSteps';

export const LayoutContent = ({
  height,
  children,
  currentStep,
  zIndex,
  title
}: LayoutType) => {
  return (
    <Box
      bg="#F4F4F4"
      w="100% !important"
      minH={height}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Header>
        <IndexSteps currentStep={currentStep} zIndex={zIndex} />
      </Header>
      <Box
        p={{
          base: '0px 16px'
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        w="100%"
      >
        <Heading
          w="100%"
          m={{
            base: '36px 0 18px 0 !important',
            lg: '36px 0 24px 0 !important'
          }}
          color="#000000"
          fontSize={['1.5rem', '1.5rem', '2rem']}
          fontWeight="700"
          lineHeight={['2rem', '2rem', '3rem']}
          display="flex"
          justifyContent="center"
        >
          {title}
        </Heading>
        {children}
      </Box>
    </Box>
  );
};
