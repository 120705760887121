import { createIcon } from '@chakra-ui/react';

export const StepSuccessCheck = createIcon({
  displayName: 'SuccessCheck',
  viewBox: '0 0 54 55',
  path: [
    <circle key="1" cx="27" cy="27.5" r="27" fill="#006217" />,
    <path
      key="2"
      d="M17 27.494L23.5854 34L37.25 20.5"
      stroke="white"
      strokeWidth="5"
      fill="none"
    />
  ]
});
