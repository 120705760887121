import { createIcon } from '@chakra-ui/react';

export const FilhoPostumoIcon = createIcon({
  displayName: 'FilhoPostumoIcon',
  viewBox: '0 0 50 50',
  path: [
    <path
      key="1"
      d="M39.2727 42.2592L30.4719 31.2996M24.8005 31.5648L16.2019 42.2747M8.39024 18.8828C8.39024 11.3974 5.3096 5.33224 1.5 5.33224M31.9514 17.3914V1.07698C31.9561 1.03682 31.9875 1.00469 32.0284 1C38.8898 1 46.7305 5.51054 48.4579 12.1514C49.6883 16.7027 48.735 21.568 45.8787 25.3197C43.0223 29.0703 38.5861 31.2828 33.8703 31.3068H20.6173C13.5831 31.3068 10.1185 26.4415 8.44869 19.906C8.28267 19.3156 8.40919 18.681 8.78875 18.199C9.1683 17.7171 9.75542 17.4459 10.3686 17.4687H31.8652C31.8879 17.476 31.9127 17.4707 31.9301 17.4546C31.9482 17.4392 31.9561 17.4148 31.9514 17.3914ZM46.0086 42.263C46.0086 45.9837 42.9929 49 39.2723 49C35.5511 49 32.5353 45.9837 32.5353 42.263C32.5353 38.5424 35.551 35.526 39.2723 35.526C42.9929 35.526 46.0086 38.5424 46.0086 42.263ZM22.9378 42.263C22.9378 45.9837 19.9215 49 16.2008 49C12.4802 49 9.46387 45.9837 9.46387 42.263C9.46387 38.5424 12.4802 35.526 16.2008 35.526C19.9215 35.526 22.9378 38.5424 22.9378 42.263Z"
      fill="none"
      stroke="#146CFA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ]
});
