import { Flex, FlexProps } from '@chakra-ui/react';
import { ReactNode, useEffect } from 'react';

import { apiSensedia } from '../../api/api-sensedia';
import { useQueryParam, useSubdomain } from '../../hooks/useSubdomain';
import { AccessProps } from '../../types';

type PageProps = {
  children: ReactNode;
} & FlexProps;

const referrerMocks = {};

export const Page = ({ children, ...rest }: PageProps) => {
  const subdomain = useSubdomain() || 'M';
  const queryParam = useQueryParam();

  useEffect(() => {
    try {
      //se o session storage estiver vazio
      if (!sessionStorage.getItem('currentAccess')) {
        const registroAcesso: AccessProps = {
          browser: navigator.userAgent,
          device_memory: (navigator as any).deviceMemory,
          language: navigator.language,
          subdomain: subdomain
        };

        if (document.referrer.includes('linkedin')) {
          registroAcesso.source = 'linkedin';
        }

        if (document.referrer.includes('instagram')) {
          registroAcesso.source = 'instagram';
        }

        if (document.referrer.includes('facebook')) {
          registroAcesso.source = 'facebook';
        }

        if (document.referrer.includes('t.co')) {
          registroAcesso.source = 'twitter';
        }

        if (document.referrer.includes('mail')) {
          registroAcesso.source = 'email';
        }

        let sourceRefVal;

        try {
          sourceRefVal = atob(
            queryParam.get('sourceRef') as string
          ).toLowerCase();
        } catch (e) {
          console.log(e);
          sourceRefVal = '';
        }

        if (document.referrer.length === 0) {
          if (sourceRefVal === 'whatsapp') {
            registroAcesso.source = 'whatsapp';
          } else if (sourceRefVal === 'facebook') {
            registroAcesso.source = 'facebook';
          } else if (sourceRefVal === 'instagram') {
            registroAcesso.source = 'instagram';
          } else if (sourceRefVal == 'twitter') {
            registroAcesso.source = 'twitter';
          } else if (sourceRefVal == 'linkedin') {
            registroAcesso.source = 'linkedin';
          } else if (sourceRefVal == 'email') {
            registroAcesso.source = 'email';
          } else if (sourceRefVal == 'qrcode') {
            registroAcesso.source = 'qrcode';
          } else {
            registroAcesso.source = 'direct_url';
          }
        }

        apiSensedia()
          .then(async (api) => {
            //salva no firestore
            const response = await api.post(
              '/v1/metricas/acessos',
              registroAcesso
            );

            //salva o retorno num sessionStorage
            sessionStorage.setItem(
              'currentAccess',
              JSON.stringify(response.data)
            );

            // console.log('retorno acesso', response.data);

            // console.log('o referrer', document.referrer);
          })
          .catch((e) => {
            // console.log('erro criando acesso', e);
          });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Flex flexDir="column" h="100vh" {...rest}>
      {children}
    </Flex>
  );
};
