import { createIcon } from '@chakra-ui/react';

export const AssistenciaResidencialIcon = createIcon({
  displayName: 'AssistenciaResidencialIcon',
  viewBox: '0 0 60 60',
  path: [
    <path
      key="1"
      d="M24.5218 28.877C24.5218 28.5606 24.6632 28.2629 24.9051 28.0619L26.1481 27.0273C25.0465 27.686 23.7961 28.1177 22.46 28.2629C22.4191 28.4639 22.4005 28.6686 22.4005 28.877V30.5108C23.1299 30.4512 23.8407 30.3247 24.5218 30.1311V28.877ZM39.4418 45.9295H32.9587V39.0407C32.9587 38.8398 32.9773 38.6388 33.0145 38.449C33.2937 36.9417 34.6148 35.7992 36.2002 35.7992C37.3874 35.7992 38.4779 36.443 39.0436 37.4888C39.2036 37.7791 39.319 38.0954 39.3822 38.4192C39.4232 38.6239 39.4418 38.8323 39.4418 39.0407V45.9295ZM48.8538 26.4319L38.236 17.5855C37.0562 16.5993 35.3405 16.5993 34.1608 17.5855L32.8992 18.6388L30.5434 20.6001C30.2605 22.3269 29.4976 23.8937 28.3923 25.1553L32.6163 21.6347L35.5192 19.2156C35.7164 19.0519 35.9583 18.97 36.2002 18.97C36.4421 18.97 36.6803 19.0519 36.8776 19.2156L47.4917 28.0619C47.7373 28.2629 47.875 28.5606 47.875 28.877V44.8651C47.875 45.4531 47.3986 45.9295 46.8143 45.9295H41.6859V39.0407C41.6859 38.5718 41.6264 38.1066 41.5073 37.6563C41.3956 37.2208 41.2319 36.804 41.016 36.4095C40.0558 34.6492 38.2099 33.5513 36.2002 33.5513C35.91 33.5513 35.6271 33.5737 35.3517 33.6183C34.4399 33.7597 33.6025 34.1282 32.8992 34.6604C32.3707 35.0586 31.9167 35.5498 31.5631 36.1118C31.2654 36.577 31.0383 37.0869 30.8932 37.6339C30.7741 38.0843 30.7108 38.5569 30.7108 39.0407V45.9295H25.5862C24.9981 45.9295 24.5218 45.4531 24.5218 44.8651V40.5517C23.6211 40.1609 22.8731 39.4836 22.4005 38.6313V44.8651C22.4005 46.6217 23.8296 48.0508 25.5862 48.0508H46.8143C48.5709 48.0508 50 46.6217 50 44.8651V28.877C50 27.9279 49.5795 27.0385 48.8538 26.4319Z"
      fill="#0066F3"
    />,
    <path
      key="2"
      d="M32.8992 11.9501V19.1031C32.8992 19.9739 32.8024 20.8187 32.6163 21.6338C31.6896 25.735 28.5486 29.0063 24.5218 30.1302C23.8407 30.3238 23.1299 30.4503 22.4005 30.5098C22.2181 30.5285 22.032 30.5359 21.8459 30.5433C21.7157 30.5471 21.5817 30.5508 21.4477 30.5508C20.8225 30.5508 20.2121 30.5024 19.613 30.4019C14.1719 29.5236 10 24.7897 10 19.1031V11.9501H15.4894C16.1072 11.9501 16.6059 12.4488 16.6059 13.0666C16.6059 13.6844 16.1072 14.1831 15.4894 14.1831H12.233V19.1031C12.233 24.1868 16.3677 28.3178 21.4477 28.3178C21.7901 28.3178 22.1288 28.2992 22.46 28.262C23.7961 28.1168 25.0465 27.6851 26.1481 27.0264C26.9929 26.524 27.7522 25.8913 28.3923 25.1544C29.4976 23.8928 30.2605 22.326 30.5434 20.5992C30.6253 20.1116 30.6662 19.6129 30.6662 19.1031V14.1831H27.9792C27.3651 14.1831 26.8627 13.6844 26.8627 13.0666C26.8627 12.4488 27.3651 11.9501 27.9792 11.9501H32.8992Z"
      fill="#0066F3"
    />,
    <path
      key="3"
      d="M32.8992 33.267V36.4936C32.8992 38.4437 32.0544 40.2004 30.7109 41.421C29.5274 42.4854 27.968 43.1367 26.2561 43.1367C25.6569 43.1367 25.0763 43.0586 24.5218 42.906C23.7514 42.6976 23.0332 42.3552 22.4005 41.8974C20.7146 40.6953 19.613 38.7191 19.613 36.4936V29.4337C19.613 28.8196 20.1117 28.3172 20.7295 28.3172C21.3473 28.3172 21.846 28.8196 21.846 29.4337V36.4936C21.846 37.2677 22.0469 37.9972 22.4005 38.6298C22.8731 39.4821 23.6212 40.1594 24.5218 40.5502C25.054 40.7772 25.642 40.9037 26.2561 40.9037C28.6863 40.9037 30.6662 38.9238 30.6662 36.4936V33.267C30.6662 32.6492 31.1649 32.1505 31.7827 32.1505C32.3968 32.1505 32.8992 32.6492 32.8992 33.267Z"
      fill="#0066F3"
    />,
    <path
      key="4"
      d="M35.5826 32.3124C35.5826 34.4118 33.8807 36.1133 31.7817 36.1133C29.6823 36.1133 27.9804 34.4118 27.9804 32.3124C27.9804 30.213 29.6823 28.5115 31.7817 28.5115C33.8807 28.5115 35.5826 30.213 35.5826 32.3124Z"
      fill="#65E5AE"
    />
  ]
});
