import { createIcon } from '@chakra-ui/react';

export const FacebookIcon = createIcon({
  displayName: 'FacebookIcon',
  viewBox: '0 0 45 46',
  path: [
    <circle key="1" cx="22.5" cy="23" r="22.5" fill="#1877F2" />,
    <path
      key="2"
      d="M28.7562 25.9751L29.5025 20.964H24.8341V17.712C24.8341 16.3411 25.4862 15.0048 27.5772 15.0048H29.6998V10.7385C29.6998 10.7385 27.7735 10.3999 25.9318 10.3999C22.0868 10.3999 19.5738 12.7998 19.5738 17.1447V20.964H15.2998V25.9751H19.5738V38.0893C20.4308 38.2278 21.3091 38.2999 22.2039 38.2999C23.0987 38.2999 23.9771 38.2278 24.8341 38.0893V25.9751H28.7562Z"
      fill="white"
    />
  ]
});
