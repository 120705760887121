import { createIcon } from '@chakra-ui/react';

export const FazTudoIcon = createIcon({
  displayName: 'FazTudoIcon',
  viewBox: '0 0 96 95',
  path: [
    <path
      key="1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.5 20V23.742L49.736 18.428C49.01 17.858 47.99 17.858 47.264 18.428C47.264 18.428 26.794 34.512 20.858 39.174C19.37 40.344 18.5 42.134 18.5 44.028V44.03C18.5 45.082 18.918 46.092 19.662 46.838C20.408 47.582 21.418 48 22.47 48H26.5V72.042C26.5 73.622 27.128 75.138 28.246 76.254C29.362 77.372 30.878 78 32.458 78H64.542C66.122 78 67.638 77.372 68.754 76.254C69.872 75.138 70.5 73.622 70.5 72.042V48H74.48C75.546 48 76.568 47.576 77.322 46.822C78.076 46.068 78.5 45.046 78.5 43.98V43.978C78.5 42.116 77.646 40.356 76.18 39.206L66.5 31.6V20C66.5 18.896 65.604 18 64.5 18H58.5C57.396 18 56.5 18.896 56.5 20ZM56.551 61.0574L58.6945 61.8419L62.7452 65.9279L60.1097 68.5871L56.059 64.5011L55.2813 62.3375L49.3979 56.4021L38.2658 67.6321C37.2428 68.6647 35.5838 68.6678 34.5593 67.6336C33.5379 66.6025 33.5356 64.9268 34.5616 63.8972L44.3418 54.0303L34.7315 44.3355C33.6339 43.2298 33.6316 41.435 34.7307 40.3255C35.8268 39.2207 37.6084 39.2184 38.7045 40.3271L48.3148 50.0211L50.3554 47.9635C49.954 47.093 49.711 46.1295 49.711 45.1061C49.711 42.5598 51.0981 40.3447 53.1485 39.1753L53.1516 45.1376L56.4589 47.4472L59.7669 45.1391L59.7662 39.1769C61.8167 40.3447 63.2045 42.5591 63.2045 45.103C63.2045 48.8625 60.1851 51.9112 56.4574 51.9081C55.6827 51.9081 54.2515 51.5063 54.2515 51.5063L50.6685 55.1213L56.551 61.0574Z"
      fill="white"
    />
  ]
});
