import { Box, Divider, Image, Text, VStack } from '@chakra-ui/react';

import emojiEasyImage from '../assets/images/emojiEasy.png';
import emojiHardImage from '../assets/images/emojiHard.png';
import emojiMediumImage from '../assets/images/emojiMedium.png';
import emojiVeryEasyImage from '../assets/images/emojiVeryEasy.png';
import emojiVeryHardImage from '../assets/images/emojiVeryHard.png';
import womanConclusionImage from '../assets/images/womanConclusion.png';
import { Header } from '../components/layouts/Header';
import { IndexSteps } from '../components/layouts/IndexSteps';
import { LayoutContent } from '../components/layouts/LayoutContent';

export const Conclusao = () => {
  return (
    <LayoutContent currentStep={0} zIndex={10} title={'Conclusão'}>
      <Box
        h={{ base: '100vh' }}
        p={['30px 16px', '30px 16px', '20px 16px']}
        bg="#F5F5F5"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display={{ base: 'none', lg: 'flex', xl: 'flex' }}
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src={womanConclusionImage}
            w={{ lg: '399px', xl: '485px' }}
            h={{ lg: '399px', xl: '485px' }}
          />
        </Box>
        <Box
          w={{ base: '50%', lg: '', xl: '' }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            m="0 !important"
            w={{ lg: '399px', xl: '485px' }}
            fontSize={{ base: '1.5rem', lg: '1.75rem', xl: '2rem' }}
            fontWeight="700"
            lineHeight={{ base: '1.5rem', lg: '1.75rem', xl: '2rem' }}
            color="#323232"
            textAlign="center"
          >
            Obrigado por escolher a SulAmérica!
          </Text>
          <Text
            m="30px 0 0 0 !important"
            w={{ lg: '399px', xl: '485px' }}
            color="#5A5A5A"
            fontSize={{ base: '1rem', lg: '1rem', xl: '1.25rem' }}
            fontWeight="700"
            lineHeight={{ base: '1rem', lg: '1.25rem', xl: '1.5rem' }}
            textAlign="center"
          >
            Fique atento ao seu e-mail, você receberá um link para
            acompanhamento da sua proposta em nosso Espaço do Cliente
          </Text>
          <Text
            m="40px 0 0 0 !important"
            w={{ lg: '399px', xl: '485px' }}
            color="#FF5000"
            fontSize={{ base: '1rem', lg: '1rem', xl: '1.25rem' }}
            fontWeight="400"
            lineHeight={{ base: '1rem', lg: '1.25rem', xl: '1.5rem' }}
            textAlign="center"
          >
            Lembramos que os beneficiários podem ser incluídos através do espaço
            do cliente.
          </Text>
        </Box>
      </Box>
    </LayoutContent>
  );
};
