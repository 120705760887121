import { Button } from '@chakra-ui/react';
import { useEffect } from 'react';

import { wlLocalStorage } from '../../utils/localStorage.util';

type ButtonSelectProps = {
  idProduct: string;
  product: any;
  product_id: string;
  redirectProdId: string | null;
  title: string;
  subtitle: string;
  text: string;
  priceProduct: string;
  quotationData: any;
  assist: any;
  cover: any;
  productSave: (newState: boolean) => void;
  recommended: boolean;
  codigo_corretor: number;
  codigo_estrutura_venda: number;
  buttonColor: string;
  corretagem: number;
  agenciamento: number;
  intermediacao?: number;
  disabled?: boolean;
};

export const ButtonSelect = ({
  idProduct,
  product,
  product_id,
  redirectProdId,
  codigo_corretor,
  codigo_estrutura_venda,
  priceProduct,
  quotationData,
  title,
  subtitle,
  text,
  assist,
  cover,
  productSave,
  recommended,
  buttonColor,
  corretagem,
  agenciamento,
  intermediacao,
  disabled = false
}: ButtonSelectProps) => {
  const handleProductSelected = async () => {
    productSave(true);

    try {
      wlLocalStorage.setItem('assist', JSON.stringify(assist));
      wlLocalStorage.setItem('cover', JSON.stringify(cover));
      wlLocalStorage.setItem('quotationData', JSON.stringify(quotationData));
      wlLocalStorage.setItem('selectedProduct', JSON.stringify(product));
      wlLocalStorage.setItem('title', title);
      wlLocalStorage.setItem('subtitle', subtitle);
      wlLocalStorage.setItem('text', text);
      wlLocalStorage.setItem('codigo_corretor', String(codigo_corretor));
      wlLocalStorage.setItem(
        'codigo_estrutura_venda',
        String(codigo_estrutura_venda)
      );
      wlLocalStorage.setItem('idProdutoCustomizado', product.product_id);
      wlLocalStorage.setItem('priceSelected', priceProduct);
      wlLocalStorage.setItem('brokerage', corretagem.toString());
      wlLocalStorage.setItem('agency', agenciamento.toString());
      if (intermediacao) {
        wlLocalStorage.setItem('commission', intermediacao.toString());
      } else {
        wlLocalStorage.setItem('commission', 'undefined');
      }
      productSave(false);
      window.location.href = `/dados-cadastrais/inicio/product=${idProduct}`;
    } catch (erro) {
      // console.log(erro);
    }
  };

  useEffect(() => {
    if (redirectProdId === product_id) {
      handleProductSelected();
    }
  }, []);

  return (
    <>
      <Button
        data-testid="ButtonSelect"
        justifyContent="center"
        bg={buttonColor!}
        margin={['16px 0 0 0 !important']}
        w="100%"
        h="48px"
        disabled={disabled}
        p="13px 47px"
        gap="10px"
        border={
          recommended ? '1px solid' : `1px solid ${buttonColor || '#FF5000'}`
        }
        borderRadius="133.5px"
        color={'white'}
        fontWeight="700"
        fontSize="1rem"
        lineHeight="1.375rem"
        textAlign="center"
        onClick={() => {
          handleProductSelected();
        }}
        _disabled={{
          color: '#D2D2D2',
          border: '1px solid #D2D2D2'
        }}
        _hover={{
          WebkitFilter: ' brightness(60%)',
          transition: 'all 0.4s',
          WebkitTransition: 'all 0.4s'
        }}
        _active={{}}
        _focus={{
          '&:focus': {
            boxShadow: 'none !important'
          }
        }}
      >
        {codigo_corretor === 2994631 ? 'Contrate' : 'Simule'}
      </Button>
    </>
  );
};
