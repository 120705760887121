import { Box, Button, HStack, Spacer, Text, VStack } from '@chakra-ui/react';

import { BuyConfirmationProps } from '../../types/CheckoutType';
import { getNumberFormat } from '../../utils/getNumberFormat';

export const BuyConfirmation = ({
  isDisable,
  price,
  comparisonPrice,
  percentualDesconto,
  actionNext,
  actionPrev,
  frequency
}: BuyConfirmationProps) => {
  const precoComDesconto = (1 - Number(percentualDesconto) / 100) * price;

  let recorrencia = '';

  if (frequency === 'M') {
    recorrencia = 'mensal';
  }

  if (frequency === 'T') {
    recorrencia = 'trimestral';
  }

  if (frequency === 'S') {
    recorrencia = 'semestral';
  }

  if (frequency === 'A') {
    recorrencia = 'anual';
  }

  return (
    <HStack
      bg="#001E64"
      w="100%"
      p={['26px 16px', '36px 0px']}
      m="0 !important"
      justify="center"
    >
      <HStack
        w="100%"
        maxWidth="1192px"
        justify="space-between"
        flexDir={['column', 'row']}
      >
        <Text
          color="#FFFFFF"
          fontSize={['1rem', '1.25rem']}
          lineHeight={['1.5rem', '2rem']}
          fontWeight="700"
          w="100%"
          display="flex"
        >
          {/* Sua proteção: {getNumberFormat(price.toFixed(2))} por {frequency} no{' '} */}
          {percentualDesconto ? (
            <>
              <Box as="span" fontSize="24px">
                Valor {recorrencia} com desconto
              </Box>
              <Spacer width="34px" maxWidth="34px"></Spacer>
              <VStack alignItems={'flex-start'}>
                <Box as="span" fontSize="32px">
                  {getNumberFormat(precoComDesconto.toFixed(2))}
                </Box>
                {precoComDesconto !== 0 &&
                precoComDesconto < comparisonPrice ? (
                  <Box as="span" fontSize="12px" color="red" lineHeight="16px">
                    O valor {recorrencia} do prêmio não pode
                    <br />
                    ser menor do que{' '}
                    <b>{getNumberFormat(comparisonPrice.toFixed(2))}</b>.
                  </Box>
                ) : (
                  <></>
                )}
              </VStack>
            </>
          ) : (
            <>
              <Box as="span" fontSize="24px">
                Valor {recorrencia}
              </Box>
              <Spacer width="34px" maxWidth="34px"></Spacer>
              <VStack alignItems={'flex-start'}>
                <Box as="span" fontSize="32px">
                  {getNumberFormat(price.toFixed(2))}
                </Box>
                {price !== 0 && price < comparisonPrice ? (
                  <Box as="span" fontSize="12px" color="red" lineHeight="16px">
                    O valor {recorrencia} do prêmio não pode
                    <br />
                    ser menor do que{' '}
                    <b>{getNumberFormat(comparisonPrice.toFixed(2))}</b>.
                  </Box>
                ) : (
                  <></>
                )}
              </VStack>
            </>
          )}
        </Text>
        <Box
          w="100%"
          m={['20px 0 0 0 !important', '0 !important']}
          display="flex"
          justifyContent="end"
          flexDirection={['column', 'row']}
          gridGap="10px"
        >
          <Button
            bg="transparent"
            color="#FFFFFF"
            border="1px solid #FFFFFF"
            w={['100%', '174px']}
            p="16px 32px"
            borderRadius="4px"
            _hover={{}}
            _active={{}}
            _focus={{
              '&:focus': {
                boxShadow: 'none !important'
              }
            }}
            onClick={actionPrev}
          >
            VOLTAR
          </Button>
          <Button
            disabled={isDisable || precoComDesconto < comparisonPrice}
            bg="#FFFFFF"
            color="#FF5000"
            border="1px solid transparent"
            w={['100%', '174px']}
            p="16px 32px"
            borderRadius="4px"
            _hover={{}}
            _active={{}}
            _focus={{
              '&:focus': {
                boxShadow: 'none !important'
              }
            }}
            onClick={actionNext}
          >
            CONTRATAR
          </Button>
        </Box>
      </HStack>
    </HStack>
  );
};
