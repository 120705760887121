import { Box } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { ButtonNavegate } from '../components/buttons/ButtonNavegate';
import { CardCondicoesSaude } from '../components/cards/CardCondicoesSaude';
import { LayoutContent } from '../components/layouts/LayoutContent';
import { getParams } from '../utils/getParams';

const LAYOUT_CONTENT_HEIGHT = '244px';

export const CondicoesSaudeInicio = () => {
  const [maxWidth, setMaxWidth] = useState('');
  const [isDisable, setIsDisable] = useState(false);

  const query = location.pathname;

  const checkboxBeneficiarios =
    useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleChecked = () => {
    checkboxBeneficiarios.current.querySelectorAll('[type=checkbox]:checked')
      .length === 0 && setIsDisable(true);
    checkboxBeneficiarios.current.querySelectorAll('[type=checkbox]:checked')
      .length === 1 && setIsDisable(false);
  };

  useEffect(() => {
    setMaxWidth(checkboxBeneficiarios.current?.clientWidth.toString());
  });

  return (
    <LayoutContent currentStep={0} zIndex={10} title={'Condições de Saúde'}>
      <Box
        w="100%"
        h={['', `calc(100vh - ${LAYOUT_CONTENT_HEIGHT})`]}
        p={{ base: '0', md: '30px' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CardCondicoesSaude
          checkboxBeneficiarios={checkboxBeneficiarios}
          handleChecked={handleChecked}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          m="30px 0 !important"
          w="100%"
          maxW={maxWidth}
        >
          <ButtonNavegate
            disable={isDisable}
            actionPrev={() => {
              history.back();
            }}
            actionNext={() =>
              (window.location.href = `/condicoes-saude/questionario/${getParams(
                query
              )}`)
            }
          />
        </Box>
      </Box>
    </LayoutContent>
  );
};
