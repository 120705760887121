import { HStack, Stack } from '@chakra-ui/react';

import { ProductTypes } from '../../types';
import { getNumberFormat } from '../../utils/getNumberFormat';
import { getInstallmentOptions } from '../../utils/money.util';
import { RegistrationSelect } from '../inputs/RegistrationSelect';

type FormPixProps = {
  price: number;
  installments: string;
  productType: ProductTypes;
  setInstallments: (newState: string) => void;
  validity: string;
  setValidity: (newState: string) => void;
};

export const FormPix = ({
  price,
  installments,
  productType,
  setInstallments,
  validity,
  setValidity
}: FormPixProps) => {
  const installmentOptions = getInstallmentOptions(price, productType);

  const optionsVencimento = Array.from(
    { length: 31 },
    (_, index) => index + 1
  ).map((el) => ({ option: String(el) }));

  return (
    <Stack
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      p={['40px 20px', '40px 20px', '30px 50px 20px 30px']}
      bg="#FFFFFF"
      m="5px 0 0 0 !important"
      maxW="850px"
      w="100%"
      borderRadius="0 !important"
    >
      <HStack
        p="0 0 20px"
        m="0 !important"
        w="100%"
        display="flex"
        gap={['0px', '0px', '30px']}
        flexDirection={['column', 'column', 'row']}
      >
        <RegistrationSelect
          id="data-vencimento-select"
          width={['100%', '100%', '50%']}
          placeholder={'Data do Vencimento*'}
          textLabel={validity ? 'Data do Vencimento*' : ''}
          textErrorMessage={validity === '' ? 'Campo Obrigatório' : ''}
          value={validity}
          setValue={setValidity}
          options={optionsVencimento}
        />
        <RegistrationSelect
          id="parcelamento-select"
          placeholder={'Parcelamento*'}
          textLabel={installments ? 'Parcelamento*' : ''}
          textErrorMessage={installments === '' ? 'Campo Obrigatório' : ''}
          value={installments}
          setValue={setInstallments}
          options={installmentOptions}
        />
      </HStack>
    </Stack>
  );
};
