import { createIcon } from '@chakra-ui/react';

export const YoutubeIcon = createIcon({
  displayName: 'YoutubeIcon',
  viewBox: '0 0 45 46',
  path: [
    <circle key="1" cx="22.5" cy="23" r="22.5" fill="#FF0000" />,
    <path key="2" d="M18 30.2L29.8993 23.4503L18 16.7V30.2Z" fill="white" />
  ]
});
