import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  HStack,
  ListItem,
  OrderedList,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { apiSensedia } from '../../api/api-sensedia';
import { getHiddenAssistsIds } from '../../utils/general.util';
import { getNumberFormat } from '../../utils/getNumberFormat';
import { wlLocalStorage } from '../../utils/localStorage.util';
import {
  getMinimumInstallmentValue,
  getMonthNameByChar,
  PaymentFrequencyType
} from '../../utils/money.util';
import {
  getProductType,
  MAX_NUMBER_COVERAGES
} from '../../utils/products.utils';
import { ButtonSelect } from '../buttons/ButtonSelect';
import { ReadMore } from '../fragments/ReadMore';
import { ModalLoad } from '../modals/ModalLoad';

type CardsHomeForMoreProductsProps = {
  reference: React.MutableRefObject<HTMLDivElement>;
  id: string;
  title: string;
  subtitle: string;
  text: string;
  price: number;
  assistences: {
    id_servico: number;
    assist: string;
  }[];
  covers: {
    id_cobertura: number;
    cover: string;
    value: string;
  }[];
  recommended: boolean;
  codigo_corretor: number;
  codigo_estrutura_venda: number;
  quotationDatas: any;
  quotationMin: number;
  quotationMax: number;
  corretagem: number;
  agenciamento: number;
  intermediacao?: number;
  initialValue: number;
  websiteId: string;
  websiteStatus: string;
  product_id: string;
  color: string;
  disabled?: boolean;
  product: any;
  maxCoveragesNumber: number;
  redirectProdId: string | null;
};

const ALLOWED_VALUES = [
  10000, 20000, 30000, 40000, 50000, 70000, 100000, 150000, 200000, 250000,
  300000, 400000, 450000
];

export const CardsHomeForMoreProducts = ({
  reference,
  recommended,
  id,
  title,
  subtitle,
  price,
  text,
  covers,
  codigo_corretor,
  codigo_estrutura_venda,
  assistences,
  quotationDatas,
  quotationMin,
  quotationMax,
  websiteId,
  websiteStatus,
  product_id,
  initialValue,
  corretagem,
  agenciamento,
  intermediacao,
  color,
  disabled,
  product,
  maxCoveragesNumber,
  redirectProdId
}: CardsHomeForMoreProductsProps) => {
  const hiddenAssistsIds = getHiddenAssistsIds();

  const minAllowedIdx = ALLOWED_VALUES.indexOf(Number(quotationMin));
  const maxAllowedIdx = ALLOWED_VALUES.indexOf(Number(quotationMax));

  const [sliderValue, setSliderValue] = useState(initialValue);
  const [currentValue, setCurrentValue] = useState(price);

  const [openModal, setOpenModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [productsRangedValues, setProductsRangedValues] = useState([
    {
      value: 10000,
      price: initialValue
    }
  ]);
  const [isReadMore, setIsReadMore] = useState(true);

  const [orderListAssistences, setOrderListAssistences] = useState(false);

  const [showMoreAssis, setShowMoreAssis] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const websiteColor = color;
  const subdomain = wlLocalStorage.getItem('subdomain');
  const changeSlierAndPrice = (v: number) => {
    if (quotationMin && quotationMax) {
      const valueToSet = ALLOWED_VALUES[v - 1 + minAllowedIdx];
      if (valueToSet) {
        setSliderValue(valueToSet);
      }
    }
  };

  useEffect(() => {
    const teste = productsRangedValues.filter((product) => {
      return Number(product.price);
      // if (product.value === sliderValue) {
      //   return Number(product.price);
      // }
    });

    setCurrentValue(
      productsRangedValues?.find((product) => product.value == sliderValue)
        ?.price as number
    );
  });

  useEffect(() => {
    if (
      quotationDatas &&
      quotationMin > 0 &&
      quotationMax > 0 &&
      corretagem !== undefined &&
      agenciamento !== undefined
    ) {
      getDataProducts(quotationDatas);
    }

    // console.log('agenciamento e corretagem', agenciamento, corretagem);
  }, [quotationDatas]);

  async function getDataProducts(quotationDatas: any) {
    try {
      let data = {
        quotation: {
          ...quotationDatas,
          percentual_agenciamento: agenciamento,
          percentual_corretagem: corretagem
        },
        minValue: Number(quotationMin),
        maxValue: Number(quotationMax),
        subdomain,
        product_id,
        websiteId,
        websiteStatus
      };

      if (intermediacao) {
        const corretagemCalculada = Number(corretagem);
        // console.log('corretagem calculada', corretagemCalculada);
        data = {
          quotation: {
            ...quotationDatas,
            percentual_agenciamento: agenciamento,
            percentual_corretagem: corretagemCalculada,
            percentual_intermediacao: intermediacao
          },
          minValue: Number(quotationMin),
          maxValue: Number(quotationMax),
          subdomain,
          product_id,
          websiteId,
          websiteStatus
        };
      }

      const api = await apiSensedia();

      const res = await api.post(`/v1/vendas/cotacoes-faixas`, data);

      setProductsRangedValues(res.data);
      // console.log('dados range', res.data);
    } catch (e) {
      console.log(e);
    }
  }

  const isProductVariable = () => {
    return Boolean(quotationMin) && Boolean(quotationMax);
  };

  return (
    <>
      <VStack
        ref={reference}
        m="0 0 10px 0 !important"
        alignSelf="start"
        justifyContent="space-between"
      >
        <Box
          w={['288px', '288px', '288px', '376px']}
          borderRadius="10px"
          p={{ base: '30px 16px', md: '30px 16px', lg: '30px 38px' }}
          boxShadow=" 0px 6px 10px rgba(115, 137, 169, 0.5)"
          borderLeft={`10px solid ${websiteColor}`}
        >
          <Text
            color="#323232"
            fontSize="1.25rem"
            lineHeight="2rem"
            fontWeight="700"
            textAlign="center"
          >
            {subtitle}
          </Text>
          <Stack m="20px 0 0 0 !important">
            {text ? (
              <div
                className="content"
                style={{ minHeight: '53px', paddingBottom: '5px' }}
              >
                <ReadMore
                  isReadMore={isReadMore}
                  text={text}
                  toggleReadMore={toggleReadMore}
                  websiteColor={websiteColor}
                />
              </div>
            ) : (
              <div style={{ minHeight: '25px', paddingBottom: '5px' }}>
                <p style={{ display: 'inline', width: '100%' }}>-</p>
              </div>
            )}
            <Text
              color="#323232"
              fontWeight="400"
              m="40px 0 0 0 !important"
              w="100%"
              textAlign="center"
            >
              A partir de:
            </Text>
            <Box
              m="0 0 20px 0 !important"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                color="#323232"
                fontSize="2.5rem"
                lineHeight="3.75rem"
                fontWeight="700"
              >
                {getNumberFormat(
                  getMinimumInstallmentValue(
                    price,
                    getProductType(Number(id))
                  ).installment?.toFixed(2)
                )}
              </Text>
              <Text
                color="#323232"
                fontSize="1.2rem"
                lineHeight="1.3rem"
                fontWeight="400"
              >
                &nbsp;/
                {getMonthNameByChar(
                  getMinimumInstallmentValue(price, getProductType(Number(id)))
                    .frequency as PaymentFrequencyType
                )}
              </Text>
            </Box>
            <Box
              m="0 !important"
              h="100px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box borderBottom={`1px dashed ${websiteColor}`}></Box>
            </Box>
            <HStack w="100%">
              <Text>Indenização</Text>
              <ChevronDownIcon
                cursor="pointer"
                onClick={() => {
                  setShowMoreAssis(!showMoreAssis);
                }}
              />
            </HStack>
            <Stack
              m="20px 0 0 0 !important"
              minH={`${maxCoveragesNumber * 52}px`}
            >
              {covers.map((item, index) => {
                if (showMoreAssis || index < MAX_NUMBER_COVERAGES) {
                  return (
                    <Box
                      key={index}
                      mt="10px"
                      display="flex"
                      alignItems="start"
                      w="max-content"
                      position="relative"
                    >
                      <CheckIcon
                        mr="12px"
                        w="22px"
                        h="22px"
                        borderRadius="3px"
                        p="2px"
                        color="#FFFFFF"
                        bg={websiteColor!}
                      />
                      <Box>
                        <Text
                          color={'#323232'}
                          fontSize="0.875rem"
                          lineHeight="1.375rem"
                          fontWeight="400"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          width={{ base: '210px', md: '210px', lg: '250px' }}
                        >
                          {item.cover}
                        </Text>
                        <Text
                          m="0 !important"
                          color={'#323232'}
                          fontSize="0.875rem"
                          lineHeight="1.375rem"
                          fontWeight="700"
                        >
                          {getNumberFormat(Number(item.value).toFixed(2))}
                        </Text>
                      </Box>
                    </Box>
                  );
                }
              })}
            </Stack>
            <Stack m="20px 0 0 0 !important" position="relative">
              <Box display="flex" gridGap="10px" alignItems="center">
                <Text>Ver assistências</Text>
                <ChevronDownIcon
                  cursor="pointer"
                  onClick={() => setOrderListAssistences(!orderListAssistences)}
                />
              </Box>
              {orderListAssistences && (
                <OrderedList
                  gap="10px"
                  bg="#FFFFFF"
                  w="100%"
                  m="10px 0 10px 0 !important"
                >
                  {assistences
                    .filter((ass) => !hiddenAssistsIds.includes(ass.id_servico))
                    .map((item, index) => {
                      return (
                        <ListItem
                          key={index}
                          display="flex"
                          alignItems="start"
                          justifyContent="start"
                          m="10px 0 0 0 !important"
                        >
                          <CheckIcon
                            m="0 12px 0 0 !important"
                            w="22px"
                            h="22px"
                            borderRadius="3px"
                            p="2px"
                            color="#FFFFFF"
                            bg={websiteColor!}
                          />
                          <Text
                            m="0 !important"
                            color={'#323232'}
                            fontSize="0.875rem"
                            lineHeight="1.375rem"
                            fontWeight="400"
                          >
                            {item.assist}
                          </Text>
                        </ListItem>
                      );
                    })}
                </OrderedList>
              )}
            </Stack>
          </Stack>
          <ButtonSelect
            quotationData={quotationDatas}
            idProduct={id}
            product_id={product_id}
            product={product}
            redirectProdId={redirectProdId}
            priceProduct={String(sliderValue)}
            productSave={setOpenModal}
            recommended={recommended}
            title={title}
            subtitle={subtitle}
            text={text}
            assist={assistences}
            cover={covers}
            codigo_corretor={codigo_corretor}
            codigo_estrutura_venda={codigo_estrutura_venda}
            buttonColor={websiteColor}
            disabled={disabled}
            corretagem={corretagem}
            agenciamento={agenciamento}
            intermediacao={intermediacao}
          />
        </Box>
      </VStack>
      <ModalLoad isOpenModal={openModal} content={'Salvando produto...'} />
    </>
  );
};
