import { extendTheme } from '@chakra-ui/react';

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
  opacity: 1
};

export const theme = extendTheme({
  colors: {
    custom: {
      500: '#fd6520'
    },
    customBlueWhiteLabel: {
      500: '#001E64'
    }
  },
  fonts: {
    body: 'Roboto',
    heading: 'Roboto'
  },
  styles: {
    global: {
      body: {
        bg: '#E5E5E5',
        color: 'gray.900',
        fontSize: { base: '75%', md: '87.5%', lg: '100%' }
      },
      a: {
        textDecoration: 'none !important',
        _focus: { outline: 'none !important', boxShadow: 'none' }
      }
    }
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label':
              {
                ...activeLabelStyles
              },
            label: {
              opacity: 0,
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top'
            }
          }
        }
      }
    }
  }
});
