export const removeDiacritic = (value: string) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const isValidEmail = (email: string) => {
  // Regular expression pattern for a basic email format
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Test the email against the pattern
  return emailPattern.test(email);
}