/* eslint-disable react/no-children-prop */
import {
  InputGroup,
  Select,
  Spinner,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';

type RegistrationSelectProps = {
  width?: string[];
  placeholder: string;
  displayTextLabel?: boolean;
  textLabel?: string;
  id?: string;
  textErrorMessage: string;
  value: any;
  setValue?: any;
  options: {
    option: string;
    value?: string;
  }[];
  children?: ReactNode;
  disable?: boolean;
};

export const RegistrationSelect = ({
  width,
  placeholder,
  displayTextLabel,
  id,
  textLabel,
  textErrorMessage,
  value,
  setValue,
  options,
  children,
  disable
}: RegistrationSelectProps) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [showPlaceholderOverField, setShowPlaceholderOverField] = useState(
    Boolean(value) || disable
  );
  const defaultColor = '#FF5000';

  return (
    <>
      <Stack w={width ? width : '100%'} m="0 !important" position="relative">
        {displayTextLabel && showPlaceholderOverField ? (
          <Text
            w={'100%'}
            fontSize={'12px'}
            m="0 !important"
            fontWeight="400"
            position="absolute"
            top="-8px"
            left="16px"
            zIndex="10"
            color="#828282"
            display={undefined}
          >
            {placeholder}
          </Text>
        ) : (
          <></>
        )}
        <InputGroup
          m="0 !important"
          w={['100%']}
          display="flex"
          flexDirection="column"
        >
          <Select
            id={id}
            disabled={disable ?? false}
            icon={children ? <Spinner /> : <MdArrowDropDown />}
            m="0 !important"
            h="50px"
            bg="#FFFFFF"
            border="none"
            borderBottom={`1px solid #001E64 !important`}
            borderRadius="0 !important"
            placeholder={placeholder}
            _placeholder={{
              color: '#828282',
              fontSize: '1rem',
              fontWeight: '400',
              lineHeight: '1.5rem'
            }}
            value={value}
            onBlur={() => {
              if (!value) {
                setShowPlaceholderOverField(false);
              }
              setErrorMessage(true);
            }}
            onFocus={() => {
              setShowPlaceholderOverField(true);
            }}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            _hover={
              defaultColor
                ? { borderBottom: `1px solid ${defaultColor} !important` }
                : { borderBottom: '1px solid #FF5000 !important' }
            }
            _focus={
              defaultColor
                ? { borderBottom: `1px solid ${defaultColor} !important` }
                : { borderBottom: '1px solid #FF5000 !important' }
            }
            transition="0.2s"
          >
            <></>
            {options.map((item, index) => {
              return (
                <option key={index} value={item.value ?? item.option}>
                  {item.option}
                </option>
              );
            })}
          </Select>
        </InputGroup>
        {errorMessage && textErrorMessage && (
          <Text
            color="#AA2C23"
            fontSize="0.875rem"
            lineHeight="1.5rem"
            w="100%"
          >
            {textErrorMessage}
          </Text>
        )}
      </Stack>
    </>
  );
};
