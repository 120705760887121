import { HStack, VStack } from '@chakra-ui/react';

import { RegistrationInput } from '../inputs/RegistrationInput';

type FormEnderecoProps = {
  cep: string;
  setCep: (newState: string) => void;
  submitCep: () => void;
  address: string;
  setAddress: (newState: string) => void;
  number: string;
  setNumber: (newState: string) => void;
  complement: string;
  setComplement: (newState: string) => void;
  district: string;
  setDistrict: (newState: string) => void;
  city: string;
  setCity: (newState: string) => void;
  uf: string;
  setUf: (newState: string) => void;
};
export const FormEndereco = ({
  cep,
  setCep,
  submitCep,
  address,
  setAddress,
  number,
  setNumber,
  complement,
  setComplement,
  district,
  setDistrict,
  city,
  setCity,
  uf,
  setUf
}: FormEnderecoProps) => {
  return (
    <>
      <VStack
        data-testid="FormEndereco"
        maxW="850px"
        w="100%"
        m="0 !important"
        bg="#FFFFFF"
        p={['30px 15px', '30px 15px', '30px 30px 60px']}
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25);"
        display="flex"
        gridGap={['30px', '50px']}
      >
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <RegistrationInput
            id="FormEndereco-cep-input"
            width={['100%', '100%', '40%']}
            type={'text'}
            mask={'99999-999'}
            placeholder={'CEP*'}
            textLabel={cep ? 'CEP*' : ''}
            textErrorMessage={
              cep.replaceAll('_', '').length <= 1
                ? 'Campo Obrigatório'
                : cep.replaceAll('_', '').length < 9
                ? 'CEP inválido'
                : ''
            }
            value={cep}
            setValue={setCep}
            necessarySubmit={true}
            handleSubmit={submitCep}
          />
          <RegistrationInput
            type={'text'}
            mask={''}
            placeholder={'Endereço*'}
            textLabel={address ? 'Endereço*' : ''}
            textErrorMessage={
              address
                ?.replace(/[/.,~!@#$%¨*()=+:;?&_[\]<>'"|]/g, '')
                ?.replaceAll('-', '') === ''
                ? 'Campo Obrigatório'
                : ''
            }
            value={
              address
                ?.replace(/[/.,~!@#$%¨*()=+:;?&_[\]<>'"|]/g, '')
                ?.replaceAll('-', '') ?? ''
            }
            setValue={setAddress}
          />
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <RegistrationInput
            id="FormEndereco-numero-input"
            width={['100%', '100%', '40%']}
            type={'number'}
            mask={''}
            placeholder={'Número*'}
            textLabel={number ? 'Número*' : ''}
            textErrorMessage={number === '' ? 'Campo Obrigatório' : ''}
            value={number}
            setValue={setNumber}
          />
          <RegistrationInput
            type={'text'}
            mask={''}
            placeholder={'Complemento'}
            textLabel={complement ? 'Complemento' : ''}
            textErrorMessage={''}
            value={complement}
            setValue={setComplement}
          />
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          pb={{ base: '30px', md: '0' }}
          flexDirection={['column', 'column', 'row']}
        >
          <RegistrationInput
            type={'text'}
            mask={''}
            placeholder={'Bairro*'}
            textLabel={district ? 'Bairro*' : ''}
            textErrorMessage={
              district
                ?.replace(/[/.,~!@#$%¨*()=+:;?&_[\]<>'"|]/g, '')
                ?.replaceAll('-', '') === ''
                ? 'Campo Obrigatório'
                : ''
            }
            value={
              district
                ?.replace(/[/.,~!@#$%¨*()=+:;?&_[\]<>'"|]/g, '')
                ?.replaceAll('-', '') ?? ''
            }
            setValue={setDistrict}
          />
          <RegistrationInput
            type={'text'}
            mask={''}
            placeholder={'Cidade*'}
            textLabel={city ? 'Cidade*' : ''}
            textErrorMessage={
              city
                ?.replace(/[/.,~!@#$%¨*()=+:;?&_[\]<>'"|]/g, '')
                ?.replaceAll('-', '') === ''
                ? 'Campo Obrigatório'
                : ''
            }
            value={
              city
                ?.replace(/[/.,~!@#$%¨*()=+:;?&_[\]<>'"|]/g, '')
                ?.replaceAll('-', '') ?? ''
            }
            setValue={setCity}
          />
          <RegistrationInput
            width={['100%', '100%', '40%']}
            type={'text'}
            mask={'aa'}
            placeholder={'UF*'}
            textLabel={uf ? 'UF*' : ''}
            textErrorMessage={
              uf === ''
                ? 'Campo Obrigatório'
                : uf?.replaceAll('_', '').length == 1
                ? 'UF inválida'
                : ''
            }
            value={uf ?? ''}
            setValue={setUf}
          />
        </HStack>
      </VStack>
    </>
  );
};
