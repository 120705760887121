import { createIcon } from '@chakra-ui/react';

export const SorteiosMensaisIcon = createIcon({
  displayName: 'SorteiosMensaisIcon',
  viewBox: '0 0 40 40',
  path: [
    <path
      key="1"
      d="M20 2.6261C10.4201 2.6261 2.62659 10.4201 2.62659 20C2.62659 29.5799 10.4201 37.3734 20 37.3734C29.5799 37.3734 37.3734 29.5799 37.3734 20C37.3734 10.4201 29.5799 2.6261 20 2.6261ZM20 40C8.97185 40 0 31.0281 0 20C0 8.97185 8.97185 0 20 0C31.0281 0 40 8.97185 40 20C40 31.0281 31.0281 40 20 40Z"
      fill="#0066F3"
    />,
    <path
      key="2"
      d="M20 21.3774C16.9349 21.3774 14.4416 18.884 14.4416 15.819C14.4416 12.7539 16.9349 10.2601 20 10.2601C23.0651 10.2601 25.5584 12.7539 25.5584 15.819C25.5584 16.5441 24.9705 17.132 24.2454 17.132C23.5202 17.132 22.9323 16.5441 22.9323 15.819C22.9323 14.2017 21.6168 12.8862 20 12.8862C18.3832 12.8862 17.0677 14.2017 17.0677 15.819C17.0677 17.4358 18.3832 18.7513 20 18.7513C20.7251 18.7513 21.313 19.3392 21.313 20.0643C21.313 20.7894 20.7251 21.3774 20 21.3774Z"
      fill="#0066F3"
    />,
    <path
      key="3"
      d="M20 29.8684C16.9349 29.8684 14.4416 27.375 14.4416 24.3099C14.4416 23.5848 15.0295 22.9969 15.7546 22.9969C16.4797 22.9969 17.0677 23.5848 17.0677 24.3099C17.0677 25.9268 18.3832 27.2423 20 27.2423C21.6168 27.2423 22.9323 25.9268 22.9323 24.3099C22.9323 22.6926 21.6168 21.3774 20 21.3774C19.2749 21.3774 18.6869 20.7892 18.6869 20.0641C18.6869 19.339 19.2749 18.7513 20 18.7513C23.0651 18.7513 25.5584 21.2449 25.5584 24.3099C25.5584 27.375 23.0651 29.8684 20 29.8684Z"
      fill="#0066F3"
    />,
    <path
      key="4"
      d="M20.0012 31.9267C19.9762 31.9267 19.9512 31.9262 19.9263 31.9247C19.2021 31.8841 18.648 31.2638 18.6892 30.5397L18.8053 28.4814C18.8459 27.7573 19.4706 27.2032 20.1898 27.2443C20.914 27.285 21.4681 27.9052 21.427 28.6294L21.3108 30.6876C21.2716 31.3863 20.6925 31.9267 20.0012 31.9267Z"
      fill="#0066F3"
    />,
    <path
      key="5"
      d="M18.7758 9.32341C18.7748 9.33321 18.7734 9.34203 18.7724 9.35134C18.7734 9.34154 18.7748 9.33223 18.7758 9.32341ZM18.768 9.4018C18.7675 9.41454 18.7675 9.42924 18.7675 9.44737C18.7675 9.43218 18.7675 9.4165 18.768 9.4018ZM20.036 11.8231C20.0252 11.8231 20.0144 11.8231 20.0031 11.8226C19.2785 11.805 18.7048 11.2028 18.7229 10.4777C18.742 9.6943 18.7567 9.48068 18.7695 9.37241C18.8082 8.68207 19.3804 8.13432 20.0805 8.13432C20.8057 8.13432 21.3936 8.72225 21.3936 9.44737C21.3936 9.57034 21.3877 9.6066 21.3784 9.67568C21.3774 9.68842 21.3652 9.8403 21.348 10.5419C21.3309 11.2562 20.7464 11.8231 20.036 11.8231Z"
      fill="#0066F3"
    />
  ]
});
