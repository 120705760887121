import { HStack, Switch, Text } from '@chakra-ui/react';

import { getNumberFormat } from '../../utils/getNumberFormat';
import { wlLocalStorage } from '../../utils/localStorage.util';
import {
  getInstallmentsNumberByChar,
  getMonthNameByChar,
  PaymentFrequencyType
} from '../../utils/money.util';
import { isCoberturaDIT } from '../../utils/products.utils';

type SwicthProps = {
  coverage: any;
  states: any;
  setStates: any;
  isProductVariable: any;
  size: string;
  frequencyChar: string;
  onChange?: () => void;
};

export const SwitchValue = ({
  coverage,
  states,
  setStates,
  isProductVariable,
  size,
  frequencyChar,
  onChange
}: SwicthProps) => {
  return (
    <HStack w="100%" justify="end" gap={['16px', '30px']} mt={['26px', '0']}>
      <Text
        m="0 !important"
        bg="#AAAAAA3D"
        w={['97px', '170px']}
        p={['2px 14px', '4px 12px']}
        borderRadius="15px"
        fontSize={['0.75rem', '1rem']}
        lineHeight={['1.375', '1.5rem']}
        fontWeight="400"
        textAlign="center"
        display="flex"
        justifyContent="center"
      >
        {`${getNumberFormat(
          (
            coverage.valor_premio_calculado /
            getInstallmentsNumberByChar(frequencyChar as PaymentFrequencyType)
          ).toFixed(2)
        )}/${getMonthNameByChar(
          frequencyChar as PaymentFrequencyType
        )?.toLowerCase()}`}
      </Text>
      <Switch
        id={'switch-' + coverage.id}
        isChecked={
          (coverage.contratacao_obrigatoria ? true : undefined) ||
          /*(!isProductVariable ? true : undefined) ||*/
          states[coverage.id]?.checked
        }
        onChange={(e) => {
          if (!onChange) {
            states[coverage.id].checked = !states[coverage.id]?.checked;
            setStates({ ...states });
          } else {
            if (!states[coverage.id]?.checked) {
              onChange();
            } else {
              states[coverage.id].checked = false;
              setStates({ ...states });
              wlLocalStorage.removeItem('tipoCategoriaProfissional');
              wlLocalStorage.removeItem('tipoFranquia');
            }
          }
        }}
        isDisabled={
          coverage.contratacao_obrigatoria ? true : undefined /*||
          (!isProductVariable ? true : undefined)*/
        }
        m="0 !important"
        size={size}
        colorScheme="orange"
        _hover={{}}
        _active={{}}
        _focus={{
          '&:focus': {
            boxShadow: 'none !important'
          }
        }}
      />
    </HStack>
  );
};
