import { createIcon } from '@chakra-ui/react';

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 22 22',
  path: [
    <path
      key="1"
      d="M8.60369 21.3445V0.22514H13.9432V21.3445H8.60369ZM0.713778 13.4545V8.11506H21.8331V13.4545H0.713778Z"
      fill="#323232"
    />
  ]
});
