import { Link, Text } from '@chakra-ui/react';

import { WhatsAppIcon } from '../../assets/icons/redes/WhatsAppIcon';

type ButtonWhatsAppProps = {
  numberWhatsApp: string;
  width?: string;
};

export const ButtonWhatsApp = ({
  numberWhatsApp,
  width
}: ButtonWhatsAppProps) => {
  return (
    <>
      <Link
        pointerEvents={
          window.location.pathname.includes('preview') ? 'none' : 'auto'
        }
        p={{
          base: '13px 35px 13px 35px',
          sm: '13px 70px 13px 70px',
          md: '13px 47px 13px 47px'
        }}
        w="290px"
        h="48px"
        borderRadius="133.5px"
        color="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="#006217"
        href={`https://api.whatsapp.com/send?phone=${numberWhatsApp}&text=Olá, estou precisando de apoio a respeito do(s) produto(s) deste site, pode me ajudar?`}
        target="_blank"
        _hover={{
          WebkitFilter: ' brightness(90%)',
          transition: 'all 0.4s',
          WebkitTransition: 'all 0.4s'
        }}
        sx={{
          '&:focus': {
            boxShadow: 'none !important'
          }
        }}
      >
        <WhatsAppIcon w="1rem" h="1rem" />
        <Text fontSize="0.875rem" ml="15px">
          Chamar no WhatsApp
        </Text>
      </Link>
    </>
  );
};
