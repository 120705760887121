import { createIcon } from '@chakra-ui/react';

export const DoencaTerminalIcon = createIcon({
  displayName: 'DoencaTerminalIcon',
  viewBox: '0 0 34 45',
  path: [
    <path
      key="1"
      d="M22.5 0H11.25C9.88393 0 8.83929 1.04464 8.83929 2.41071V3.21429H4.01786C1.76786 3.21429 0 4.98214 0 7.23214V40.9821C0 43.2321 1.76786 45 4.01786 45H29.7321C31.9821 45 33.75 43.2321 33.75 40.9821V7.23214C33.75 4.98214 31.9821 3.21429 29.7321 3.21429H24.9107V2.41071C24.9107 1.04464 23.8661 0 22.5 0ZM10.4464 2.41071C10.4464 1.92857 10.7679 1.60714 11.25 1.60714H22.5C22.9821 1.60714 23.3036 1.92857 23.3036 2.41071V4.81785H10.4464V2.41071ZM29.7321 4.82143C31.0982 4.82143 32.1429 5.86607 32.1429 7.23214V40.9821C32.1429 42.3482 31.0982 43.3929 29.7321 43.3929H4.01786C2.65179 43.3929 1.60714 42.3482 1.60714 40.9821V7.23214C1.60714 5.86607 2.65179 4.82143 4.01786 4.82143H8.83929V5.62141C8.83929 6.10355 9.16072 6.42498 9.64286 6.42498H24.1071C24.5893 6.42498 24.9107 6.10355 24.9107 5.62141V4.82143H29.7321Z"
      fill="#146CFA"
    />,
    <path
      key="2"
      d="M12.3721 18.4723C12.4525 18.1509 12.7739 17.8295 13.0953 17.8295C13.4971 17.8295 13.8185 18.0706 13.8989 18.392L16.5507 27.2313L19.0418 19.6777C19.1221 19.3563 19.4435 19.1152 19.765 19.1152C20.0864 19.1152 20.4078 19.3563 20.4882 19.6777L23.3007 27.392H26.4884C26.9706 27.392 27.292 27.7134 27.292 28.1956C27.292 28.6777 26.9706 28.9991 26.4884 28.9991H22.7382C22.4168 28.9991 22.0953 28.7581 22.015 28.4366L19.8453 22.3295L17.2739 30.0438V30.1241L17.1935 30.2045C17.1935 30.2848 17.1132 30.2848 17.1132 30.3652L17.0328 30.4456C16.9525 30.5259 16.8721 30.5259 16.7918 30.6063H16.3096C16.2293 30.6063 16.1489 30.5259 16.0685 30.4456L15.9882 30.3652C15.9078 30.2848 15.9078 30.2848 15.9078 30.2045C15.9078 30.2045 15.9078 30.1241 15.8275 30.1241V30.0438L13.4168 21.767L11.9703 28.2759C11.89 28.6777 11.5685 28.9188 11.1668 28.9188H7.22926C6.74711 28.9188 6.42568 28.5973 6.42568 28.1152C6.42568 27.6331 6.74711 27.3116 7.22926 27.3116H10.3632L12.3721 18.4723Z"
      fill="#146CFA"
    />
  ]
});
