import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

import { Page } from './components/layouts/Page';
import { AppRoutes } from './routes/AppRoutes';
import { theme } from './styles/theme';

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Page>
            <AppRoutes />
          </Page>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
