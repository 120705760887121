import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type LayoutFooterProps = {
  children: ReactNode;
  items: string;
};

export const LayoutFooter = ({ children, items }: LayoutFooterProps) => {
  return (
    <>
      <HStack w="100%" justifyContent="center">
        <Box
          //
          w="100%"
          display="flex"
          justifyContent="center !important"
          p={[
            '26px 40px 30px 40px',
            '26px 40px 30px 40px',
            '55.5px 40px 40px 40px'
          ]}
          aria-label="footer"
        >
          {children}
        </Box>
      </HStack>
      <HStack w="100%" position="relative" mt={['20px', '10px', '10px']}>
        <Image
          src={items}
          w="77px"
          h="86px"
          position="absolute"
          right={['40%', '40%', '4.5%']}
          top="0"
        />
        <VStack
          w="100%"
          alignItems="center"
          gap="30px"
          m={[
            '116px 0 26px 0 !important',
            '116px 0 26px 0 !important',
            '0 0 40px 0 !important'
          ]}
        >
          <Image src="/images/logo-sulAmerica.png" w="232px" />
          <Text
            fontSize="0.75rem"
            textAlign="center"
            w={['50%', '50%', '50%', '100%']}
          >
            {new Date().getFullYear()} SulAmérica - Todos os direitos reservados
            | CNPJ: 01.685.053/0013-90 - Rua dos Pinheiros, 1673 - São Paulo/SP
            - CEP: 05.422-012
          </Text>
        </VStack>
      </HStack>
    </>
  );
};
