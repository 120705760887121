import { createIcon } from '@chakra-ui/react';

export const FuneralIcon = createIcon({
  displayName: 'FuneralIcon',
  viewBox: '0 0 47 52',
  path: [
    <path
      key="1"
      d="M16.3751 46.1444C28.0116 45.1453 31.0601 33.4132 31.0601 33.4132C32.2039 28.7029 19.8377 34.6687 17.0737 38.5227C17.4358 34.3538 20.0187 29.6554 22.7569 26.5176C23.6888 30.023 28.4304 31.2001 30.9978 28.103C32.6337 33.2156 40.6622 31.3015 40.0512 25.9446C44.8395 28.1756 49.4514 21.2184 45.1653 17.9802C50.3157 16.0423 48.6486 8.27453 43.2082 8.86153C45.7548 3.99431 38.7509 -0.193548 35.3399 3.99226C33.7039 -1.12035 25.6754 0.793698 26.2864 6.15066C21.3274 3.96035 16.8863 10.8768 21.1724 14.115C16.3274 15.8026 17.4717 22.8075 22.0683 23.3092C20.1376 25.1895 16.3762 28.3202 14.7083 34.7731C11.3724 28.8822 4.14475 26.5176 4.14475 26.5176C-1.97096 25.4355 0.674784 36.5747 13.4763 44.3505C13.6316 45.911 13.1815 51.3434 15.4384 50.9829C17.3537 50.7038 16.4942 47.5361 16.3751 46.1444ZM28.321 13.1208C31.1834 8.88827 37.6695 9.82701 38.7205 14.7241C39.7352 19.4523 34.3364 23.5794 29.9881 20.8885C27.4671 19.182 26.7423 15.8047 28.321 13.1208Z"
      fill="none"
      stroke="#146CFA"
      strokeWidth="2"
    />
  ]
});
