import { DateObjectUnits, DateTime } from "luxon";

export const delay = (delayInms: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

export const getDateTimeString = (currentdate: Date) => {
  return (
    currentdate.getFullYear() +
    '-' +
    ((currentdate.getMonth() + 1) < 10 ? '0' + (currentdate.getMonth() + 1) : (currentdate.getMonth() + 1)) +
    '-' +
    (currentdate.getDate() < 10 ? '0' + currentdate.getDate() : currentdate.getDate()) +
    ' ' +
    (currentdate.getHours() < 10
      ? '0' + currentdate.getHours()
      : currentdate.getHours()) +
    ':' +
    (currentdate.getMinutes() < 10
      ? '0' + currentdate.getMinutes()
      : currentdate.getMinutes()) +
    ':' +
    (currentdate.getSeconds() < 10
      ? '0' + currentdate.getSeconds()
      : currentdate.getSeconds())
  );
};

export const getDataNAnosAtras = (n: number) => {
  const brDateTime = DateTime.now().setZone('America/Sao_Paulo');
  brDateTime.minus({ years: n });
  const brTimeNYearsAgo = brDateTime.minus({ years: n });


  return brTimeNYearsAgo.toFormat('yyyy-MM-dd');
}

export const getDifferenceBetweenDatesInYears = (date1: DateObjectUnits | Date, date2: DateObjectUnits | Date) => {
  // Convert CustomDate objects to DateTime objects
  const dateTime1 = DateTime.fromObject(date1 as any).setZone('America/Sao_Paulo');
  const dateTime2 = DateTime.fromObject(date2 as any).setZone('America/Sao_Paulo');

  // Calculate the difference in years
  const diffYears = Math.trunc(Math.abs(dateTime2.diff(dateTime1, 'years').years));

  return diffYears;
}

export const datePartsToISOString = ({ year, month, day }: { year: string, month: string, day: string }) => {
  if (!Number(month) || Number(month) < 1 || Number(month) > 12) {
    throw new Error('O mês deve ser um número de 1 a 12')
  }

  const isoString = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}T00:00:00.000-03:00`;

  return isoString;
};