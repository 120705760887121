import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { DateObjectUnits } from 'luxon';
import { useEffect, useState } from 'react';

import { kinshipDgreesList } from '../../utils/kinshipDegrees';
import { wlLocalStorage } from '../../utils/localStorage.util';
import { occupationList } from '../../utils/occupations';
import { isValidEmail, removeDiacritic } from '../../utils/string-utils';
import { getDifferenceBetweenDatesInYears } from '../../utils/time-utils';
import {
  WL_MINIMUM_CUSTOMER_AGE,
  WL_STARTER_LEGAL_AGE
} from '../../utils/wl-consts.util';
import { RegistrationDropdown } from '../inputs/RegistrationDropdown';
import { RegistrationInput } from '../inputs/RegistrationInput';
import { RegistrationSelect } from '../inputs/RegistrationSelect';

type FormDadosCadastraisProps = {
  nameLegalGuardian: string;
  setNameLegalGuardian: (newState: string) => void;
  dateLegalGuardian: string;
  setDateLegalGuardian: (newState: string) => void;
  genreLegalGuardian: string;
  setGenreLegalGuardian: (newState: string) => void;
  occupationLegalGuardian: string;
  setOccupationLegalGuardian: (newState: string) => void;
  rendaLegalGuardian: string;
  setRendaLegalGuardian: (newState: string) => void;
  phoneLegalGuardian: string;
  setPhoneLegalGuardian: (newState: string) => void;
  emailLegalGuardian: string;
  setEmailLegalGuardian: (newState: string) => void;
  confirmedEmailLegalGuardian: string;
  setConfirmedEmailLegalGuardian: (newState: string) => void;
  cpfLegalGuardian: string;
  setCpfLegalGuardian: (newState: string) => void;
  cpfClient?: string;
  kinshipDegree: string;
  setKinshipDegree: (newState: string) => void;
  occupationCodeLegalGuardian: string;
  setOccupationCodeLegalGuardian: (newState: string) => void;
  clientsLegalGuardianAge: number;
  setClientsLegalGuardianAge: (newState: number) => void;
};

const WIDTH_SCREEN = window.screen.width;

export const FormResponsavelLegal = ({
  nameLegalGuardian,
  setNameLegalGuardian,
  dateLegalGuardian,
  setDateLegalGuardian,
  genreLegalGuardian,
  setGenreLegalGuardian,
  occupationLegalGuardian,
  setOccupationLegalGuardian,
  rendaLegalGuardian,
  setRendaLegalGuardian,
  phoneLegalGuardian,
  setPhoneLegalGuardian,
  emailLegalGuardian,
  setEmailLegalGuardian,
  confirmedEmailLegalGuardian,
  setConfirmedEmailLegalGuardian,
  cpfLegalGuardian,
  setCpfLegalGuardian,
  cpfClient,
  kinshipDegree,
  setKinshipDegree,
  occupationCodeLegalGuardian,
  setOccupationCodeLegalGuardian,
  clientsLegalGuardianAge,
  setClientsLegalGuardianAge
}: FormDadosCadastraisProps) => {
  const descriptions = occupationList.map((option) => {
    return option.descricao;
  });

  const occupationItems = occupationList.map((option) => {
    return option;
  });

  const findCodigoByDescricao = (
    options: Array<{ codigo: string; descricao: string }>,
    name: string
  ) => {
    for (let i = 0; i < options.length; i++) {
      const item = options[i];
      if (item.descricao.toLowerCase() === name.toLowerCase()) {
        return item.codigo;
      }
    }
  };

  const [cpfErrorMessage, setCpfErrorMessage] = useState('');

  const [showOcuppationOptions, setShowOcuppationOptions] = useState(false);

  // const [listOccupations, setListOccupations] = useState('');
  const [filteredList, setFilteredList] = useState(['']);

  const handleFilterBySearch = () => {
    const updateList = [...descriptions];
    const newList = updateList.filter((item) => {
      return (
        removeDiacritic(item.toLocaleLowerCase()).indexOf(
          removeDiacritic(occupationLegalGuardian.toLocaleLowerCase())
        ) !== -1
      );
    });

    setFilteredList(newList);
  };

  const setOccupationSelected = (item: string, index: number) => {
    setOccupationLegalGuardian(item);
    console.log('item profissao', item, 'numero', index);
    setOccupationCodigo(item);
    setShowOcuppationOptions(false);
  };

  const isOccupationCodeSetted = (): boolean => {
    const occupationCodigo = findCodigoByDescricao(
      occupationItems,
      occupationLegalGuardian
    );

    console.log('codigo profissao selecionada', occupationCodigo);

    if (!occupationCodigo) {
      setOccupationCodeLegalGuardian('');
      return false;
    }

    setOccupationCodeLegalGuardian(occupationCodigo);
    wlLocalStorage.setItem('occupationSelected', occupationCodigo);

    return true;
  };

  const setOccupationCodigo = (descricao: string) => {
    const occupationCodigo =
      findCodigoByDescricao(occupationItems, descricao) || '';
    console.log('código buscado', occupationCodigo);
    setOccupationCodeLegalGuardian(occupationCodigo);
    wlLocalStorage.setItem('occupationSelected', occupationCodigo);
    console.log(
      'código ocupação',
      occupationCodigo,
      wlLocalStorage.getItem('occupationSelected')
    );
  };

  const handleAge = (e: any) => {
    const today = new Date();
    const birthDateArray = e.target.value.split('-');
    const birthDate: DateObjectUnits = {
      day: birthDateArray[2],
      month: birthDateArray[1],
      year: birthDateArray[0]
    };

    const age = getDifferenceBetweenDatesInYears(birthDate, today);

    wlLocalStorage.setItem('legalGuardianEnteredAge', String(age));
    setClientsLegalGuardianAge(age);
  };

  //debug useEffect
  useEffect(() => {
    console.log({ nameLegalGuardian, cpfLegalGuardian, dateLegalGuardian });
  }, [nameLegalGuardian, cpfLegalGuardian, dateLegalGuardian]);

  useEffect(() => {
    handleFilterBySearch();
  }, [occupationLegalGuardian]);

  useEffect(() => {
    if (
      cpfLegalGuardian
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('_', '') === ''
    ) {
      setCpfErrorMessage('Campo obrigatório');
    } else if (
      cpfLegalGuardian
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('_', '').length < 11
    ) {
      setCpfErrorMessage('CPF inválido');
    } else if (cpfLegalGuardian === cpfClient) {
      setCpfErrorMessage('Esse CPF já foi utilizado');
    } else {
      setCpfErrorMessage('');
    }
  }, [cpfLegalGuardian]);

  return (
    <>
      <VStack
        data-testid="FormDadosCadastraisResponsavel"
        maxW="850px"
        w="100%"
        m="0 !important"
        bg="#FFFFFF"
        p={['30px 15px', '30px 15px', '30px 30px 60px']}
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25);"
        display="flex"
        gridGap={['30px', '50px']}
      >
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <RegistrationInput
            id="FormResponsavelLeagal-nome-input"
            type={'text'}
            mask={''}
            placeholder={'Nome*'}
            displayTextLabel={true}
            textErrorMessage={
              nameLegalGuardian.replace(
                /[/.,~!@#$%¨*()=+:;?&_0-9-[\]<>'"|]/g,
                ''
              ) === ''
                ? 'Campo Obrigatório'
                : ''
            }
            value={nameLegalGuardian.replace(
              /[/.,~!@#$%¨*()=+:;?&_0-9-[\]<>'"|]/g,
              ''
            )}
            setValue={setNameLegalGuardian}
          />
          <RegistrationInput
            id="FormResponsavelLeagal-cpf-input"
            type={'text'}
            mask={'999.999.999-99'}
            placeholder={'CPF*'}
            displayTextLabel={true}
            textErrorMessage={cpfErrorMessage}
            value={cpfLegalGuardian}
            setValue={(cpf: string) => {
              if (cpf) {
                setCpfLegalGuardian(
                  cpf
                    .replaceAll('.', '')
                    .replaceAll('-', '')
                    .replaceAll('_', '')
                );
              }
            }}
          />
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <Box w="100%">
            <Text
              pl="16px"
              w={'100%'}
              fontSize="1rem"
              fontWeight="400"
              lineHeight={['1.375rem', '1.375rem', '1.5rem']}
              display={WIDTH_SCREEN < 768 ? 'auto' : 'none'}
              visibility={WIDTH_SCREEN > 768 ? 'hidden' : 'visible'}
            >
              Data de nascimento*
            </Text>
            <RegistrationInput
              id="FormResponsavelLeagal-data-nascimento-input"
              type={'date'}
              mask={''}
              placeholder={'Data de nascimento*'}
              displayTextLabel={true}
              textErrorMessage={
                dateLegalGuardian === ''
                  ? 'Data Inválida'
                  : clientsLegalGuardianAge < WL_STARTER_LEGAL_AGE
                  ? `O Responsável deve ser maior que ${WL_STARTER_LEGAL_AGE} anos`
                  : ''
              }
              value={dateLegalGuardian}
              setValue={setDateLegalGuardian}
              handleAge={handleAge}
            />
          </Box>

          <RegistrationSelect
            id="FormResponsavelLeagal-sexo-select"
            placeholder={'Sexo de nascimento*'}
            displayTextLabel={true}
            textErrorMessage={
              genreLegalGuardian === '' ? 'Campo Obrigatório' : ''
            }
            value={genreLegalGuardian}
            setValue={setGenreLegalGuardian}
            options={[{ option: 'Masculino' }, { option: 'Feminino' }]}
          />
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <Stack w="100%">
            <RegistrationInput
              id="FormResponsavelLeagal-occupation-input"
              type={'text'}
              mask={''}
              setBlur={setShowOcuppationOptions}
              placeholder={'Ocupação atual*'}
              displayTextLabel={true}
              textErrorMessage={
                occupationLegalGuardian === ''
                  ? 'Campo Obrigatório'
                  : !isOccupationCodeSetted()
                  ? 'digite uma profissão válida e escolha a partir das opções listadas'
                  : ''
              }
              value={occupationLegalGuardian}
              setValue={setOccupationLegalGuardian}
              setFocus={() => {
                setShowOcuppationOptions(true);
              }}
            />
            {occupationLegalGuardian.length > 0 && showOcuppationOptions && (
              <Box
                maxH="96px"
                flexWrap="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                boxShadow="0px 6px 5px -3px rgba(0,0,0,0.5)"
              >
                {filteredList.map((item, index) => {
                  return (
                    <Text
                      key={index}
                      onClick={() => {
                        console.log('index no select', index);
                        setOccupationSelected(item, index);
                      }}
                      _hover={{ bg: '#dcdcdc' }}
                      flexWrap="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      cursor="pointer"
                    >
                      {item}
                    </Text>
                  );
                })}
              </Box>
            )}
          </Stack>
          <RegistrationInput
            id="FormResponsavelLeagal-income-input"
            type={''}
            mask={''}
            placeholder={'Renda*'}
            displayTextLabel={true}
            textErrorMessage={
              rendaLegalGuardian === ''
                ? 'Campo Obrigatório'
                : rendaLegalGuardian === 'R$ 0,00'
                ? 'Valor Inválido'
                : ''
            }
            value={rendaLegalGuardian?.replaceAll('NaN', '0,00')}
            setValue={setRendaLegalGuardian}
            isMoney={true}
          />
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <Box w="100%">
            <RegistrationDropdown
              id="FormResponsavelLeagal-kinship-dropdown"
              placeholder={'Grau de parentesco*'}
              displayTextLabel={true}
              textErrorMessage={kinshipDegree === '' ? 'Campo Obrigatório' : ''}
              value={kinshipDegree}
              setValue={setKinshipDegree}
              options={kinshipDgreesList.map((el) => ({
                option: el.descricao,
                value: el.codigo
              }))}
            />
          </Box>
          <Box w="100%">
            <RegistrationInput
              id="FormResponsavelLeagal-telefone-input"
              type={'text'}
              mask={'(99)99999-9999'}
              placeholder={'Telefone (celular)*'}
              displayTextLabel={true}
              textErrorMessage={
                phoneLegalGuardian.replaceAll('_', '').length <= 3
                  ? 'Campo Obrigatório'
                  : phoneLegalGuardian.replaceAll('_', '').length < 14
                  ? 'Número inválido'
                  : ''
              }
              value={phoneLegalGuardian}
              setValue={setPhoneLegalGuardian}
            />
          </Box>
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <RegistrationInput
            id="FormResponsavelLeagal-email-input"
            type={'text'}
            mask={''}
            placeholder={'E-mail*'}
            displayTextLabel={true}
            textErrorMessage={
              emailLegalGuardian.length === 0
                ? 'Campo obrigatório'
                : !isValidEmail(emailLegalGuardian)
                ? 'E-mail inválido'
                : ''
            }
            value={emailLegalGuardian}
            setValue={setEmailLegalGuardian}
          />
          <RegistrationInput
            id="FormResponsavelLeagal-email-confirmation-input"
            type={'text'}
            mask={''}
            placeholder={'Confirmação de e-mail*'}
            displayTextLabel={true}
            textErrorMessage={
              confirmedEmailLegalGuardian.length === 0
                ? 'Campo obrigatório'
                : confirmedEmailLegalGuardian != emailLegalGuardian
                ? 'Confirmação de e-mail inválida'
                : ''
            }
            value={confirmedEmailLegalGuardian}
            setValue={setConfirmedEmailLegalGuardian}
          />
        </HStack>
      </VStack>
    </>
  );
};
