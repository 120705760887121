export const ReadMore = ({
  isReadMore,
  text,
  toggleReadMore,
  websiteColor
}: {
  isReadMore: boolean;
  text: string;
  toggleReadMore: VoidFunction;
  websiteColor: string;
}) => {
  return (
    <>
      <p style={{ display: 'inline', width: '100%' }}>
        {isReadMore ? text.slice(0, 47) : text}
      </p>
      {text.length > 47 && (
        <span
          onClick={toggleReadMore}
          onKeyUp={() => {
            console.log('toggled');
          }}
          className="read-or-hide"
          style={{ color: websiteColor, cursor: 'pointer' }}
        >
          {isReadMore ? '... ver mais' : ' ver menos'}
        </span>
      )}
    </>
  );
};
