import { createIcon } from '@chakra-ui/react';

export const InternacaoHospitalarIcon = createIcon({
  displayName: 'InternacaoHospitalarIcon',
  viewBox: '0 0 40 40',
  path: [
    <path
      key="1"
      d="M18.3684 7.4154H19.4003V8.4473C19.4003 8.88725 19.7603 9.24718 20.2002 9.24718C20.6402 9.24718 21.0001 8.88725 21.0001 8.4473V7.4154H22.032C22.4719 7.4154 22.8319 7.05547 22.8319 6.61552C22.8319 6.17557 22.4719 5.81564 22.032 5.81564H21.0001V4.78374C21.0001 4.34379 20.6402 3.98386 20.2002 3.98386C19.7603 3.98386 19.4003 4.34379 19.4003 4.78374V5.81564H18.3684C17.9285 5.81564 17.5686 6.17557 17.5686 6.61552C17.5686 7.05547 17.9285 7.4154 18.3684 7.4154Z"
      fill="#146CFA"
    />,
    <path
      key="2"
      d="M13.9929 20.4946H18.0088C18.4487 20.4946 18.8087 20.1346 18.8087 19.6947L18.809 15.6789C18.809 15.2389 18.4491 14.879 18.0091 14.879H13.9933C13.5533 14.879 13.1934 15.2389 13.1934 15.6789V19.6947C13.1931 20.1346 13.545 20.4946 13.9929 20.4946ZM14.7931 16.4787H17.2092V18.8948H14.7931V16.4787Z"
      fill="#146CFA"
    />,
    <path
      key="3"
      d="M22.1851 20.4946H26.201C26.6409 20.4946 27.0008 20.1346 27.0008 19.6947L27.0012 15.6789C27.0012 15.2389 26.6413 14.879 26.2013 14.879H22.1855C21.7455 14.879 21.3856 15.2389 21.3856 15.6789V19.6947C21.3853 20.1346 21.7452 20.4946 22.1851 20.4946ZM22.985 16.4787H25.4011V18.8948H22.985V16.4787Z"
      fill="#146CFA"
    />,
    <path
      key="4"
      d="M18.0067 28.6695C18.4466 28.6695 18.8066 28.3095 18.8066 27.8696V23.8537C18.8066 23.4138 18.4466 23.0539 18.0067 23.0539H13.9908C13.5509 23.0539 13.191 23.4138 13.191 23.8537V27.8696C13.191 28.3095 13.5509 28.6695 13.9908 28.6695H18.0067ZM14.7907 24.6536H17.2068V27.0697H14.7907V24.6536Z"
      fill="#146CFA"
    />,
    <path
      key="5"
      d="M22.1855 28.6695H26.2013C26.6413 28.6695 27.0012 28.3095 27.0012 27.8696V23.8537C27.0012 23.4138 26.6413 23.0539 26.2013 23.0539H22.1855C21.7455 23.0539 21.3856 23.4138 21.3856 23.8537V27.8696C21.3856 28.3176 21.7455 28.6695 22.1855 28.6695ZM22.9853 24.6536H25.4014V27.0697H22.9853V24.6536Z"
      fill="#146CFA"
    />,
    <path
      key="6"
      d="M39.3916 15.7519H32.0558V6.6157C32.0558 6.17575 31.6958 5.81582 31.2559 5.81582H26.768C26.3676 2.54397 23.5759 0 20.1999 0C16.8239 0 14.0319 2.54397 13.6398 5.81582H8.93596C8.49601 5.81582 8.13608 6.17575 8.13608 6.6157V15.7519L0.799877 15.7522C0.359928 15.7522 0 16.1121 0 16.5521V39.2001C0 39.6401 0.359928 40 0.799877 40H39.3914C39.8314 40 40.1913 39.6401 40.1913 39.2001V16.5521C40.1913 16.1121 39.8316 15.7519 39.3916 15.7519ZM20.1995 1.59975C22.9675 1.59975 25.2155 3.84776 25.2155 6.6157C25.2155 9.38364 22.9675 11.6316 20.1995 11.6316C17.4316 11.6316 15.1836 9.38364 15.1836 6.6157C15.1833 3.84776 17.4316 1.59975 20.1995 1.59975ZM1.59975 17.3513H8.13574L8.1354 38.3991H1.59976L1.59975 17.3513ZM9.73549 16.5514V7.41523H13.6395C14.0396 10.6871 16.8236 13.2311 20.1995 13.2311C23.5755 13.2311 26.3676 10.6871 26.7596 7.41523H30.4476L30.4473 38.3989H24.1835V32.6069C24.1835 32.1669 23.8236 31.807 23.3836 31.807H16.7918C16.3518 31.807 15.9919 32.1669 15.9919 32.6069L15.9916 38.3989H9.72779L9.72746 16.5514H9.73549ZM17.5993 38.3989V33.4071H22.5911L22.5915 38.3989H17.5993ZM38.5914 38.3989H32.0554L32.0557 17.3511H38.5917L38.5914 38.3989Z"
      fill="#146CFA"
    />
  ]
});
