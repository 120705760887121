import { Box, HStack, Img, Link, Text, VStack } from '@chakra-ui/react';

import { FacebookIcon } from '../../assets/icons/redes/FacebookIcon';
import { InstagramIcon } from '../../assets/icons/redes/InstagramIcon';
import { LinkedinIcon } from '../../assets/icons/redes/LinkedinIcon';
import { TwitterIcon } from '../../assets/icons/redes/TwitterIcon';
import { YoutubeIcon } from '../../assets/icons/redes/YoutubeIcon';
import bbLogo from '../../assets/images/logo-banco-do-brasil.png';
import banparaLogo from '../../assets/images/logo-banpara.png';
import bradescoLogo from '../../assets/images/logo-bradesco.png';
import itauLogo from '../../assets/images/logo-itau.png';
import masterLogo from '../../assets/images/logo-mastercard.png';
import santanderLogo from '../../assets/images/logo-santander.png';
import sicoobLogo from '../../assets/images/logo-sicoob.png';
import visaLogo from '../../assets/images/logo-visa.png';

type WhiteLabelWebsiteFooterProps = {
  linkedin?: string | null;
  youtube?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  twitter?: string | null;
};

export const Footer = (props: WhiteLabelWebsiteFooterProps): JSX.Element => {
  const isItems =
    props.linkedin ||
    props.youtube ||
    props.facebook ||
    props.instagram ||
    props.twitter
      ? true
      : false;

  const handleSocialMediaLinkClick = (socialMedia: string) => {
    const url =
      socialMedia?.startsWith('http://') || socialMedia?.startsWith('https://')
        ? socialMedia
        : `https://${socialMedia}`;

    window.open(url, '_blank');
  };

  return (
    <Box
      display={['auto', 'auto', 'flex']}
      w="100%"
      maxW={isItems ? '890px' : '500px'}
    >
      {isItems ? (
        <HStack w="100%" gap="20px" display="flex" p="6px" alignItems="start">
          <Box
            textAlign={['center', 'center', 'start']}
            m="0 !important"
            w="100%"
          >
            <Text
              fontWeight="700"
              fontSize="1rem"
              lineHeight="1.5rem"
              color="#323232"
            >
              Redes sociais
            </Text>
            <Box
              m="20px 0 0 0 !important"
              display="flex"
              gap="24px"
              alignItems="center"
              justifyContent={['center', 'center', 'start', 'start']}
            >
              {props.facebook && (
                <Link
                  href="#"
                  onClick={() => {
                    handleSocialMediaLinkClick(
                      props.facebook ? props.facebook : ''
                    );
                  }}
                  target="_blank"
                  rel="noreferrer"
                  m="0 !important"
                >
                  <FacebookIcon
                    w={['40px', '40px', '45px']}
                    h={['40px', '40px', '45px']}
                  />
                  <Text
                    m="10px 0 0 0 !important"
                    fontWeight="400"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    color="#323232"
                  >
                    Facebook
                  </Text>
                </Link>
              )}
              {props.youtube && (
                <Link
                  href="#"
                  onClick={() => {
                    handleSocialMediaLinkClick(
                      props.youtube ? props.youtube : ''
                    );
                  }}
                  target="_blank"
                  rel="noreferrer"
                  m="0 !important"
                >
                  <YoutubeIcon
                    w={['40px', '40px', '45px']}
                    h={['40px', '40px', '45px']}
                  />
                  <Text
                    m="10px 0 0 0 !important"
                    fontWeight="400"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    color="#323232"
                  >
                    Youtube
                  </Text>
                </Link>
              )}
              {props.linkedin && (
                <Link
                  href="#"
                  onClick={() => {
                    handleSocialMediaLinkClick(
                      props.linkedin ? props.linkedin : ''
                    );
                  }}
                  target="_blank"
                  rel="noreferrer"
                  m="0 !important"
                >
                  <LinkedinIcon
                    w={['40px', '40px', '45px']}
                    h={['40px', '40px', '45px']}
                  />
                  <Text
                    m="10px 0 0 0 !important"
                    fontWeight="400"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    color="#323232"
                  >
                    Linkedin
                  </Text>
                </Link>
              )}
              {props.twitter && (
                <Link
                  href="#"
                  onClick={() => {
                    handleSocialMediaLinkClick(
                      props.twitter ? props.twitter : ''
                    );
                  }}
                  target="_blank"
                  rel="noreferrer"
                  m="0 !important"
                >
                  <TwitterIcon
                    w={['40px', '40px', '45px']}
                    h={['40px', '40px', '45px']}
                  />
                  <Text
                    m="10px 0 0 0 !important"
                    fontWeight="400"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    color="#323232"
                  >
                    Twitter
                  </Text>
                </Link>
              )}
              {props.instagram && (
                <Link
                  href="#"
                  onClick={() => {
                    handleSocialMediaLinkClick(
                      props.instagram ? props.instagram : ''
                    );
                  }}
                  target="_blank"
                  rel="noreferrer"
                  m="0 !important"
                >
                  <InstagramIcon
                    w={['40px', '40px', '45px']}
                    h={['40px', '40px', '45px']}
                  />
                  <Text
                    m="10px 0 0 0 !important"
                    fontWeight="400"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    color="#323232"
                  >
                    Instagram
                  </Text>
                </Link>
              )}
            </Box>
          </Box>
        </HStack>
      ) : null}
      <VStack
        w="100%"
        p="6px"
        textAlign={['center', 'center', 'start']}
        m={['40px 0 0 0 !important', '0 !important', '0 !important']}
      >
        <Text w="100%" fontWeight="bold">
          Formas de pagamento
        </Text>
        <Text w="100%" m="10px 0 0 0 !important">
          Pix, Cartão de Crédito e Débito em conta.
        </Text>
        <HStack
          w="100%"
          flexWrap="wrap"
          gap="10px"
          m="20px 0 0 0 !important"
          justifyContent={[
            'center !important',
            'center !important',
            'start !important'
          ]}
        >
          <Img
            src={visaLogo}
            w={{ base: '30px', md: '40px' }}
            m="0 !important"
          />
          <Img
            src={masterLogo}
            w={{ base: '30px', md: '40px' }}
            m="0 !important"
          />
          <Img
            src={itauLogo}
            w={{ base: '30px', md: '40px' }}
            m="0 !important"
          />
          <Img
            src={bradescoLogo}
            w={{ base: '30px', md: '40px' }}
            m="0 !important"
          />
          <Img
            src={santanderLogo}
            w={{ base: '30px', md: '40px' }}
            m="0 !important"
          />
          <Img src={bbLogo} w={{ base: '30px', md: '40px' }} m="0 !important" />
          <Img
            src={banparaLogo}
            w={{ base: '30px', md: '40px' }}
            m="0 !important"
          />
          <Img
            src={sicoobLogo}
            w={{ base: '30px', md: '40px' }}
            m="0 !important"
          />
        </HStack>
      </VStack>
      {/* </Box> */}
    </Box>
  );
};
