import { ProductTypes } from "../types";
import { getNumberFormat } from "./getNumberFormat";

export type PaymentFrequencyType = 'M' | 'T' | 'S' | 'A';

export const getInstallmentOver10 = (value: number) => {
  //Mensal
  let installment = value / 12;

  if (installment >= 10) {
    return { installment, frequency: 'M' }
  }

  //Trimestral
  installment = value / 4;

  if (installment >= 10) {
    return { installment, frequency: 'T' }
  }

  //Semestral
  installment = value / 2;

  if (installment >= 10) {
    return { installment, frequency: 'S' }
  }

  //Anual
  installment = value;

  return { installment, frequency: 'A' }
}

export const getMinimumAllowedInstallmentValue = (value: number, productType: ProductTypes) => {
  if (productType === 'vida_flex') {
    const installment = 10;

    return { installment, frequency: 'M' }
  }
  if (productType === 'acidentes_pessoais') {
    const installment = 5;
    return { installment, frequency: 'M' }
  }
  return { installment: value, frequency: 'A' }
}

export const getMinimumInstallmentValue = (value: number, productType: ProductTypes) => {
  const monthlyValue = (value / 12);
  if (productType === 'vida_flex') {
    const installmentValue = 10;

    return { installment: monthlyValue < installmentValue ? installmentValue : monthlyValue, frequency: 'M' }
  }
  if (productType === 'acidentes_pessoais') {
    const installmentValue = 5;
    return { installment: monthlyValue < installmentValue ? installmentValue : monthlyValue, frequency: 'M' }
  }
  return { installment: value, frequency: 'A' }
}

export const getMonthlyValue = (value: number) => {
  return { installment: value / 12, frequency: 'M' }
}


export const getMonthNameByChar = (char: PaymentFrequencyType) => {
  if (char === 'M') {
    return 'Mês';
  }

  if (char === 'T') {
    return 'Trimestre';
  }

  if (char === 'S') {
    return 'Semestre';
  }

  if (char === 'A') {
    return 'Ano';
  }
}

export const getInstallmentsNumberByChar = (char: PaymentFrequencyType) => {
  if (char === 'M') {
    return 12;
  }

  if (char === 'T') {
    return 4;
  }

  if (char === 'S') {
    return 2;
  }

  return 1
}

export const creditCardType = (cc: string) => {
  const amex = new RegExp('^3[47][0-9]{13}$');
  const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
  const cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
  const cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

  const mastercard = new RegExp('^5[1-5][0-9]{14}$');
  const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

  const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
  const jcb = new RegExp('^35[0-9]{14}[0-9]*$');

  if (visa.test(cc)) {
    return 'VI';
  }
  if (amex.test(cc)) {
    return 'AM';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return 'MC';
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return 'DI';
  }
  if (diners.test(cc)) {
    return 'DN';
  }
  if (jcb.test(cc)) {
    return 'JC';
  }
  if (cup1.test(cc) || cup2.test(cc)) {
    return 'CU';
  }
  return 'MC';
}

export const getInstallmentOptions = (price: number, productType: ProductTypes) => {
  const installmentOptions = [
    {
      option: `Anual (1x ${getNumberFormat(price.toFixed(2))})`,
      rawInstallmentValue: price,
      value: `A`
    },
    {
      option: `Semestral (2x ${getNumberFormat((price / 2).toFixed(2))})`,
      rawInstallmentValue: price / 2,
      value: `S`
    },
    {
      option: `Trimestral (4x ${getNumberFormat((price / 4).toFixed(2))})`,
      rawInstallmentValue: price / 4,
      value: `T`
    }
  ];

  if (
    productType === 'vida_flex' ||
    productType === 'vida_individual' ||
    productType === 'vida_mulher' ||
    productType === 'acidentes_pessoais'
  ) {
    installmentOptions.push({
      option: `Mensal (12x ${getNumberFormat((price / 12).toFixed(2))})`,
      rawInstallmentValue: price / 12,
      value: `M`
    });
  }

  return installmentOptions;
}