import { Box, Checkbox, Text } from '@chakra-ui/react';

import { CondicoesSaudeIcon } from '../../assets/icons/diversos/CondicoesSaudeIcon';

type CardCondicoesSaudeProps = {
  checkboxBeneficiarios: React.MutableRefObject<HTMLDivElement>;
  handleChecked: () => void;
};

export const CardCondicoesSaude = ({
  checkboxBeneficiarios,
  handleChecked
}: CardCondicoesSaudeProps) => {
  return (
    <Box
      ref={checkboxBeneficiarios}
      w="100%"
      p="40px"
      maxW="784px"
      bg="#FFFFFF"
      display="flex"
      flexDirection="column"
      gridGap="32px"
      alignItems="center"
      boxShadow="0px 4px 10px rgba(50, 50, 50, 0.16);"
    >
      <CondicoesSaudeIcon w="55px" h="55px" />
      <Text
        color="#323232"
        fontSize="1rem"
        lineHeight="1.5rem"
        fontWeight="400"
        textAlign="center"
      >
        As perguntas a seguir são sobre sua condição de saúde atual e seu
        preenchimento correto é essencial para a contratação. Lembrando que
        essas respostas precisam ser informadas pelo próprio cliente e não por
        um terceiro ou consultor de vendas.
      </Text>
      <Checkbox
        className="checkbox-beneficiarios"
        size="md"
        colorScheme="orange"
        defaultChecked
        onChange={handleChecked}
      >
        <Text
          color="#1F1E1F"
          fontSize="1rem"
          lineHeight="1.5rem"
          fontWeight="700"
          ml="6px"
        >
          Concordo que sou o próprio cliente e desejo prosseguir
        </Text>
      </Checkbox>
    </Box>
  );
};
