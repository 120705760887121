import { Alert, AlertIcon, Flex, Image, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

import Loader from '../../assets/svg/loader.svg';

type PageLoadingProps = {
  hasError?: boolean;
  errorText?: string;
};

export const PageLoading = ({ hasError, errorText }: PageLoadingProps) => {
  return (
    <AnimatePresence>
      <Flex
        flexDir="column"
        w="100%"
        h="100vh"
        justifyContent="center"
        alignItems="center"
        bg="white"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.1 }}
        >
          <Flex flexDir="column" alignItems="center">
            <img src={Loader} style={{ transform: 'scale(0.5)' }} />
            {hasError ? (
              <Alert status="error">
                <AlertIcon />
                {errorText ||
                  'Desculpe, não foi possível carregar o site. Tente novamente mais tarde'}
              </Alert>
            ) : (
              <Text>Por favor, aguarde...</Text>
            )}
          </Flex>
        </motion.div>
      </Flex>
    </AnimatePresence>
  );
};
