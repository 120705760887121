import { createIcon } from '@chakra-ui/react';

export const DescontoFarmaciaIcon = createIcon({
  displayName: 'DescontoFarmaciaIcon',
  viewBox: '0 0 96 95',
  path: [
    <path
      key="1"
      d="M48.492 18C46.7838 18 45.0557 18.6739 43.7535 19.9758L38.893 24.8354H32.0144C28.332 24.8354 25.3097 27.8571 25.3097 31.5388V38.4021L20.4532 43.2737C17.8489 45.8754 17.8489 50.1511 20.4532 52.7528L25.3097 57.6084V64.4897C25.3097 68.1714 28.332 71.1931 32.0144 71.1931H38.893L43.7535 76.0487C46.3578 78.6504 50.6422 78.6504 53.2465 76.0487L58.107 71.1931H64.9716C68.654 71.1931 71.6903 68.1714 71.6903 64.4897V57.6084L76.5468 52.7528C79.1511 50.1511 79.1511 45.8754 76.5468 43.2737L71.6903 38.4021V31.5368C71.6903 27.8551 68.652 24.8334 64.9716 24.8334H58.107L53.2465 19.9738C51.9443 18.6719 50.2042 18 48.492 18ZM41.9373 32.8806C45.2297 32.8806 47.9299 35.5984 47.9299 38.8881C47.9299 42.1778 45.2277 44.8795 41.9373 44.8795C38.647 44.8795 35.9447 42.1778 35.9447 38.8881C35.9447 35.5984 38.647 32.8806 41.9373 32.8806ZM59.9151 35.4304C61.0193 35.4024 61.9393 36.2743 61.9653 37.3782C61.9794 37.9402 61.7573 38.4821 61.3513 38.8721L40.1352 60.0841C39.3691 60.8841 38.101 60.9121 37.3009 60.1481C36.5008 59.3822 36.4728 58.1143 37.2389 57.3144C37.2589 57.2924 37.2789 57.2724 37.2989 57.2524L58.515 36.0363C58.879 35.6644 59.3711 35.4504 59.8911 35.4344C59.8991 35.4344 59.9071 35.4324 59.9151 35.4304ZM41.9373 36.8803C40.8092 36.8803 39.9291 37.7602 39.9291 38.8881C39.9291 40.016 40.8072 40.8799 41.9373 40.8799C43.0675 40.8799 43.9295 40.016 43.9295 38.8881C43.9295 37.7602 43.0655 36.8803 41.9373 36.8803ZM58.771 49.7071C62.0634 49.7071 64.7636 52.4128 64.7636 55.7025C64.7636 58.9922 62.0614 61.71 58.771 61.71C55.4807 61.71 52.7584 58.9922 52.7584 55.7025C52.7584 52.4128 55.4807 49.7071 58.771 49.7071ZM58.771 53.7107C57.6429 53.7107 56.7628 54.5746 56.7628 55.7025C56.7628 56.8304 57.6409 57.7104 58.771 57.7104C59.9011 57.7104 60.7632 56.8304 60.7632 55.7025C60.7632 54.5746 59.8991 53.7107 58.771 53.7107Z"
      fill="white"
    />
  ]
});
