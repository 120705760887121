import { createIcon } from '@chakra-ui/react';

export const DespesaHospitalarIcon = createIcon({
  displayName: 'DespesaHospitalarIcon',
  viewBox: '0 0 55 50',
  path: [
    <path
      key="1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7147 32.143C10.7147 30.1701 9.11596 28.5714 7.14311 28.5714H3.57155C1.5987 28.5714 0 30.1701 0 32.143V46.4292C0 48.4021 1.5987 50.0008 3.57155 50.0008H7.14311C9.11596 50.0008 10.7147 48.4021 10.7147 46.4292V32.143ZM8.33362 32.143C8.33362 31.4861 7.80001 30.9525 7.14311 30.9525H3.57155C2.91465 30.9525 2.38104 31.4861 2.38104 32.143V46.4292C2.38104 47.0861 2.91465 47.6197 3.57155 47.6197H7.14311C7.80001 47.6197 8.33362 47.0861 8.33362 46.4292V32.143Z"
      fill="#146CFA"
    />,
    <path
      key="2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.32937 46.4122C9.32937 46.4122 24.6837 48.9717 29.3981 49.7573C30.5089 49.9418 31.6495 49.8084 32.6864 49.3715C36.2495 47.8717 46.3971 43.5996 51.3074 41.5322C53.4025 40.6499 54.7647 38.5974 54.7647 36.3248V36.3237C54.7647 35.2156 54.3241 34.151 53.5396 33.3676C52.7562 32.5831 51.6922 32.1425 50.5835 32.1425H34.5258C33.8689 32.1425 33.3353 32.6761 33.3353 33.333C33.3353 33.9899 33.8689 34.5235 34.5258 34.5235H50.5835C51.0608 34.5235 51.5194 34.7127 51.8564 35.0508C52.1944 35.3877 52.3836 35.8459 52.3836 36.3237V36.3247C52.3836 37.6401 51.5954 38.827 50.3837 39.3378C45.4728 41.4046 35.326 45.6773 31.7626 47.1771C31.1397 47.4391 30.4568 47.5188 29.7898 47.4083C25.0756 46.6227 9.71967 44.0642 9.71967 44.0642C9.0718 43.9558 8.45794 44.3937 8.35065 45.0427C8.24222 45.6905 8.68044 46.3049 9.32937 46.4122Z"
      fill="#146CFA"
    />,
    <path
      key="3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.334 34.5223C33.334 33.2069 32.2684 32.1413 30.953 32.1413H16.3933C15.94 32.1413 15.4898 32.2285 15.0673 32.3975C13.4218 33.0544 9.96613 34.4367 9.96613 34.4367C9.35652 34.6807 8.66241 34.3841 8.41955 33.7734C8.1756 33.1638 8.47217 32.4697 9.08284 32.2269C9.08284 32.2269 12.5375 30.8434 14.1829 30.1865C14.8866 29.9043 15.6365 29.7603 16.3938 29.7603H30.9535C33.5833 29.7603 35.7156 31.8926 35.7156 34.5223C35.7156 37.1521 33.5833 39.2844 30.9535 39.2844H25.0009C24.344 39.2844 23.8104 38.7508 23.8104 38.0939C23.8104 37.437 24.344 36.9034 25.0009 36.9034H30.9535C32.269 36.9034 33.334 35.8378 33.334 34.5223Z"
      fill="#146CFA"
    />,
    <path
      key="4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6199 7.14311V1.19052C22.6199 0.53361 23.1536 0 23.8105 0H30.9536C31.6105 0 32.1441 0.53361 32.1441 1.19052V7.14311H38.0967C38.7536 7.14311 39.2872 7.67672 39.2872 8.33362V15.4767C39.2872 16.1336 38.7536 16.6672 38.0967 16.6672H32.1441V22.6198C32.1441 23.2767 31.6105 23.8104 30.9536 23.8104H23.8105C23.1536 23.8104 22.6199 23.2767 22.6199 22.6198V16.6672H16.6674C16.0104 16.6672 15.4768 16.1336 15.4768 15.4767V8.33362C15.4768 7.67672 16.0104 7.14311 16.6674 7.14311H22.6199ZM29.7631 2.38104H25.001V8.33362C25.001 8.99053 24.4674 9.52414 23.8105 9.52414H17.8579V14.2862H23.8105C24.4674 14.2862 25.001 14.8198 25.001 15.4767V21.4293H29.7631V15.4767C29.7631 14.8198 30.2967 14.2862 30.9536 14.2862H36.9062V9.52414H30.9536C30.2967 9.52414 29.7631 8.99053 29.7631 8.33362V2.38104Z"
      fill="#146CFA"
    />
  ]
});
