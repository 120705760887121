import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { PlusIcon } from '../../assets/icons/ferramentas/PlusIcon';
import { ButtonWhatsApp } from '../buttons/ButtonWhatsApp';

type WhiteLabelWebsiteHeaderProps = {
  children?: ReactNode;
  stage?: string;
  logo?: string;
  whatsapp?: string | null;
  logo_escrita?: string;
  logo_sulamerica?: boolean;
};

export const Header = ({
  children,
  stage,
  logo,
  whatsapp,
  logo_escrita,
  logo_sulamerica
}: WhiteLabelWebsiteHeaderProps): JSX.Element => {
  return (
    <>
      {stage === 'final' ? (
        <Box
          aria-label="header"
          w="100%"
          p={{ base: '0px', md: '36px 74px' }}
          bg="white"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1) !important"
        ></Box>
      ) : (
        <Box
          aria-label="header"
          width="100%"
          p={{ base: '0px', md: '36px 74px' }}
          bg="white"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1) !important"
        >
          <Stack
            width="100%"
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            bg="white"
            // pb={
            //   !children ? { base: '15px', md: '0' } : { base: '20px', md: '0' }
            // }
            gap={!children ? '' : '36px'}
          >
            <HStack
              width="100%"
              margin="0px !important"
              gap="20px"
              justifyContent={
                !children ? { base: 'center', md: 'start' } : 'start'
              }
              borderBottom={!children ? '0px -2px 5px #D2D2D2 !important' : ''}
              p={
                !children
                  ? { base: '36px 16px 36px 16px', md: '0' }
                  : { base: '36px 36px 0 36px', md: '0' }
              }
            >
              {!children ? (
                <>
                  <Box>
                    <Text fontSize="2.5rem" fontWeight="bold">
                      {logo_escrita ? (
                        <p>{logo_escrita}</p>
                      ) : (
                        <Image
                          h="40px"
                          src={logo ?? '/images/logo-sulAmerica.png'}
                        />
                      )}
                    </Text>
                  </Box>
                  {logo_sulamerica && (
                    <>
                      <Box margin="0 !important">
                        <PlusIcon w="1.5rem" h="1.5rem" />
                      </Box>
                      <Box margin="0 !important" w="152px">
                        <Image w="100%" src="/images/logo-sulAmerica.png" />
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <Box margin="0 !important" w="152px">
                  <Image w="100%" src="/images/logo-sulAmerica.png" />
                </Box>
              )}
            </HStack>
            {
              children ? children : null
              // <Box
              //   w="100%"
              //   m={['14px 0 0 0 !important', '0 !important']}
              //   position="relative"
              //   display="flex"
              //   justifyContent={['center', 'end']}
              // >
              //   <ButtonWhatsApp numberWhatsApp={`+55${whatsapp}`} />
              // </Box>
            }
          </Stack>
        </Box>
      )}
    </>
  );
};
