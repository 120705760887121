import { Box, Checkbox, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { DateObjectUnits } from 'luxon';
import { useEffect, useState } from 'react';

import { wlLocalStorage } from '../../utils/localStorage.util';
import { occupationList } from '../../utils/occupations';
import { getProductType } from '../../utils/products.utils';
import { isValidEmail, removeDiacritic } from '../../utils/string-utils';
import { getDifferenceBetweenDatesInYears } from '../../utils/time-utils';
import {
  WL_MINIMUM_CUSTOMER_AGE,
  WL_STARTER_LEGAL_AGE
} from '../../utils/wl-consts.util';
import { RegistrationInput } from '../inputs/RegistrationInput';
import { RegistrationSelect } from '../inputs/RegistrationSelect';

type FormDadosCadastraisProps = {
  name: string;
  setName: (newState: string) => void;
  socialName: string;
  setSocialName: (newState: string) => void;
  hasSocialName: boolean;
  setHasSocialName: (hasSocialName: boolean) => void;
  date: string;
  setDate: (newState: string) => void;
  genre: string;
  setGenre: (newState: string) => void;
  occupation: string;
  setOccupation: (newState: string) => void;
  renda: string;
  setRenda: (newState: string) => void;
  phone: string;
  setPhone: (newState: string) => void;
  email: string;
  setEmail: (newState: string) => void;
  confirmedEmail: string;
  setConfirmedEmail: (newState: string) => void;
  peso: string;
  setPeso: (newState: string) => void;
  altura: string;
  setAltura: (newState: string) => void;
  setOccupationCode: (newState: string) => void;
  clientsAge: number;
  setClientsAge: (newState: number) => void;
  idProduct?: string;
};

const WIDTH_SCREEN = window.screen.width;

export const FormDadosCadastrais = ({
  name,
  setName,
  socialName,
  setSocialName,
  hasSocialName,
  setHasSocialName,
  date,
  setDate,
  genre,
  setGenre,
  occupation,
  setOccupation,
  renda,
  setRenda,
  phone,
  setPhone,
  email,
  setEmail,
  confirmedEmail,
  setConfirmedEmail,
  peso,
  setPeso,
  altura,
  setAltura,
  setOccupationCode,
  clientsAge,
  setClientsAge,
  idProduct
}: FormDadosCadastraisProps) => {
  const options = occupationList.map((option) => {
    return option.descricao;
  });

  const optionsPair = occupationList.map((option) => {
    return option;
  });

  const getCodigoByOptionName = (
    options: Array<{ codigo: string; descricao: string }>,
    name: string
  ) => {
    for (let i = 0; i < options.length; i++) {
      const item = options[i];
      if (item.descricao.toLowerCase() === name.toLowerCase()) {
        return item.codigo;
      }
    }
  };

  const [cpf, setCpf] = useState('');
  const [showOcuppationOptions, setShowOcuppationOptions] = useState(false);

  // const [listOccupations, setListOccupations] = useState('');
  const [filteredList, setFilteredList] = useState(['']);

  const handleCpf = () => {
    const query = location.pathname;
    const parts = query.split('&');
    setCpf(parts[1].replaceAll('cpf=', ''));
  };

  const handleFilterBySearch = () => {
    const updateList = [...options];
    const newList = updateList.filter((item) => {
      return (
        removeDiacritic(item.toLocaleLowerCase()).indexOf(
          removeDiacritic(occupation.toLocaleLowerCase())
        ) !== -1
      );
    });

    setFilteredList(newList);
  };

  const setOccupationSelected = (item: string, index: number) => {
    setOccupation(item);
    console.log('item profissao', item, 'numero', index);
    setOccupationCodigo(item);
    setShowOcuppationOptions(false);
  };

  const isOccupationCodeSetted = (): boolean => {
    const occupationCodigo = getCodigoByOptionName(optionsPair, occupation);

    console.log('codigo profissao selecionada', occupationCodigo);

    if (!occupationCodigo) {
      setOccupationCode('');
      return false;
    }

    setOccupationCode(occupationCodigo);
    wlLocalStorage.setItem('occupationSelected', occupationCodigo);

    return true;
  };

  const setOccupationCodigo = (descricao: string) => {
    const occupationCodigo =
      getCodigoByOptionName(optionsPair, descricao) || '';
    console.log('código buscado', occupationCodigo);
    setOccupationCode(occupationCodigo);
    wlLocalStorage.setItem('occupationSelected', occupationCodigo);
    console.log(
      'código ocupação',
      occupationCodigo,
      wlLocalStorage.getItem('occupationSelected')
    );
  };

  const handleAge = (e: any) => {
    const today = new Date();
    const birthDateArray = e.target.value.split('-');
    const birthDate: DateObjectUnits = {
      day: birthDateArray[2],
      month: birthDateArray[1],
      year: birthDateArray[0]
    };

    console.log('obj data', birthDate);
    const age = getDifferenceBetweenDatesInYears(birthDate, today);

    console.log('idade calculada', age);

    wlLocalStorage.setItem('clientEnteredAge', String(age));
    setClientsAge(age);
  };

  useEffect(() => {
    handleCpf();
    console.log(WIDTH_SCREEN);
  }, []);

  useEffect(() => {
    handleFilterBySearch();
  }, [occupation]);

  const nameHasError =
    name.replace(/[/.,~!@#$%¨*()=+:;?&_0-9-[\]<>'"|]/g, '') === '';
  const socialNameHasError =
    socialName.replace(/[/.,~!@#$%¨*()=+:;?&_0-9-[\]<>'"|]/g, '') === '';

  const cpfRegistrationInput = (
    <RegistrationInput
      type={'text'}
      mask={'999.999.999.99'}
      placeholder={'CPF*'}
      displayTextLabel={true}
      disable={true}
      textErrorMessage={cpf === '' ? 'Campo Obrigatório' : ''}
      value={cpf}
      setValue={() => {
        /* */
      }}
    />
  );

  return (
    <>
      <VStack
        data-testid="FormDadosCadastrais"
        maxW="850px"
        w="100%"
        m="0 !important"
        bg="#FFFFFF"
        p={['30px 15px', '30px 15px', '30px 30px 60px']}
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25);"
        display="flex"
        gridGap={['30px', '50px']}
      >
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap={['60px', '60px', '30px']}
          flexDirection={['column', 'column', 'row']}
        >
          <VStack w="100%" position="relative" alignItems="start">
            <RegistrationInput
              id="FormDadosCadastrais-nome-input"
              type={'text'}
              mask={''}
              placeholder={'Nome*'}
              displayTextLabel={true}
              textErrorMessage={nameHasError ? 'Campo Obrigatório' : ''}
              value={name.replace(/[/.,~!@#$%¨*()=+:;?&_0-9-[\]<>'"|]/g, '')}
              setValue={setName}
            />
            <Box
              as="span"
              position="absolute"
              top={nameHasError ? '60px' : '45px'}
              fontSize="12px"
            >
              <HStack>
                <Text>Possui nome social? Se sim, clique aqui:</Text>
                <Checkbox
                  id="FormDadosCadastrais-nome-social-checkbox"
                  border="#FF5000"
                  colorScheme="orange"
                  isChecked={hasSocialName}
                  transform="scale(0.8)"
                  onChange={(e) => {
                    setHasSocialName(e.target.checked);
                  }}
                />
              </HStack>
            </Box>
          </VStack>

          {hasSocialName ? (
            <RegistrationInput
              id="FormDadosCadastrais-nome-input"
              type={'text'}
              mask={''}
              placeholder={'Nome Social*'}
              displayTextLabel={true}
              textErrorMessage={socialNameHasError ? 'Campo Obrigatório' : ''}
              value={socialName.replace(
                /[/.,~!@#$%¨*()=+:;?&_0-9-[\]<>'"|]/g,
                ''
              )}
              setValue={setSocialName}
            />
          ) : (
            cpfRegistrationInput
          )}
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <Box w="100%">
            <Text
              pl="16px"
              w={'100%'}
              fontSize="1rem"
              fontWeight="400"
              lineHeight={['1.375rem', '1.375rem', '1.5rem']}
              display={WIDTH_SCREEN < 768 ? 'auto' : 'none'}
              visibility={WIDTH_SCREEN > 768 ? 'hidden' : 'visible'}
            >
              Data de nascimento*
            </Text>
            <RegistrationInput
              id="FormDadosCadastrais-data-nascimento-input"
              type={'date'}
              mask={''}
              placeholder={'Data de nascimento*'}
              displayTextLabel={true}
              textErrorMessage={
                date === ''
                  ? 'Data Inválida'
                  : clientsAge < WL_MINIMUM_CUSTOMER_AGE
                  ? `Data de nascimento deve corresponder a maiores de ${WL_MINIMUM_CUSTOMER_AGE} anos`
                  : clientsAge < WL_STARTER_LEGAL_AGE
                  ? 'Preencha os dados do responsável abaixo.'
                  : ''
              }
              textErrorMessageColor={
                clientsAge >= WL_MINIMUM_CUSTOMER_AGE &&
                clientsAge < WL_STARTER_LEGAL_AGE
                  ? '#FF5000'
                  : undefined
              }
              value={date}
              setValue={setDate}
              handleAge={handleAge}
            />
          </Box>

          <RegistrationSelect
            id="FormDadosCadastrais-sexo-select"
            placeholder={'Sexo de nascimento*'}
            displayTextLabel={true}
            textErrorMessage={genre === '' ? 'Campo Obrigatório' : ''}
            value={genre}
            setValue={setGenre}
            options={[{ option: 'Masculino' }, { option: 'Feminino' }]}
          />
        </HStack>
        {getProductType(Number(idProduct)) !== 'acidentes_pessoais' ? (
          <HStack
            m="0 !important"
            w="100%"
            display="flex"
            gap="30px"
            flexDirection={['column', 'column', 'row']}
          >
            <RegistrationInput
              id="FormDadosCadastrais-peso-input"
              type={'number'}
              mask={''}
              placeholder={'Peso*'}
              displayTextLabel={true}
              textErrorMessage={
                peso === ''
                  ? 'Campo Obrigatório'
                  : String(peso).length > 3 || String(peso).length < 2
                  ? 'Valor inválido'
                  : ''
              }
              value={peso}
              setValue={setPeso}
            />
            <RegistrationInput
              id="FormDadosCadastrais-altura-input"
              type={'text'}
              mask={'9,99'}
              placeholder={'Altura*'}
              displayTextLabel={true}
              textErrorMessage={
                altura === ''
                  ? 'Campo Obrigatório'
                  : String(altura).length > 4 || String(altura).length < 3
                  ? 'Valor inválido'
                  : ''
              }
              value={altura}
              setValue={setAltura}
            />
          </HStack>
        ) : (
          <></>
        )}

        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <Stack w="100%">
            <RegistrationInput
              id="FormDadosCadastrais-ocupacao-input"
              type={'text'}
              mask={''}
              setBlur={setShowOcuppationOptions}
              placeholder={'Sua ocupação atual*'}
              displayTextLabel={true}
              textErrorMessage={
                occupation === ''
                  ? 'Campo Obrigatório'
                  : !isOccupationCodeSetted()
                  ? 'digite uma profissão válida e escolha a partir das opções listadas'
                  : ''
              }
              value={occupation}
              setValue={setOccupation}
              setFocus={() => {
                setShowOcuppationOptions(true);
              }}
            />
            {occupation.length > 0 && showOcuppationOptions && (
              <Box
                maxH="96px"
                flexWrap="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                boxShadow="0px 6px 5px -3px rgba(0,0,0,0.5)"
                zIndex="10"
                background="white"
                margin="0 !important"
              >
                {filteredList.map((item, index) => {
                  return (
                    <Text
                      key={index}
                      onClick={() => {
                        console.log('index no select', index);
                        setOccupationSelected(item, index);
                      }}
                      _hover={{ bg: '#dcdcdc' }}
                      flexWrap="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      cursor="pointer"
                    >
                      {item}
                    </Text>
                  );
                })}
              </Box>
            )}
          </Stack>
          <RegistrationInput
            id="FormDadosCadastrais-renda-input"
            type={''}
            mask={''}
            placeholder={'Renda*'}
            displayTextLabel={true}
            textErrorMessage={
              renda === ''
                ? 'Campo Obrigatório'
                : renda === 'R$ 0,00'
                ? 'Valor Inválido'
                : ''
            }
            value={renda?.replaceAll('NaN', '0,00')}
            setValue={setRenda}
            isMoney={true}
          />
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap="30px"
          flexDirection={['column', 'column', 'row']}
        >
          <RegistrationInput
            id="FormDadosCadastrais-email-input"
            type={'text'}
            mask={''}
            placeholder={'E-mail*'}
            displayTextLabel={true}
            textErrorMessage={
              email.length === 0
                ? 'Campo obrigatório'
                : !isValidEmail(email)
                ? 'E-mail inválido'
                : ''
            }
            value={email}
            setValue={setEmail}
          />
          <RegistrationInput
            id="FormDadosCadastrais-email-confirmation-input"
            type={'text'}
            mask={''}
            placeholder={'Confirmação de e-mail*'}
            displayTextLabel={true}
            textErrorMessage={
              confirmedEmail.length === 0
                ? 'Campo obrigatório'
                : confirmedEmail != email
                ? 'Confirmação de e-mail inválida'
                : ''
            }
            value={confirmedEmail}
            setValue={setConfirmedEmail}
          />
        </HStack>
        <HStack
          m="0 !important"
          w="100%"
          display="flex"
          gap={['0px', '30px']}
          pb={{ base: '30px', md: '0' }}
          flexDirection={['column', 'column', 'row']}
        >
          <RegistrationInput
            id="FormDadosCadastrais-telefone-input"
            type={'text'}
            mask={'(99)99999-9999'}
            placeholder={'Telefone (celular)*'}
            displayTextLabel={true}
            textErrorMessage={
              phone.replaceAll('_', '').length <= 3
                ? 'Campo Obrigatório'
                : phone.replaceAll('_', '').length < 14
                ? 'Número inválido'
                : ''
            }
            value={phone}
            setValue={setPhone}
          />

          {hasSocialName ? (
            cpfRegistrationInput
          ) : (
            <Box w="100%" m="0 !important" />
          )}
        </HStack>
      </VStack>
    </>
  );
};
