import { Button } from '@chakra-ui/react';

type ButtonSubmitProps = {
  reference: string;
  hrefNext: string;
  textContent: string;
  handleSubmit: () => void;
};

const buttonColor = '#FF5000';

export const ButtonSubmit = ({
  reference,
  hrefNext,
  textContent,
  handleSubmit
}: ButtonSubmitProps) => {
  const handleDataUsers = () => {
    handleSubmit();
    window.location.href = `${hrefNext}`;
  };
  return (
    <>
      <Button
        bg={buttonColor || ''}
        w={['100%']}
        m="0 !important"
        h="48px"
        p="12px 48px"
        gap="10px"
        borderRadius="0 !important"
        color="#FFFFFF"
        fontWeight="700"
        fontSize="1rem"
        lineHeight="1.375rem"
        textAlign="center"
        transition="0.4s"
        onClick={handleDataUsers}
        disabled={
          reference.replaceAll('_', '').replaceAll('.', '').length < 11
            ? true
            : false
        }
        _disabled={{
          bg: '#D2D2D2'
        }}
        _hover={
          reference.replaceAll('_', '').replaceAll('.', '').length < 11
            ? {}
            : {
                WebkitFilter: ' brightness(60%)',
                transition: 'all 0.4s',
                WebkitTransition: 'all 0.4s'
              }
        }
        _active={{}}
        cursor={
          reference.replaceAll('_', '').replaceAll('.', '').length < 11
            ? 'not-allowed'
            : ''
        }
        _focus={{
          '&:focus': {
            boxShadow: 'none !important'
          }
        }}
      >
        {textContent}
      </Button>
    </>
  );
};
