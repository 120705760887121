import { createIcon } from '@chakra-ui/react';

export const WhatsAppIcon = createIcon({
  displayName: 'WhatsAppIcon',
  viewBox: '0 0 17 16',
  path: [
    <path
      key="1"
      d="M11.6483 9.37344C11.478 9.28594 10.6296 8.87031 10.4718 8.81406C10.314 8.75469 10.1983 8.72656 10.0843 8.90156C9.96865 9.075 9.64053 9.46094 9.5374 9.57812C9.4374 9.69375 9.33584 9.70781 9.16553 9.62187C8.15303 9.11562 7.48896 8.71875 6.82178 7.57344C6.64521 7.26875 6.99834 7.29063 7.32803 6.63281C7.38428 6.51719 7.35615 6.41875 7.3124 6.33125C7.26865 6.24375 6.9249 5.39687 6.78115 5.05156C6.64209 4.71562 6.49834 4.7625 6.39365 4.75625C6.29365 4.75 6.17959 4.75 6.06396 4.75C5.94834 4.75 5.7624 4.79375 5.60459 4.96406C5.44678 5.1375 5.00146 5.55469 5.00146 6.40156C5.00146 7.24844 5.61865 8.06875 5.70303 8.18437C5.79053 8.3 6.91709 10.0375 8.64678 10.7859C9.74053 11.2578 10.1687 11.2984 10.7155 11.2172C11.0483 11.1672 11.7343 10.8016 11.8765 10.3969C12.0187 9.99375 12.0187 9.64844 11.9765 9.57656C11.9343 9.5 11.8187 9.45625 11.6483 9.37344Z"
      fill="white"
    />,
    <path
      key="2"
      d="M14.9562 5.2875C14.6031 4.44844 14.0968 3.69531 13.4515 3.04844C12.8062 2.40313 12.0531 1.89531 11.2124 1.54375C10.3531 1.18281 9.44056 1 8.49994 1H8.46869C7.52181 1.00469 6.60462 1.19219 5.74212 1.56094C4.90931 1.91719 4.16244 2.42344 3.52337 3.06875C2.88431 3.71406 2.38275 4.46406 2.03587 5.3C1.6765 6.16563 1.49525 7.08594 1.49994 8.03281C1.50462 9.11719 1.764 10.1938 2.24994 11.1562V13.5312C2.24994 13.9281 2.57181 14.25 2.96869 14.25H5.34525C6.30775 14.7359 7.38431 14.9953 8.46869 15H8.5015C9.43744 15 10.3452 14.8188 11.1999 14.4641C12.0359 14.1156 12.7874 13.6156 13.4312 12.9766C14.0765 12.3375 14.5843 11.5906 14.939 10.7578C15.3077 9.89531 15.4952 8.97813 15.4999 8.03125C15.5046 7.07969 15.3202 6.15625 14.9562 5.2875ZM12.5952 12.1313C11.4999 13.2156 10.0468 13.8125 8.49994 13.8125H8.47337C7.53119 13.8078 6.59525 13.5734 5.76869 13.1328L5.63744 13.0625H3.43744V10.8625L3.36712 10.7312C2.9265 9.90469 2.69212 8.96875 2.68744 8.02656C2.68119 6.46875 3.2765 5.00625 4.36869 3.90469C5.45931 2.80312 6.91712 2.19375 8.47494 2.1875H8.5015C9.28275 2.1875 10.0406 2.33906 10.7546 2.63906C11.4515 2.93125 12.0765 3.35156 12.614 3.88906C13.1499 4.425 13.5718 5.05156 13.864 5.74844C14.1671 6.47031 14.3187 7.23594 14.3156 8.02656C14.3062 9.58281 13.6952 11.0406 12.5952 12.1313Z"
      fill="white"
    />
  ]
});
