import { createIcon } from '@chakra-ui/react';

export const AssistenciaFilhosIcon = createIcon({
  displayName: 'AssistenciaFilhosIcon',
  viewBox: '0 0 60 60',
  path: [
    <path
      key="1"
      d="M25.2026 36.1924L40.2479 21.756M26.3247 29.8145L24.5068 27.9864M22.6714 33.4878L20.8535 31.6598M33.634 22.4644L31.8162 20.6363M37.2732 18.8052L35.4553 16.9772M29.9807 26.1377L28.1628 24.3097M43.8995 16.1005C41.7655 13.9665 38.3062 13.9665 36.1722 16.1005L19.5553 32.9285C18.8372 33.6555 18.4158 34.6246 18.3736 35.6459L18.2226 39.2934L15.0146 42.5014C14.3285 43.1875 14.3285 44.2993 15.0146 44.9854C15.7007 45.6715 16.8125 45.6715 17.4986 44.9854L20.7066 41.7774L24.548 41.4121C25.4808 41.3235 26.3554 40.9188 27.0266 40.2647L43.8995 23.8278C46.0335 21.6938 46.0335 18.2345 43.8995 16.1005Z"
      stroke="#0066F3"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ]
});
