import {
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react';

import { AdvantageType } from '../../types/AdvantageType';

type ModalDetailAdvantageProps = {
  content: AdvantageType | undefined;
  handleClose: () => void;
};

export const ModalDetailAdvantage = ({
  content,
  handleClose
}: ModalDetailAdvantageProps) => {
  return (
    <>
      <Modal isOpen={true} onClose={handleClose}>
      <ModalOverlay bg="#00000056" />

        <ModalContent
          m="0 !important"
          w="100%"
          maxW={['295px', '530px']}
          position="absolute"
          top="15%"
          borderRadius="0 !important"
          background="#FFFFFF"
          boxShadow="0px 0px 5px rgba(50, 50, 50, 0.25)"
        >
          <HStack
            bg="#FF5000"
            display="flex"
            p="11px 0"
            justify="space-between"
            position="relative"
          >
            <Icon
              as={content?.icon}
              display={['none', 'block']}
              w="80px"
              h="80px"
              bg="#FF5000"
              dis
              borderRadius="50%"
              border="2px solid #FFFFFF"
              position="absolute"
              left="-15px"
            />
            <span style={{ width: '80px' }}></span>
            <Text
              m="0 36px 0 0 !important"
              textAlign="center"
              color="#FFFFFF"
              fontSize={['1rem', '1.5rem']}
              lineHeight={['1.5rem', '2rem']}
              fontWeight="700"
            >
              {content?.label}
            </Text>
            <Button
              bg="transparent"
              w={['11.6px', '20px']}
              h={['11.6px', '20px']}
              color="#FFFFFF"
              _hover={{}}
              _active={{}}
              _focus={{
                '&:focus': {
                  boxShadow: 'none !important'
                }
              }}
              onClick={handleClose}
            >
              X
            </Button>
          </HStack>
          <Box
            p={['16px 28px', '40px 24px 20px']}
            display="flex"
            flexDirection="column"
            gridGap="16px"
          >
            <Text
              w="100%"
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.5rem"
              color="#323232"
              dangerouslySetInnerHTML={content}
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
