import { Alert, AlertIcon, Box, Heading, Text } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { DateObjectUnits } from 'luxon';
import { useEffect, useRef, useState } from 'react';

import { apiSensedia } from '../api/api-sensedia';
import { ButtonNavegate } from '../components/buttons/ButtonNavegate';
import { CardPagamentoCheckOutBottom } from '../components/cards/CardPagamentoCheckBottom';
import { CardPagamentoFormPayment } from '../components/cards/CardPagamentoFormPayment';
import { Header } from '../components/layouts/Header';
import { IndexSteps } from '../components/layouts/IndexSteps';
import { ModalLoad } from '../components/modals/ModalLoad';
import { tratarCriticaCvp } from '../utils/cvp.helper.util';
import { pushToDataLayer } from '../utils/data-layer.util';
import { wlLocalStorage } from '../utils/localStorage.util';
import { creditCardType } from '../utils/money.util';
import { getProductType, hasDit } from '../utils/products.utils';
import {
  delay,
  getDateTimeString,
  getDifferenceBetweenDatesInYears
} from '../utils/time-utils';
import { WL_STARTER_LEGAL_AGE } from '../utils/wl-consts.util';

export const Pagamento = () => {
  const [mensagemErro, setMensagemErro] = useState('');

  const [maxWidth, setMaxWidth] = useState('');

  const [productSelected, setProductSelected] = useState({
    title: '',
    subtitle: '',
    text: '',
    price: 0,
    price_desconto_campanha: 0,
    assistences: [
      {
        assist: ''
      },
      {
        assist: ''
      },
      {
        assist: ''
      }
    ],
    covers: [
      {
        cover: '',
        value: ''
      },
      {
        cover: '',
        value: ''
      }
    ],
    recommended: false,
    id: ''
  });

  const duracaoLoadingFinal = 25 * 1000;

  const [campanhaDescontoBody, setCampanhaDescontoBody] = useState<{
    campanha: any;
  }>({
    campanha: null
  });

  const getCampanhaDesconto = async () => {
    const api = await apiSensedia();
    let result = {} as AxiosResponse;

    try {
      result = await api.get(`/v1/produtos/${idProduto}/campanha-desconto`);
    } catch (error: any) {
      console.log(error);
    }

    const campanhaDesconto = result.data;
    setCampanhaDescontoBody(campanhaDesconto);
  };

  //TODO: Deixar a validade do catrão separado
  const [loadingPayment, setLoadingpayment] = useState(false);
  const [value, setValue] = useState('');
  const [isHolder, setIsHolder] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const [cpf, setCpf] = useState('');
  const [installments, setInstallments] = useState('');
  const [numberCardCredit, setNumberCardCredit] = useState('');
  const [validity, setValidity] = useState('');
  const [nameCardCredit, setNameCardCredit] = useState('');
  const [bank, setBank] = useState('');
  const [bankAgency, setBankAgency] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [verifyingDigit, setVerifyingDigit] = useState('');
  const [alert, setAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [coversFromApi, setCoversFromApi] = useState([
    {
      id_cobertura: 0,
      valor_premio_calculado: 0,
      valor_premio_calculado_desconto: 0,
      valor_capital: 0,
      tabela_doencas: undefined
    }
  ]);

  const query = location.pathname;
  const parts = query.split('&');

  const idProduto = Number(parts[0].replace(/[^0-9,]/gi, ''));
  const weight = wlLocalStorage.getItem('weight');
  const height = wlLocalStorage.getItem('height');
  const city = wlLocalStorage.getItem('city') || '';
  const uf = wlLocalStorage.getItem('uf') || '';
  const district = wlLocalStorage.getItem('district') || '';
  const complement = wlLocalStorage.getItem('complement') || '';
  const cep = wlLocalStorage.getItem('cep') || '';
  const address = wlLocalStorage.getItem('address') || '';
  const addressNumber = wlLocalStorage.getItem('number') || '';
  const phone = wlLocalStorage.getItem('phone') || '';
  const email = wlLocalStorage.getItem('email') || '';
  const name = wlLocalStorage.getItem('name') || '';
  const socialName = wlLocalStorage.getItem('socialName') || '';
  const title = wlLocalStorage.getItem('title') || '';
  const subtitle = wlLocalStorage.getItem('subtitle') || '';
  const text = wlLocalStorage.getItem('text') || '';
  const birthDate = wlLocalStorage.getItem('date') || '';
  const genre = wlLocalStorage.getItem('genre') || '';
  const codigo_corretor = wlLocalStorage.getItem('codigo_corretor') || '';
  const codigo_estrutura_venda =
    wlLocalStorage.getItem('codigo_estrutura_venda') || '';
  const quotationData = wlLocalStorage.getItem('newQuotationData') || '{}';
  const valorRenda = Number(wlLocalStorage.getItem('valor_renda'));
  const parsedQuotationData = JSON.parse(quotationData);
  const assistenciasRetornadasDaCotacao = JSON.parse(
    wlLocalStorage.getItem('assistenciasRetornadasDaCotacao') || '{}'
  );
  const coberturasRetornadasDaCotacao = JSON.parse(
    wlLocalStorage.getItem('coberturasRetornadasDaCotacao') || '{}'
  );
  const respostasDPSA = JSON.parse(
    wlLocalStorage.getItem('respostasDPSA') || '[]'
  );
  const occupation = wlLocalStorage.getItem('occupationSelected') || '';
  const brokerage = wlLocalStorage.getItem('brokerage');
  const agency = wlLocalStorage.getItem('agency');
  const commission =
    wlLocalStorage.getItem('commission') === 'undefined'
      ? undefined
      : wlLocalStorage.getItem('commission');

  const tipoCategoriaProfissional = wlLocalStorage.getItem(
    'tipoCategoriaProfissional'
  );
  const tipoFranquia = wlLocalStorage.getItem('tipoFranquia');

  const coversToSend = parsedQuotationData.coberturas?.map(
    (cover: { valor_capital_informado: any }) => {
      return {
        ...cover,
        valor_capital_informado: cover.valor_capital_informado
      };
    }
  );

  const nameLegalGuardian: string = wlLocalStorage.getItem('nameLegalGuardian');
  const cpfLegalGuardian: string = wlLocalStorage.getItem('cpfLegalGuardian');
  const dateLegalGuardian: string = wlLocalStorage.getItem('dateLegalGuardian');
  const genreLegalGuardian: string =
    wlLocalStorage.getItem('genreLegalGuardian');
  const occupationCodeLegalGuardian: string = wlLocalStorage.getItem(
    'occupationSelectedLegalGuardian'
  );
  const incomeLegalGuardian: string = wlLocalStorage.getItem(
    'valor_renda_legal_guardian'
  );
  const kinshipDegree: string = wlLocalStorage.getItem('kinshipDegree');
  const phoneLegalGuardian: string =
    wlLocalStorage.getItem('phoneLegalGuardian');
  const emailLegalGuardian: string =
    wlLocalStorage.getItem('emailLegalGuardian');

  const legalGuardianData = {
    nameLegalGuardian,
    cpfLegalGuardian,
    dateLegalGuardian,
    genreLegalGuardian,
    occupationCodeLegalGuardian,
    incomeLegalGuardian,
    kinshipDegree,
    phoneLegalGuardian,
    emailLegalGuardian
  };

  const cardPagamentoRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const getDataProductSelected = async () => {
    setOpenModal(true);
    try {
      let data = {
        ...parsedQuotationData,
        valor_renda: valorRenda,
        codigo_parceiro: 3033856,
        data_nascimento: birthDate,
        sexo: genre[0],
        percentual_corretagem: Number(brokerage),
        percentual_agenciamento: agency ? Number(agency) : 100,
        atividade_profissional: occupation || '111',
        coberturas: coversToSend,
        tipo_categoria_profissional: hasDit()
          ? tipoCategoriaProfissional ?? undefined
          : undefined,
        tipo_franquia: hasDit() ? tipoFranquia ?? undefined : undefined
      };

      if (commission) {
        const corretagemCalculada = Number(brokerage);
        data = {
          ...data,
          percentual_corretagem: corretagemCalculada,
          percentual_agenciamento: Number(agency),
          percentual_intermediacao: Number(commission)
        };
      }

      const api = await apiSensedia();

      let res = {} as AxiosResponse;

      let looping = true;

      while (looping) {
        try {
          res = await api.post(`/v1/vendas/cotacoes`, data);
          looping = false;
        } catch (e: any) {
          if (e.response.status == 500) {
            console.log('ocorreu internal server error', e.response.statusText);
            console.log('ocorreu internal server error', e.response.data);
          } else {
            console.log('ocorreu gateway timeout', e.response.data);
          }
          if (e.response.status != 504) {
            looping = false;
          }
        }
      }

      const productToSave = {
        price: res.data.valor_premio,
        price_desconto_campanha: res.data.valor_premio_desconto_campanha
          ? res.data.valor_premio_desconto_campanha
          : res.data.valor_premio,
        title: title,
        subtitle: subtitle,
        text: text,
        assistences: assistenciasRetornadasDaCotacao.map((item: any) => ({
          assist: item.descricao
        })),
        covers:
          /*coversToSend as { cover: string; value: string }[]*/ coberturasRetornadasDaCotacao.map(
            (item: any) => ({
              cover: item.descricao,
              value: item.valor_capital
            })
          ),
        recommended: false,
        id: parts[0].replace(/[^0-9,]/gi, '')
      };
      const coversToSet = res.data.coberturas.map(
        (cover: {
          tabela_doencas: any;
          valor_premio_desconto_campanha: any;
          valor_premio: any;
          id_cobertura: any;
          valor_capital_informado: any;
        }) => {
          return {
            id_cobertura: cover.id_cobertura,
            valor_premio_calculado: cover.valor_premio,
            valor_premio_calculado_desconto:
              cover.valor_premio_desconto_campanha
                ? cover.valor_premio_desconto_campanha
                : cover.valor_premio,
            valor_capital: cover.valor_capital_informado,
            tabela_doencas: cover.tabela_doencas
          };
        }
      );
      setCoversFromApi(coversToSet);
      setProductSelected(productToSave);
      setOpenModal(false);
    } catch (erro) {
      console.log(erro);
    }
  };

  const paymentMethodHasDisccount = (method: 'CAR' | 'DCC' | 'PIX') => {
    return campanhaDescontoBody.campanha?.vias_pagamento?.includes(method);
  };

  const getAcronym = (paymentMethodName: string) => {
    if (paymentMethodName === 'credito') {
      return 'CAR';
    }

    if (paymentMethodName === 'debito') {
      return 'DCC';
    }

    if (paymentMethodName === 'pix') {
      return 'PIX';
    }
  };

  const getClientsAge = () => {
    const today = new Date();
    const birthDateArray = birthDate.split('-');
    const birthDateObj: DateObjectUnits = {
      day: birthDateArray[2],
      month: birthDateArray[1],
      year: birthDateArray[0]
    };

    const age = getDifferenceBetweenDatesInYears(birthDateObj, today);

    return age;
  };

  const completePurchase = async () => {
    try {
      setLoadingpayment(true);

      const paymentMethod: 'PIX' | 'DCC' | 'CAR' =
        value == 'pix' ? 'PIX' : value == 'debito' ? 'DCC' : 'CAR';

      const serviceToSend = parsedQuotationData.servicos.map(
        (service: { codigo: any; id: any; contratado: any }) => {
          return {
            contratado: service.contratado,
            codigo: Number(service.id)
          };
        }
      );

      const riskCovers = paymentMethodHasDisccount(paymentMethod)
        ? coversFromApi.map((cover) => {
            return {
              id_cobertura: cover.id_cobertura,
              valor_premio_calculado:
                installments == 'A'
                  ? Number(cover.valor_premio_calculado_desconto.toFixed(2))
                  : installments == 'S'
                  ? Number(
                      (cover.valor_premio_calculado_desconto / 2).toFixed(2)
                    )
                  : installments == 'T'
                  ? Number(
                      (cover.valor_premio_calculado_desconto / 4).toFixed(2)
                    )
                  : Number(
                      (cover.valor_premio_calculado_desconto / 12).toFixed(2)
                    ),
              valor_capital: Number(cover.valor_capital),
              tabela_doencas: cover.tabela_doencas
            };
          })
        : coversFromApi.map((cover) => {
            return {
              id_cobertura: cover.id_cobertura,
              valor_premio_calculado:
                installments == 'A'
                  ? Number(cover.valor_premio_calculado.toFixed(2))
                  : installments == 'S'
                  ? Number((cover.valor_premio_calculado / 2).toFixed(2))
                  : installments == 'T'
                  ? Number((cover.valor_premio_calculado / 4).toFixed(2))
                  : Number((cover.valor_premio_calculado / 12).toFixed(2)),
              valor_capital: Number(cover.valor_capital),
              tabela_doencas: cover.tabela_doencas
            };
          });

      let initialData: any = {
        servicos: serviceToSend,
        id_produto: idProduto,
        data_hora_assinatura: getDateTimeString(new Date()),
        nome_participante_principal: name,
        email: email,
        data_nascimento_participante_principal: birthDate,
        sexo_participante_principal: genre[0],
        renda_mensal_participante_principal: valorRenda,
        codigo_ocupacao_principal_participante_principal: occupation || '111',
        altura: Number(height?.slice(0, 1) + '.' + height?.slice(1)),
        peso: Number(weight),
        cpf_participante_principal: String(parts[1].replace(/[^0-9,]/gi, '')),
        ddd_telefone_celular: phone.substring(1, 3),
        telefone_celular: phone.substring(4, phone.length).replace('-', ''),
        cep: cep.replace('-', ''),
        logradouro: address,
        numero_endereco: addressNumber,
        bairro: district,
        complemento: complement,
        local_assinatura: `${city}, ${uf}`,
        gerar_boleto_primeira_parcela: false,
        meio_Pagamento: paymentMethod,
        dia_vencimento: Number(validity || 5),
        periodicidade_pagamento: installments || 'A',
        herdeiros_conforme_legislacao: true,
        codigo_parceiro: 3033856,
        corretor_principal: commission
          ? {
              codigo_estrutura_venda: Number(codigo_estrutura_venda),
              acao_venda: '0',
              percentualParticipacao: 100,
              codigo_corretor: Number(codigo_corretor),
              percentual_corretagem: Number(brokerage),
              percentual_agenciamento: Number(agency),
              percentual_intermediacao: Number(commission)
            }
          : {
              codigo_estrutura_venda: Number(codigo_estrutura_venda),
              acao_venda: '0',
              percentualParticipacao: 100,
              codigo_corretor: Number(codigo_corretor),
              percentual_corretagem: Number(brokerage),
              percentual_agenciamento: Number(agency)
            },
        coberturas_risco: riskCovers,
        id_registro_venda: sessionStorage.getItem('id_registro_venda'),
        valor_premio: productSelected.price,
        respostas:
          getProductType(idProduto) == 'vida_flex' ||
          getProductType(idProduto) == 'vida_individual' ||
          getProductType(idProduto) === 'vida_mulher'
            ? respostasDPSA
            : undefined,
        tipo_categoria_profissional: hasDit()
          ? tipoCategoriaProfissional ?? undefined
          : undefined,
        tipo_franquia: hasDit() ? tipoFranquia ?? undefined : undefined,
        responsavel_legal:
          getClientsAge() < WL_STARTER_LEGAL_AGE
            ? {
                bairro: district,
                cep: cep.replace('-', ''),
                codigo_ocupacao_responsavel:
                  occupationCodeLegalGuardian || '111',
                cpf: cpfLegalGuardian,
                data_nascimento_responsavel_legal: dateLegalGuardian,
                grau_parentesco: kinshipDegree,
                logradouro: address,
                nome: nameLegalGuardian,
                numero_endereco: addressNumber,
                sexo_responsavel_legal: genreLegalGuardian,
                valor_renda_mensal_responsavel_legal: incomeLegalGuardian
              }
            : undefined
      };

      if (socialName) {
        initialData = {
          ...initialData,
          nome_social_participante_principal: socialName
        };
      }

      let data: any = { ...initialData };

      if (paymentMethod == 'CAR') {
        const cardType = creditCardType(
          String(numberCardCredit.replaceAll('.', ''))
        );

        data = {
          ...initialData,
          dia_vencimento: 5,
          dados_cartao_credito: {
            bandeira: cardType,
            cpf_pagador: String(cpf.replaceAll('.', '')),
            nome_impresso: nameCardCredit,
            numero_cartao: String(numberCardCredit.replaceAll('.', '')),
            validade_cartao: validity
          }
          // coberturas_risco: riskCoversCreditCard
        };
      }

      if (paymentMethod == 'DCC') {
        data = {
          ...initialData,
          dados_bancarios_debito_em_conta: {
            agencia: String(bankAgency),
            banco: bank.slice(0, 3),
            conta_corrente: String(bankAccount),
            dv_conta_corrente: String(verifyingDigit)
          }
        };
      }

      const api = await apiSensedia();

      data.subdominio_website = wlLocalStorage.getSubdomain();

      const fullPrice = paymentMethodHasDisccount(paymentMethod)
        ? productSelected.price_desconto_campanha
        : productSelected.price;

      pushToDataLayer({
        event: 'purchase',
        data: {
          nome: name,
          email,
          telefone: phone,
          cep,
          estado: uf,
          valor: fullPrice,
          cidade: city
        }
      });

      const res = await api.post(`/v1/vendas/contratacoes`, data);

      const token = res.data.token;

      data.token = token;

      let resToken: AxiosResponse = {
        data: { message: 'processing' }
      } as AxiosResponse;

      let looping = true;
      console.log('entrando no loop', resToken.data);
      while (looping) {
        await delay(6000);
        console.log('reenviando para verificação', data.token);
        try {
          resToken = await api.post(`/v1/vendas/contratacoes`, data);
          if (resToken.data.message != 'processing') {
            looping = false;
          }
        } catch (error: any) {
          if (error.response.status === 504) {
            console.log('ocorreu gateway timeout', error.response.data);
            looping = true;
          } else {
            looping = false;

            if (error.response.status == 500) {
              console.log(
                'ocorreu internal server error',
                error.response.statusText,
                error.response.data
              );
            }
          }
        }
      }

      setLoadingpayment(false);

      if (resToken.data.message == 'success') {
        window.location.href = '/conclusao';
      } else {
        console.log('erro ao pagar', tratarCriticaCvp(resToken.data.criticas));
        setMensagemErro(tratarCriticaCvp(resToken.data.criticas));
        setAlert(true);
      }
    } catch (e) {
      setAlert(true);
      setLoadingpayment(false);
      console.log('erro processando pagamento', e);
    }
  };

  const handleDisableButtonNavegate = () => {
    const validityArray = Array.from(
      { length: 31 },
      (_, index) => index + 1
    ).map((el) => el);

    setIsDisable(true);
    switch (value) {
      case 'credito':
        if (
          cpf.replaceAll('.', '').replaceAll('_', '').length === 11 &&
          numberCardCredit.replaceAll('.', '').replaceAll('_', '').length ===
            16 &&
          validity != '' &&
          nameCardCredit != ''
        ) {
          setIsDisable(false);
        }
        break;
      case 'pix':
        // console.log(validity, installments )
        if (validityArray.includes(Number(validity)) && installments != '') {
          setIsDisable(false);
        }
        break;
      case 'debito':
        if (
          cpf.replaceAll('.', '').replaceAll('_', '').length === 11 &&
          bankAgency.replaceAll('.', '').replaceAll('_', '').length === 4 &&
          bankAccount.replaceAll('.', '').replaceAll('_', '').length >= 4 &&
          verifyingDigit.replaceAll('.', '').replaceAll('_', '').length === 1 &&
          validity != '' &&
          bank != ''
        ) {
          setIsDisable(false);
        }
    }
  };

  useEffect(() => {
    getCampanhaDesconto();
    getDataProductSelected();
    setMaxWidth(cardPagamentoRef.current?.clientWidth.toString());
  }, []);

  useEffect(() => {
    if (value == 'credito') {
      setValidity('');
    }
  }, [value]);

  useEffect(() => {
    handleDisableButtonNavegate();
  }, [
    cpf,
    installments,
    validity,
    numberCardCredit,
    nameCardCredit,
    bank,
    bankAccount,
    bankAgency,
    verifyingDigit,
    value
  ]);

  return (
    <>
      <Box
        w="100%"
        p="30px 16px"
        bg="#F5F5F5"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {alert && (
          <Alert
            status="error"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <AlertIcon />
            {mensagemErro
              ? `Erro: ${mensagemErro}`
              : 'Erro ao finalizar a contratação, tente novamente.'}
          </Alert>
        )}

        <Heading
          w="100%"
          m={['40px 0 25px 0', '40px 0 25px 0', '60px 0 40px 0 !important']}
          color="#000000"
          fontSize={['1.5rem', '1.5rem', '2rem']}
          fontWeight="700"
          lineHeight={['2rem', '2rem', '3rem']}
          display="flex"
          justifyContent={['start', 'start', 'center']}
        >
          Pagamento
        </Heading>

        <Box
          ref={cardPagamentoRef}
          w="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <CardPagamentoFormPayment
            price={
              paymentMethodHasDisccount(getAcronym(value) as any)
                ? productSelected.price_desconto_campanha
                : productSelected.price
            }
            fullPrice={productSelected.price}
            promoPrice={productSelected.price_desconto_campanha}
            value={value}
            setValue={setValue}
            isHolder={isHolder}
            setIsHolder={setIsHolder}
            cpf={cpf}
            setCpf={setCpf}
            installments={installments}
            productType={getProductType(idProduto)}
            setInstallments={setInstallments}
            numberCardCredit={numberCardCredit}
            setNumberCardCredit={setNumberCardCredit}
            validity={validity}
            setValidity={setValidity}
            nameCardCredit={nameCardCredit}
            setNameCardCredit={setNameCardCredit}
            bank={bank}
            setBank={setBank}
            bankAgency={bankAgency}
            setBankAgency={setBankAgency}
            bankAccount={bankAccount}
            setBankAccount={setBankAccount}
            verifyingDigit={verifyingDigit}
            setVerifyingDigit={setVerifyingDigit}
            recomended={
              value === 'pix' ? false : value === 'debito' ? false : true
            }
            dadosCampanha={campanhaDescontoBody.campanha}
          />
        </Box>

        <CardPagamentoCheckOutBottom
          price={
            paymentMethodHasDisccount(getAcronym(value) as any)
              ? productSelected.price_desconto_campanha
              : productSelected.price
          }
          title={productSelected.title}
          installments={installments}
          typeOfPayment={value}
        />
        <Box
          mt="20px"
          w="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {value === 'credito' && (
            <Text
              m="0 0 10px !important"
              color="#323232"
              fontSize="0.75rem"
              fontWeight="400"
              lineHeight="1.25rem"
              maxW="655px"
              w="100%"
              textAlign={['start', 'start', 'center']}
            >
              Apenas o
              <span style={{ fontWeight: 'bold' }}>
                &nbsp;valor da parcela será lançado
              </span>
              , assim não comprometemos o limite do seu cartão de crédito.
            </Text>
          )}

          {value === 'pix' && (
            <>
              <Text
                m="0 0 8px !important"
                color="#323232"
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="1.25rem"
                maxW="655px"
                w="100%"
                textAlign={['start', 'start', 'center']}
              >
                Você receberá o
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp;código PIX para pagamento em seu e-mail&nbsp;
                </span>
                após a finalização.
              </Text>
            </>
          )}
          {value === 'debito' && (
            <>
              <Text
                m="0 0 10px !important"
                color="#323232"
                fontSize="0.75rem"
                fontWeight="400"
                lineHeight="1.25rem"
                maxW="655px"
                w="100%"
                textAlign={['start', 'start', 'center']}
              >
                <span style={{ fontWeight: 'bold' }}>
                  É imprescindível que o você autorize o débito nos canais
                  digitais do Banco
                </span>
                . Essa autorização ficará disponível somente no intervalo entre
                7 à 10 dias antes do vencimento, caso não ocorra a autorização,
                não ocorrerá o débito.
              </Text>
            </>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          m="30px 0 !important"
          w="100%"
          maxW={maxWidth}
        >
          <ButtonNavegate
            content={'Contratar'}
            disable={isDisable}
            actionPrev={() => {
              history.back();
            }}
            actionNext={() => completePurchase()}
          />
        </Box>
        {alert && (
          <div
            style={{ paddingBottom: '40px', display: 'flex', width: '100%' }}
          >
            <Alert
              status="error"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon />
              {mensagemErro
                ? `Erro: ${mensagemErro}`
                : 'Erro ao finalizar a contratação, tente novamente.'}
            </Alert>
          </div>
        )}
      </Box>
      <ModalLoad isOpenModal={openModal} content={'Buscando produto...'} />
      <ModalLoad
        isOpenModal={loadingPayment}
        content={'Finalizando contratação...'}
        contentArray={[
          'Enviando dados...',
          'Processando...',
          'Realizando contratação...',
          'Estamos quase lá...',
          'Finalizando...'
        ]}
        duration={duracaoLoadingFinal}
      />
    </>
  );
};
