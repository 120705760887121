import { createIcon } from '@chakra-ui/react';

export const CondicoesSaudeIcon = createIcon({
  displayName: 'CondicoesSaudeIcon',
  viewBox: '0 0 90 90',
  path: [
    <g key="1" clipPath="url(#clip0_4150_55191)" fill="white">
      <path
        d="M-1 38.8005H26.5214L37.6967 24.7894L48.205 50.3093L58.3795 37.6326H90.4046M81.8974 37.6332C83.5653 30.2941 81.7306 22.2881 75.8927 16.6171C67.3861 8.11043 53.7089 7.77683 44.7018 15.7831C35.6948 7.77683 22.0174 8.11043 13.5108 16.6171C7.83968 22.2881 5.83816 30.2941 7.50613 37.6332M13.6778 48.3086L45.2022 80L76.7268 48.3086"
        stroke="#FF5000"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>,

    <defs key="2">
      <clipPath id="clip0_4150_55191">
        <rect width="90" height="90" fill="white" />
      </clipPath>
    </defs>
  ]
});
