import { Input, InputGroup, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import InputMask from 'react-input-mask';

import { getNumberFormat } from '../../utils/getNumberFormat';

type RegistrationInputProps = {
  id?: string;
  width?: string[];
  type: string;
  mask: string;
  placeholder: string;
  displayTextLabel?: boolean;
  textLabel?: string;
  textErrorMessage: string;
  textErrorMessageColor?: string;
  value: any;
  setValue: (newState: any) => void;
  necessarySubmit?: boolean;
  handleSubmit?: any;
  disable?: boolean;
  setBlur?: (newState: any) => void;
  setFocus?: (newState: any) => void;
  handleAge?: (e: any) => void;
  isMoney?: boolean;
};

export const RegistrationInput = ({
  id,
  width,
  type,
  mask,
  placeholder,
  displayTextLabel,
  textLabel,
  textErrorMessage,
  textErrorMessageColor,
  value,
  setValue,
  necessarySubmit,
  handleSubmit,
  disable,
  setBlur,
  setFocus,
  handleAge,
  isMoney
}: RegistrationInputProps) => {
  const [errorMessage, setErrorMessage] = useState(value.length > 0 ?? false);
  const [showPlaceholderOverField, setShowPlaceholderOverField] = useState(
    Boolean(value) || disable
  );
  const defaultColor = '#FF5000';

  const today = new Date();

  const onBlur = setBlur
    ? setBlur
    : () => {
        return null;
      };

  const onFocus = setFocus
    ? setFocus
    : () => {
        return null;
      };

  const handleMoneyMask = (value: string) => {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100
    );

    setValue(`R$ ${result}`);
  };

  return (
    <>
      <Stack w={width ? width : '100%'} m="0 !important" position="relative">
        {displayTextLabel && showPlaceholderOverField ? (
          <Text
            w={'100%'}
            fontSize={'12px'}
            m="0 !important"
            fontWeight="400"
            position="absolute"
            top="-8px"
            left="16px"
            zIndex="10"
            color="#828282"
            display={undefined}
          >
            {placeholder}
          </Text>
        ) : (
          <></>
        )}

        <InputGroup
          m="0 !important"
          w={['100%']}
          display="flex"
          flexDirection="column"
        >
          <Input
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore:next-line
            as={InputMask}
            lang={'pt-BR'}
            id={id}
            type={type}
            onClick={() => onBlur(true)}
            mask={mask}
            disabled={disable || false}
            m=" 0 !important"
            h="50px"
            bg="#FFFFFF"
            border="none"
            borderBottom={`1px solid #001E64 !important`}
            borderRadius="0 !important"
            placeholder={showPlaceholderOverField ? undefined : placeholder}
            _placeholder={{
              color: '#828282',
              fontSize: '1rem',
              fontWeight: '400',
              lineHeight: '1.5rem'
            }}
            value={value}
            onKeyUp={() => {
              setErrorMessage(true);
              if (necessarySubmit) {
                handleSubmit();
              }
            }}
            onBlur={() => {
              if (!value) {
                setShowPlaceholderOverField(false);
              }
              setErrorMessage(true);
              if (necessarySubmit) {
                handleSubmit();
              }
            }}
            onChange={(e) => {
              isMoney
                ? handleMoneyMask(e.target.value)
                : setValue(e.target.value);

              type === 'date' && handleAge && handleAge(e);
            }}
            _hover={
              defaultColor
                ? { borderBottom: `1px solid ${defaultColor} !important` }
                : { borderBottom: '1px solid #FF5000 !important' }
            }
            _focus={
              defaultColor
                ? { borderBottom: `1px solid ${defaultColor} !important` }
                : { borderBottom: '1px solid #FF5000 !important' }
            }
            onFocus={(e: React.FocusEvent<HTMLInputElement, Element>) => {
              setShowPlaceholderOverField(true);
              onFocus(e);
            }}
            transition="0.2s"
          />
        </InputGroup>
        {errorMessage && (
          <Text
            color={textErrorMessageColor || '#AA2C23'}
            fontSize="0.875rem"
            lineHeight="1.5rem"
            w="100%"
            m="0 !important"
            position="absolute"
            top="50px"
          >
            {textErrorMessage}
          </Text>
        )}
      </Stack>
    </>
  );
};
