import {
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Icon,
  Input,
  List,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Select,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { apiSensedia } from '../../api/api-sensedia';
import { getNumberFormat } from '../../utils/getNumberFormat';
import { getIconByName } from '../../utils/icons.util';
import { wlLocalStorage } from '../../utils/localStorage.util';
import { isCoberturaDIT } from '../../utils/products.utils';
import { BoxDoencasGraves } from '../contents/BoxDoencasGraves';
import { RegistrationSelect } from '../inputs/RegistrationSelect';

type ModalDetailCoverageProps = {
  coverage: any;
  coverageStates: any;
  states: any;
  setStates: any;
  idWebsite: string;
  idProdutoCustomizado: string;
  isProductVariable: boolean;
  setCoverageStates: (states: any) => void;
  handleClose: () => void;
  handleSubmit: () => void;
};

export const ModalDetailCoverage = ({
  coverage,
  coverageStates,
  states,
  setStates,
  idWebsite,
  idProdutoCustomizado,
  isProductVariable,
  setCoverageStates,
  handleClose,
  handleSubmit
}: ModalDetailCoverageProps) => {
  const tiposCategoriaProfissional = [
    { option: 'CLT', value: 'C', excludes: ['R'] },
    { option: 'Autônomo', value: 'A' },
    { option: 'Profissional Liberal', value: 'L' }
  ];

  const tiposFranquia = [
    { option: 'Normal', value: 'N' },
    { option: 'Reduzida', value: 'R', excludes: ['C'] }
  ];

  const [tipoCategoriaProfissional, setTipoCategoriaProfissional] = useState(
    wlLocalStorage.getItem('tipoCategoriaProfissional') || ''
  );
  const [tipoFranquia, setTipoFranquia] = useState(
    wlLocalStorage.getItem('tipoFranquia') || ''
  );

  const [tiposCategoriaProfissionalState, setTiposCategoriaProfissionalState] =
    useState(tiposCategoriaProfissional);
  const [tiposFranquiaState, setTiposFranquiaState] = useState(tiposFranquia);

  useEffect(() => {
    const newTiposCategoriaProfissional = tiposCategoriaProfissional.filter(
      (el) => !el.excludes?.includes(tipoFranquia)
    );
    const newTiposFranquiaState = tiposFranquia.filter(
      (el) => !el.excludes?.includes(tipoCategoriaProfissional)
    );

    setTiposCategoriaProfissionalState(newTiposCategoriaProfissional);
    setTiposFranquiaState(newTiposFranquiaState);
  }, [tipoCategoriaProfissional, tipoFranquia]);

  const [valueOfCoverage, setValueOfCoverage] = useState<string>(
    `${getNumberFormat(coverageStates[coverage?.id]?.valor_capital)}`
  );

  const [messageErrorForValueOfCoverage, setMessageErrorForValueOfCoverage] =
    useState<boolean>(false);
  const [
    disableButtonSubmitValueOfCoverage,
    setDisableButtonSubmitValueOfCoverage
  ] = useState<boolean>(false);

  const [tabelaDoencas, setTabelaDoencas] = useState<any>({});
  const getTabelaDoencas = async () => {
    const api = await apiSensedia();

    try {
      const res = await api.get(
        `/v1/coberturas/${coverage.id}/doencas?id-website=${idWebsite}&id-produto=${idProdutoCustomizado}`
      );

      setTabelaDoencas(res.data);
    } catch (e: any) {
      if (e.response.status == 500) {
        console.log('ocorreu internal server error', e.response.statusText);
        console.log('ocorreu internal server error', e.response.data);
      } else {
        console.log('ocorreu gateway timeout', e.response.data);
      }
    }
  };

  const onlyDigits = (event: any) => {
    return event.target.value
      .split('')
      .filter((item: any) => /\d/.test(item))
      .join('')
      .padStart(3, '0');
  };

  const maskCurrency = (value: any, locale: string, currency: string) => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency
    }).format(value);
  };

  const handleValueOfCoverageInput = (event: any) => {
    const digitsFloat =
      onlyDigits(event).slice(0, -2) + '.' + onlyDigits(event).slice(-2);

    const result = Number(digitsFloat);

    setValueOfCoverage(maskCurrency(digitsFloat, 'pt-BR', 'BRL'));

    if (result < coverage?.valor_minimo_capital) {
      setMessageErrorForValueOfCoverage(true);
      setDisableButtonSubmitValueOfCoverage(true);
    } else if (result > coverage?.valor_maximo_capital) {
      setMessageErrorForValueOfCoverage(true);
      setDisableButtonSubmitValueOfCoverage(true);
    } else {
      setMessageErrorForValueOfCoverage(false);
      setDisableButtonSubmitValueOfCoverage(false);
    }
    coverageStates[coverage?.id].valor_capital = Number(digitsFloat);
    setCoverageStates({ ...coverageStates });
  };

  const handleValueOfCoverageSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = maskCurrency(onlyDigits(event), 'pt-BR', 'BRL');

    setValueOfCoverage(value);

    const result = Number(onlyDigits(event));

    coverageStates[coverage?.id].valor_capital = result;
    setCoverageStates({ ...coverageStates });
  };

  useEffect(() => {
    wlLocalStorage.setItem(
      'tipoCategoriaProfissional',
      tipoCategoriaProfissional
    );
    wlLocalStorage.setItem('tipoFranquia', tipoFranquia);

    if (
      isCoberturaDIT(coverage.id) &&
      (!tipoCategoriaProfissional || !tipoFranquia)
    ) {
      setDisableButtonSubmitValueOfCoverage(true);
    } else {
      setDisableButtonSubmitValueOfCoverage(false);
    }
  }, [tipoCategoriaProfissional, tipoFranquia]);

  useEffect(() => {
    if (
      isCoberturaDIT(coverage.id) &&
      (!tipoCategoriaProfissional || !tipoFranquia)
    ) {
      setDisableButtonSubmitValueOfCoverage(true);
    }

    if (coverage && !tabelaDoencas.id_tabela_doenca) getTabelaDoencas();
  }, []);

  return (
    <>
      <Modal isOpen={true} onClose={handleClose} size="xl">
        <ModalOverlay bg="#00000056" />

        <ModalContent
          m="0 !important"
          w="100%"
          maxW={['327px', '610px']}
          position="absolute"
          top="3%"
          borderRadius="0 !important"
          background="#FFFFFF"
          boxShadow="0px 0px 5px rgba(50, 50, 50, 0.25)"
        >
          <HStack display="flex" justify="end" w="100%">
            <Button
              bg="transparent"
              _hover={{}}
              _active={{}}
              _focus={{
                '&:focus': {
                  boxShadow: 'none !important'
                }
              }}
              onClick={handleClose}
            >
              X
            </Button>
          </HStack>
          <Box
            p={{ base: '0 28px 16px', md: '0 30px' }}
            display="flex"
            flexDirection="column"
            gridGap="16px"
            w="100%"
          >
            <HStack gap="22px">
              <Icon as={getIconByName(coverage.icon_name)} w="36px" h="38px" />
              <Text
                fontSize={['1.25rem', '1.5rem']}
                lineHeight={['1.5rem', '2rem']}
                fontWeight="700"
              >
                {coverage?.descricao}
              </Text>
            </HStack>
            {isProductVariable ? (
              <>
                <Text
                  mt="16px"
                  color="#323232"
                  fontSize={['1rem', '1.125rem']}
                  lineHeight="1.5rem"
                  fontWeight="400"
                >
                  Gostaria de aumentar o valor de cobertura?
                </Text>
              </>
            ) : (
              <></>
            )}

            <Box m="16px 0 27px" w="100%">
              {isProductVariable ? (
                <>
                  {coverage.possui_capitais_especificos ? (
                    <>
                      <Text
                        color="#828282"
                        fontSize="0.75rem"
                        lineHeight="1.5rem"
                        fontWeight="400"
                      >
                        Selecione o valor da cobertura
                      </Text>
                      <Select
                        data-testid="ModalDetailCoverage-select-value"
                        variant="flushed"
                        borderBottom="1px solid #5A5A5A !important"
                        placeholder={valueOfCoverage}
                        onChange={handleValueOfCoverageSelect}
                        _hover={{}}
                        _focus={{
                          '&:focus': {
                            boxShadow: 'none !important'
                          }
                        }}
                      >
                        {coverage.capitais_disponiveis.map(
                          (item: any, index: number) => {
                            if (valueOfCoverage != getNumberFormat(item)) {
                              return (
                                <option key={index} value={item}>
                                  {getNumberFormat(item)}
                                </option>
                              );
                            }
                          }
                        )}
                      </Select>
                    </>
                  ) : (
                    <>
                      <Text
                        color="#828282"
                        fontSize="0.75rem"
                        lineHeight="1.5rem"
                        fontWeight="400"
                      >
                        Digite o valor da cobertura
                      </Text>
                      <Input
                        data-testid="ModalDetailCoverage-input-value"
                        variant="flushed"
                        borderBottom="1px solid #5A5A5A !important"
                        value={valueOfCoverage}
                        onChange={(e) => {
                          handleValueOfCoverageInput(e);
                        }}
                      />
                    </>
                  )}
                  {messageErrorForValueOfCoverage && (
                    <Text mt="8px" color="#AA2C23">
                      Valor inválido
                    </Text>
                  )}
                  <Text
                    mt="8px"
                    color="#323232"
                    fontSize="0.875rem"
                    lineHeight="1.5rem"
                    fontWeight="400"
                  >
                    Escolha um valor entre&nbsp;
                    {`${getNumberFormat(
                      coverage?.valor_minimo_capital?.toFixed(2)
                    )}`}
                    &nbsp;e&nbsp;
                    {`${getNumberFormat(
                      coverage?.valor_maximo_capital?.toFixed(2)
                    )}`}
                  </Text>
                </>
              ) : (
                <></>
              )}
              {isCoberturaDIT(coverage.id) ? (
                <>
                  <Box
                    mt="30px"
                    data-testid="ModalDetailCoverage_vinulo_select"
                  >
                    <RegistrationSelect
                      id="ModalDetailCoverage_vinulo_select"
                      placeholder={'Vínculo empregatício*'}
                      textLabel={
                        tipoCategoriaProfissional
                          ? 'Vínculo empregatício*'
                          : '*'
                      }
                      textErrorMessage={
                        tipoCategoriaProfissional === ''
                          ? 'Campo Obrigatório'
                          : ''
                      }
                      value={tipoCategoriaProfissional}
                      setValue={setTipoCategoriaProfissional}
                      options={tiposCategoriaProfissionalState}
                    />
                  </Box>

                  <Box
                    mt="40px"
                    data-testid="ModalDetailCoverage_tipo_franquia_select"
                  >
                    <RegistrationSelect
                      id="ModalDetailCoverage_tipo_franquia_select"
                      placeholder={'Tipo de franquia*'}
                      textLabel={tipoFranquia ? 'Tipo de franquia*' : '*'}
                      textErrorMessage={
                        tipoFranquia === '' ? 'Campo Obrigatório' : ''
                      }
                      value={tipoFranquia}
                      setValue={setTipoFranquia}
                      options={tiposFranquiaState}
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}
              {coverage?.tabela_doencas ? (
                <BoxDoencasGraves
                  coverage={coverage}
                  doencasCobertura={Array.from(
                    coverage?.tabela_doencas?.doencas || [],
                    (x) => x
                  )}
                  tabelaDoencasSelecionaveis={tabelaDoencas}
                  coverageStates={coverageStates}
                  setCoverageStates={setCoverageStates}
                  isProductVariable={isProductVariable}
                />
              ) : undefined}

              <Button
                data-testid="ModalDetailCoverage-btn-salvar"
                disabled={disableButtonSubmitValueOfCoverage}
                mt="27px"
                bg="#FF5000"
                w="100%"
                h="48px"
                p="12px 0"
                color="#FFFFFF"
                borderRadius="4px"
                onClick={() => {
                  if (isCoberturaDIT(coverage.id)) {
                    states[coverage.id].checked = true;
                    setStates({ ...states });
                  } else {
                    handleSubmit();
                  }
                  handleClose();
                }}
                _hover={{}}
                _active={{}}
                _focus={{
                  '&:focus': {
                    boxShadow: 'none !important'
                  }
                }}
              >
                SALVAR
              </Button>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
