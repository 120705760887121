import { isJson } from "./json.util";

const getSubdomain = () => {
  if (process.env.REACT_APP_NODE_ENV === 'homolog') {
    const fullHref = window.location.href;
    const subdominio = fullHref.split('subdominio=')[1];
    if (subdominio) {
      sessionStorage.setItem('wlsubdomain', subdominio);
    }
    return subdominio || sessionStorage.getItem('wlsubdomain');
  } else {
    const fullHost = window.location.host;
    const subdominio = fullHost?.split('.')?.[0];
    if (subdominio) {
      sessionStorage.setItem('wlsubdomain', subdominio);
    }
    return subdominio || sessionStorage.getItem('wlsubdomain');
  };
};

export const wlLocalStorage = {
  setItem: (key: string, value: string) => {
    const subdomain = getSubdomain() || '';

    const wLWebsitesDataString = sessionStorage.getItem('wLWebsitesData');

    if (!wLWebsitesDataString) {
      const wLWebsitesData = {};
      sessionStorage.setItem('wLWebsitesData', JSON.stringify(wLWebsitesData));
    }

    const wLWebsitesData = JSON.parse(sessionStorage.getItem('wLWebsitesData') || '{}');

    const keyValPair = {} as any;

    keyValPair[key] = value;

    wLWebsitesData[subdomain] = {
      ...wLWebsitesData[subdomain],
      ...keyValPair
    };

    sessionStorage.setItem('wLWebsitesData', JSON.stringify(wLWebsitesData));
  },

  getItem: (key: string) => {
    const subdomain = getSubdomain() || '';
    const wLWebsitesDataString = sessionStorage.getItem('wLWebsitesData');
    const wLWebsitesData = wLWebsitesDataString && isJson(wLWebsitesDataString) ? JSON.parse(wLWebsitesDataString) : null;

    const returnValue = wLWebsitesData?.[subdomain]?.[key];
    if (returnValue) {
      return returnValue;
    }

    return null;
  },

  removeItem: (key: string) => {
    const subdomain = getSubdomain() || '';

    const value = null;

    const wLWebsitesDataString = sessionStorage.getItem('wLWebsitesData');

    if (!wLWebsitesDataString) {
      const wLWebsitesData = {};
      sessionStorage.setItem('wLWebsitesData', JSON.stringify(wLWebsitesData));
    }

    const wLWebsitesData = JSON.parse(sessionStorage.getItem('wLWebsitesData') || '{}');

    const keyValPair = {} as any;

    keyValPair[key] = value;

    wLWebsitesData[subdomain] = {
      ...wLWebsitesData[subdomain],
      ...keyValPair
    };

    sessionStorage.setItem('wLWebsitesData', JSON.stringify(wLWebsitesData));
  },

  clear: () => {
    const subdomain = getSubdomain() || '';
    const wLWebsitesDataString = sessionStorage.getItem('wLWebsitesData');

    if (!wLWebsitesDataString) {
      const wLWebsitesData = {};
      sessionStorage.setItem('wLWebsitesData', JSON.stringify(wLWebsitesData));
    }

    let wLWebsitesData = {} as any;
    wLWebsitesData[subdomain] = undefined;
    while (wLWebsitesData[subdomain] !== null) {
      wLWebsitesData = JSON.parse(sessionStorage.getItem('wLWebsitesData') || '{}');

      wLWebsitesData[subdomain] = null;
      console.log('unsetting data', wLWebsitesData);
      sessionStorage.removeItem('wLWebsitesData');
      sessionStorage.setItem('wLWebsitesData', JSON.stringify(wLWebsitesData));
    }
  },

  getSubdomain
}
