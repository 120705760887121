import { Box, HStack, Text, useTheme, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { StepSuccessCheck } from '../../assets/icons/ferramentas/StepSuccessCheck';

type StepsWebsiteProps = {
  currentStep: number;
  zIndex?: number;
};

type Step = {
  number: number;
  current: boolean;
  past: boolean;
};

export const IndexSteps = ({
  currentStep,
  zIndex
}: StepsWebsiteProps): JSX.Element => {
  const { breakpoints } = useTheme();
  const [steps, setSteps] = useState<Array<Step>>([
    {
      number: 1,
      current: false,
      past: false
    },
    {
      number: 2,
      current: false,
      past: false
    },
    {
      number: 3,
      current: false,
      past: false
    },
    {
      number: 4,
      current: false,
      past: false
    },
    {
      number: 5,
      current: false,
      past: false
    }
  ]);

  useEffect(() => {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].number < currentStep) {
        steps[i].past = true;
        steps[i].current = false;
      } else if (steps[i].number == currentStep) {
        steps[i].current = true;
        steps[i].past = false;
      }
    }

    setSteps([...steps]);
  }, []);

  return (
    <HStack
      h="64px"
      position="relative"
      gap={{
        base: '31px',
        md: '44px',
        lg: '55px'
      }}
      zIndex={zIndex}
      m="0 !important"
    >
      {currentStep > 0 &&
        steps.map((step, index) => (
          <Box key={index} m={'0 !important'} pb="30px" position="relative">
            <Box
              width="34px"
              height="34px"
              bg="white"
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border={`4px solid ${step.current ? '#001E64' : '#AAAAAA'}`}
              fontWeight="bold"
              fontSize="0.9rem"
              m="0 !important"
              position="relative"
              color={step.current ? '#001E64' : '#AAAAAA'}
              zIndex={-(index + 1)}
              sx={
                index > 0
                  ? {
                      '&::before': {
                        content: '""',
                        height: '4px',
                        width: '31px',
                        background:
                          step.past || step.current ? '#006217' : '#AAAAAA',
                        position: 'absolute',
                        left: '-35px',
                        top: 0,
                        bottom: 0,
                        margin: 'auto'
                      },
                      [`@media screen and (min-width: ${breakpoints.md})`]: {
                        '&::before': {
                          content: '""',
                          height: '4px',
                          width: '45px',
                          background:
                            step.past || step.current ? '#006217' : '#AAAAAA',
                          position: 'absolute',
                          left: '-49px',
                          top: 0,
                          bottom: 0,
                          margin: 'auto'
                        }
                      },
                      [`@media screen and (min-width: ${breakpoints.lg})`]: {
                        '&::before': {
                          content: '""',
                          height: '4px',
                          width: '56px',
                          background:
                            step.past || step.current ? '#006217' : '#AAAAAA',
                          position: 'absolute',
                          left: '-60px',
                          top: 0,
                          bottom: 0,
                          margin: 'auto'
                        }
                      }
                    }
                  : {}
              }
            >
              {step.past ? (
                <StepSuccessCheck
                  color="red"
                  position="absolute"
                  w="34px"
                  h="34px"
                />
              ) : (
                <Box as="span">{step.number}</Box>
              )}
            </Box>
            <Text
              position="absolute"
              whiteSpace="nowrap"
              mt="8px"
              fontSize="0.875rem"
              fontWeight="bold"
              left="-5px"
              right="0"
              color={
                step.past ? '#006217' : step.current ? '#001E64' : '#AAAAAA'
              }
            >
              Etapa {step.number}
            </Text>
          </Box>
        ))}
    </HStack>
  );
};
