import { useEffect } from 'react';
import {
  Route,
  Routes as PublicRoutes,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';

import { Conclusao } from '../pages/Conclusao';
import { CondicoesSaudeInicio } from '../pages/CondicoesSaudeInicio';
import CondicoesSaudeQuestionario from '../pages/CondicoesSaudeQuestionario';
import { DadosCadastraisFormularios } from '../pages/DadosCadastraisFormularios';
import { DadosCadastraisInicio } from '../pages/DadosCadastraisInicio';
import { Home } from '../pages/Home';
import { Pagamento } from '../pages/Pagamento';
import { Resumo } from '../pages/Resumo';

export const AppRoutes = (): JSX.Element => {
  return (
    <>
      <PublicRoutes>
        <Route path="/" element={<Home />} />
        <Route path="/preview" element={<Home isPreview />} />
        <Route
          path="/dados-cadastrais/inicio/product=:idProductSelected"
          element={<DadosCadastraisInicio />}
        />
        <Route
          path="/dados-cadastrais/formularios/product=:idProductSelected&cpf=:cpf&user=:idUser"
          element={<DadosCadastraisFormularios />}
        />
        <Route
          path="/condicoes-saude/inicio/product=:idProductSelected&cpf=:cpf&user=:idUser"
          element={<CondicoesSaudeInicio />}
        />
        <Route
          path="/condicoes-saude/questionario/product=:idProductSelected&cpf=:cpf&user=:idUser"
          element={<CondicoesSaudeQuestionario />}
        />
        <Route
          path="/resumo/product=:idProductSelected&cpf=:cpf&user=:idUser"
          element={<Resumo />}
        />
        <Route
          path="/pagamento/product=:idProductSelected&cpf=:cpf&user=:idUser"
          element={<Pagamento />}
        />
        <Route path="/conclusao" element={<Conclusao />} />

        {/* <Route path="nao-autorizado" element={<NotAuthorized />} /> */}
      </PublicRoutes>
    </>
  );
};
