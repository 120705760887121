import { Text } from '@chakra-ui/react';

export const ConsentTerm = () => {
  return (
    <Text
      textAlign="center"
      fontSize={['0.75rem', '1rem']}
      fontWeight="400"
      lineHeight={['1.5rem', '1.75rem']}
      color="#323232"
    >
      Ao enviar seus dados você concorda com nossa&nbsp;
      <span style={{ color: '#FF5000' }}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://portal.sulamericaseguros.com.br/politica-de-privacidade/#:~:text=Pol%C3%ADtica%20de%20Privacidade%20da%20SulAm%C3%A9rica,nos%20nossos%20sites%20e%20aplicativos."
        >
          política de privacidade
        </a>
      </span>
    </Text>
  );
};
