const CORRETOR_INVALIDO = 742;
const CORRETOR_NAO_ENCONTRADO = 743;

export const getCodigoCritica = (s: string) => {
  return Number(s.split(' - ')[0])
}

export const tratarCriticaCvp = (s: string[]) => {
  const criticasArray = s;

  let crititcasString = '';

  for (let i = 0; i < criticasArray.length; i++) {
    const critica = criticasArray[i];
    if (getCodigoCritica(critica) != CORRETOR_INVALIDO && getCodigoCritica(critica) != CORRETOR_NAO_ENCONTRADO) {
      crititcasString += critica.replace(/[^a-zA-Z: \u00C0-\u00FF]/g, '').replace(/\s\s+/g, ' ').trim() + '; ';
    }
  }

  return crititcasString;
}
