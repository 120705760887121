import {
  Box,
  Checkbox,
  HStack,
  List,
  ListItem,
  Spinner,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import {
  mockedDoencasGraves,
  mockedInteresses
} from '../../mocks/mockedDoencasGraves';

export const BoxDoencasGraves = ({
  coverage,
  doencasCobertura,
  tabelaDoencasSelecionaveis,
  coverageStates,
  setCoverageStates,
  isProductVariable
}: {
  coverage: any;
  doencasCobertura: any[];
  tabelaDoencasSelecionaveis: any;
  coverageStates: any;
  isProductVariable: boolean;
  setCoverageStates: (states: any) => void;
}) => {
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    console.log('tabela selecionavel', tabelaDoencasSelecionaveis);
    if (tabelaDoencasSelecionaveis.id_tabela_doenca) {
      setShowTable(true);
    }
  }, [tabelaDoencasSelecionaveis]);

  useEffect(() => {
    console.log('doencas estado', coverageStates);
  }, [coverageStates]);

  const exibirDoencasSelecionaveis =
    tabelaDoencasSelecionaveis.doencas?.filter(
      (doenca: any) => !doenca.obrigatoria
    )?.length > 0;

  return showTable ? (
    <Box
      w="100%"
      bg="#F4F4F4"
      p={['10px', '15px']}
      borderRadius="10px"
      mt={['15px', '30px']}
      maxH={['110px', '410px']}
      overflowY="scroll"
    >
      {isProductVariable ? (
        <>
          <Text
            color="#323232"
            fontSize="0.875rem"
            lineHeight="1.5rem"
            fontWeight="700"
          >
            Doenças já inclusas:
          </Text>
          <List
            spacing="16px"
            p={['10px 0 20px', '20px 0 30px !important']}
            borderBottom={
              exibirDoencasSelecionaveis ? '1px solid #AAAAAA' : undefined
            }
          >
            {doencasCobertura.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  color="#323232"
                  fontSize="0.875rem"
                  lineHeight="1.5rem"
                  fontWeight="400"
                >
                  {
                    tabelaDoencasSelecionaveis.doencas?.filter(
                      (doenca: any) => {
                        return (
                          doenca.id_doenca === item.id_doenca &&
                          doenca.obrigatoria
                        );
                      }
                    )[0]?.descricao
                  }
                </ListItem>
              );
            })}
          </List>
          {exibirDoencasSelecionaveis ? (
            <>
              <Text
                color="#323232"
                fontSize="0.875rem"
                lineHeight="1.5rem"
                fontWeight="700"
                mt={['20px', '30px']}
              >
                Personalize de acordo com seus interesses:
              </Text>
              <List spacing="16px" p="20px 0 important">
                {tabelaDoencasSelecionaveis.doencas?.map(
                  (doenca: any, index: number) => {
                    const isChecked = coverageStates[
                      coverage.id
                    ]?.doencas_table?.doencas
                      .map((doenca: any) => doenca.id_doenca)
                      .includes(doenca.id_doenca);

                    console.log('está marcado', isChecked);
                    return !doenca.obrigatoria ? (
                      <HStack key={index} gap="8px">
                        <Checkbox
                          w="12px"
                          h="10px"
                          colorScheme="orange"
                          isChecked={isChecked}
                          onChange={(e) => {
                            if (!isChecked) {
                              coverageStates[
                                coverage.id
                              ].doencas_table.doencas.push({
                                id_doenca: doenca.id_doenca,
                                obrigatoria: doenca.obrigatoria
                              });
                            } else {
                              const coberturasSemASelecionada = coverageStates[
                                coverage.id
                              ].doencas_table.doencas.filter((dnc: any) => {
                                return dnc.id_doenca != doenca.id_doenca;
                              });

                              coverageStates[
                                coverage.id
                              ].doencas_table.doencas = coberturasSemASelecionada;
                            }

                            console.log(
                              'cobertura que não é estado',
                              coverage.tabela_doencas
                            );
                            setCoverageStates({ ...coverageStates });
                            console.log('mudando', coverageStates);
                          }}
                        />
                        <ListItem
                          color="#323232"
                          fontSize="0.875rem"
                          lineHeight="1.5rem"
                          fontWeight="400"
                        >
                          {doenca.descricao}
                        </ListItem>
                      </HStack>
                    ) : undefined;
                  }
                )}
              </List>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Text
            color="#323232"
            fontSize="0.875rem"
            lineHeight="1.5rem"
            fontWeight="700"
          >
            Doenças inclusas:
          </Text>
          <List spacing="16px" p={['10px 0 20px', '20px 0 30px !important']}>
            {doencasCobertura.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  color="#323232"
                  fontSize="0.875rem"
                  lineHeight="1.5rem"
                  fontWeight="400"
                >
                  {
                    tabelaDoencasSelecionaveis.doencas?.filter(
                      (doenca: any) => {
                        return (
                          doenca.id_doenca === item.id_doenca &&
                          (doenca.obrigatoria || doenca.default_checked)
                        );
                      }
                    )[0]?.descricao
                  }
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </Box>
  ) : (
    <Box width="100%" display="fex" justifyContent="center" pt="30px" pb="30px">
      <Spinner />
    </Box>
  );
};
