import { Box, Button, HStack, Icon, Text } from '@chakra-ui/react';

import { CheckoutType } from '../../types/CheckoutType';
import { getNumberFormat } from '../../utils/getNumberFormat';

const NUMBER_ZERO = 0;

export const CardCheckout = ({
  isDisable,
  iconProduct,
  title,
  protectionValue,
  installmentValue,
  frequency,
  onClick
}: CheckoutType) => {
  const getinstallmentValue = () => {
    return installmentValue
      ? getNumberFormat(installmentValue.toFixed(2))
          .split(',')[0]
          .replace('R$', '')
      : '0';
  };

  const getInstallmentCents = () => {
    return installmentValue ? installmentValue.toFixed(2).split('.')[1] : '00';
  };
  return (
    <Box
      bg="#FFFFFF"
      w="100%"
      p={['20px 55px 20px 45px', '40px 40px 30px 54px']}
      borderLeft="10px solid #FF5000"
      borderRadius="10px"
      boxShadow="0px 4px 10px rgba(115, 137, 169, 0.25)"
      display={['auto', 'flex']}
      justifyContent="center"
      alignItems="center"
    >
      <Box mr="60px" display={['none', 'flex']}>
        <Icon as={iconProduct} w="110px" h="110px" />
      </Box>
      <Box
        m="0 !important"
        w="100%"
        display="flex"
        flexDirection="column"
        alignItems={['center', 'start']}
        gridGap="10px"
      >
        <Text
          m="0 !important"
          color="#001E64"
          fontSize={['1.5rem', '2.5rem']}
          lineHeight={['2rem', '3.5rem']}
          fontWeight="700"
        >
          {title}
        </Text>
        <Text
          m="0 !important"
          color="#001E64"
          fontSize="0.875rem"
          lineHeight="1.5rem"
          fontWeight="400"
          display={['flex', 'none']}
        >
          com o valor de proteção de
          {protectionValue
            ? getNumberFormat(protectionValue.toFixed(2))
            : NUMBER_ZERO.toFixed(2).replace('.', ',')}
        </Text>
        <Box m="0 !important" display={['none', 'block']}>
          <Text
            m="0 !important"
            color="#001E64"
            fontSize="0.875rem"
            lineHeight="1.5rem"
            fontWeight="700"
          >
            com o valor de proteção de
          </Text>
          <Text
            m="0 !important"
            color="#001E64"
            fontSize="1.5rem"
            lineHeight="2rem"
            fontWeight="700"
          >
            {protectionValue
              ? getNumberFormat(protectionValue.toFixed(2))
              : getNumberFormat(NUMBER_ZERO.toFixed(2))}
          </Text>
        </Box>
      </Box>
      <Text
        m={['20px 0 12px !important', '0 !important']}
        color="#001E64"
        fontSize="0.875rem"
        lineHeight="1.5rem"
        fontWeight="700"
        display="flex"
        justifyContent="center"
        alignItems={['end', 'start']}
        gridGap="0px"
        w={['100%', '53%']}
        height="64px"
      >
        R${' '}
        <Box display="flex" height="100%" alignItems="center">
          <span style={{ fontSize: '4rem' }}>{getinstallmentValue()}</span>
          <span style={{ fontSize: '2.5rem' }}>,{getInstallmentCents()}</span>
        </Box>{' '}
        por {frequency}
      </Text>
      <Button
        disabled={isDisable}
        m={{ lg: '0 0 0 50px !important', xl: '0 0 0 160px !important' }}
        w="174px"
        display={['none', 'flex']}
        justifyContent="center"
        onClick={onClick}
        bg="#FF5000"
        p="12px 40px"
        color="#FFFFFF"
      >
        CONTRATAR
      </Button>
    </Box>
  );
};
