import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useSubdomain = () => {
  const [urlSearchParams] = useSearchParams();
  const subdominioParam = urlSearchParams.get('subdominio');
  const host = window.location.host;

  if (subdominioParam) return subdominioParam;

  const subdomain = host.split('.')[0];
  if (!['sas-comercial-whitelabel-hml', 'localhost:3000'].includes(subdomain)) {
    return subdomain;
  } else {
    return undefined;
  }
};

export const useQueryParam = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
