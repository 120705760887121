import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { delay } from '../../utils/time-utils';

type ModalLoadProps = {
  isOpenModal: boolean;
  content: string;
  contentArray?: Array<string>;
  duration?: number;
};

export const ModalLoad = ({
  isOpenModal,
  content,
  contentArray = [],
  duration = 0
}: ModalLoadProps) => {
  const [modalContent, setModalContent] = useState('');

  const asyncLoop = async (array: string[], duration: number) => {
    for (const item of array) {
      setModalContent(item);
      console.log('rerenderizou');
      await delay(duration / contentArray.length);
    }
  };

  useEffect(() => {
    if (contentArray.length === 0) {
      setModalContent(content);
    } else {
      asyncLoop(contentArray, duration);
    }
  }, [isOpenModal]);

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        onClose={() => {
          /**/
        }}
      >
        <ModalOverlay bg="#00000056" />
        <ModalContent
          m="0 !important"
          position="absolute"
          top="40%"
          padding="30px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="255px !important"
          height="160px !important"
          maxWidth="none !important"
          borderRadius="0 !important"
          background="#FFFFFF"
          boxShadow="0px 0px 5px rgba(50, 50, 50, 0.25)"
        >
          <Spinner />
          <Text
            m="20px 0 0 0"
            fontSize="0.875rem"
            lineHeight="1.25rem"
            fontWeight="700"
            color="#000000"
          >
            {modalContent}
          </Text>
        </ModalContent>
      </Modal>
    </>
  );
};
