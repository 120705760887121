export const pushToDataLayer = ({ event, data }: { event: string; data: Record<string, any> }) => {
  const windowObject: any = window;

  windowObject.dataLayer = windowObject.dataLayer || [];
  windowObject.dataLayer?.push({
    event: event,
    ...data
  });

  console.log('pushed to data layer')
}