import { Box, Button, Input, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { apiSensedia } from '../api/api-sensedia';
import { ButtonNavegate } from '../components/buttons/ButtonNavegate';
import { QuizCondicoesSaude } from '../components/contents/QuizCondicoesSaude';
import { LayoutContent } from '../components/layouts/LayoutContent';
import { ModalConfirm } from '../components/modals/ModalConfirm';
import { ModalLoad } from '../components/modals/ModalLoad';
// import { mockedQuestions } from '../mocks/mockedQuestions';
import { getParams } from '../utils/getParams';
import { wlLocalStorage } from '../utils/localStorage.util';

type RespostaType = {
  descricao_resposta: string;
  id_pergunta: number;
  justificativa_resposta?: string;
  valor_resposta: string;
}[];

export default function CondicoesSaudeQuestionario() {
  // const questions = mockedQuestions;
  const query = location.pathname;

  const parts = query.split('&');

  const idProduto = Number(parts[0].replace(/[^0-9,]/gi, ''));

  const cardQuestionario = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [maxWidth, setMaxWidth] = useState('');

  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const getQuestionStateFromStorage = () => {
    const respostasDPSA = JSON.parse(
      wlLocalStorage.getItem('respostasDPSA')
    ) as {
      id_pergunta: number;
      descricao_resposta: string;
      justificativa_resposta: string;
      valor_resposta: string;
    }[];

    if (!respostasDPSA) {
      return null;
    }

    const questions = {} as any;
    respostasDPSA.forEach((res, index) => {
      questions[res.id_pergunta] = {
        option: res.valor_resposta,
        questionNumber: index,
        questionType: res.valor_resposta ? 'F' : 'D',
        questionDescription:
          res.descricao_resposta || res.justificativa_resposta
      };
    });

    return questions;
  };

  const [listaPerguntasDPSA, setListaPerguntasDPSA] = useState<
    {
      alternativa_obs_obrigatoria: string;
      id_pergunta: number;
      codigo: number;
      numero: number;
      descricao: string;
      tipo_pergunta: string;
    }[]
  >([]);

  const [questionsState, setQuestionsState] = useState<any>(
    getQuestionStateFromStorage() || {}
  );

  const getQuestionarioDPSA = async () => {
    console.log(idProduto);
    setOpenModal(true);
    const api = await apiSensedia();

    try {
      const res = await api.get(`/v1/produtos/${idProduto}/questionario-dpsa`);

      console.log('tabela retornada', res.data);

      const idPerguntaPesoAltura = res.data.perguntas?.filter(
        (el: any) => el.descricao === '1. Informar peso e altura aproximados'
      )[0].id_pergunta;

      wlLocalStorage.setItem('id_pergunta_peso_altura', idPerguntaPesoAltura);

      setListaPerguntasDPSA(
        res.data.perguntas?.filter(
          (el: any) => el.descricao !== '1. Informar peso e altura aproximados'
        )
      );
      setOpenModal(false);
    } catch (e: any) {
      if (e.response.status == 500) {
        console.log('ocorreu internal server error', e.response.statusText);
        console.log('ocorreu internal server error', e.response.data);
      } else {
        console.log('ocorreu gateway timeout', e.response.data);
      }
      // if (e.response.status != 504) {
      //   looping = false;
      // }
    }
  };

  const areAllQuestionsAnswered = () => {
    let allAnswered = true;

    let elements: any[] = Object.entries(questionsState);

    if (elements.length < listaPerguntasDPSA.length) {
      allAnswered = false;
    }

    elements = elements.map((item) => item[1]);

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].option === 'S' && !elements[i].questionDescription) {
        allAnswered = false;
      }
      if (
        elements[i].questionType === 'D' &&
        !elements[i].questionDescription
      ) {
        allAnswered = false;
      }
    }

    console.log(elements);

    return allAnswered;
  };

  const handleAnsweredQuestions = () => {
    const respostas: RespostaType = [];

    listaPerguntasDPSA.forEach((itemPerguta) => {
      const dadosResposta = questionsState[itemPerguta.id_pergunta];
      if (dadosResposta.option === 'D') {
        respostas.push({
          id_pergunta: itemPerguta.id_pergunta,
          descricao_resposta: dadosResposta.questionDescription,
          valor_resposta: dadosResposta.option
        });
      } else {
        respostas.push({
          id_pergunta: itemPerguta.id_pergunta,
          descricao_resposta: dadosResposta.questionDescription,
          justificativa_resposta: dadosResposta.questionDescription,
          valor_resposta: dadosResposta.option
        });
      }
    });

    if (wlLocalStorage.getItem('id_pergunta_peso_altura')) {
      const descricao = `Peso: ${wlLocalStorage.getItem(
        'weight'
      )}kg Altura: ${Number(Number(wlLocalStorage.getItem('height')) / 100)
        .toFixed(2)
        .replace('.', ',')}`;

      respostas.push({
        id_pergunta: Number(wlLocalStorage.getItem('id_pergunta_peso_altura')),
        descricao_resposta: descricao,
        justificativa_resposta: descricao,
        valor_resposta: undefined as any
      });
    }

    console.log('respostas no formato do cvp', respostas);

    return respostas;
  };

  useEffect(() => {
    areAllQuestionsAnswered();
  }, [questionsState]);
  useEffect(() => {
    getQuestionarioDPSA();
  }, []);

  useEffect(() => {
    setMaxWidth(cardQuestionario.current?.clientWidth.toString());
  });

  return (
    <LayoutContent currentStep={0} zIndex={10} title={'Condições de Saúde'}>
      <Text
        mb="16px !important"
        fontSize={{ base: '0.875rem', lg: '1rem' }}
        fontWeight={400}
        lineHeight={{ base: '1.5rem', lg: '1.5rem' }}
        color="#323232"
      >
        <b>Responda as alternativas</b> abaixo de acordo com sua condição de
        saúde atual.
      </Text>
      <Box display="flex" flexDirection="column" gridGap="20px">
        <QuizCondicoesSaude
          reference={cardQuestionario}
          questions={listaPerguntasDPSA}
          questionsInternalState={questionsState}
          setQuestionsInternalState={setQuestionsState}
        />
      </Box>
      <Box
        w="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        m="30px 0 !important"
        maxW={maxWidth}
      >
        <ButtonNavegate
          disable={!areAllQuestionsAnswered()}
          actionPrev={() => {
            history.back();
          }}
          actionNext={() => {
            const respostasDPSA = handleAnsweredQuestions();
            console.log('respostas', [...respostasDPSA]);
            wlLocalStorage.setItem(
              'respostasDPSA',
              JSON.stringify([...respostasDPSA])
            );
            setOpenModalConfirm(true);
          }}
        />
      </Box>
      {openModalConfirm && (
        <ModalConfirm
          hrefNext={`/resumo/${getParams(query)}`}
          handleClose={() => setOpenModalConfirm(false)}
        />
      )}
      <ModalLoad isOpenModal={openModal} content={'Carregando...'} />
    </LayoutContent>
  );
}
