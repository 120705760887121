export const getNumberFormat = (value: string) => {
  const valueInNumber = Number(value);

  const valueInReal = valueInNumber.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });

  return valueInReal;
};
