import { createIcon } from '@chakra-ui/react';

export const LinkedinIcon = createIcon({
  displayName: 'LinkedinIcon',
  viewBox: '0 0 45 46',
  path: [
    <circle key="1" cx="22.5" cy="23" r="22.5" fill="#0A66C2" />,
    <path
      key="2"
      d="M27.7716 31.9689H31.4984L31.5 25.3847C31.5 22.1523 30.8035 19.6677 27.0262 19.6677C25.5822 19.6141 24.2257 20.3591 23.4962 21.6065H23.4461V19.9662H19.8683V31.9686H23.5952V26.0311C23.5952 24.4654 23.8921 22.9489 25.8332 22.9489C27.7467 22.9489 27.7716 24.7406 27.7716 26.1324V31.9689Z"
      fill="white"
    />,
    <path
      key="3"
      d="M13.5 16.1632C13.5002 17.3577 14.4687 18.3258 15.6631 18.3256C16.2367 18.3255 16.7868 18.0975 17.1923 17.6918C17.5978 17.2862 17.8256 16.736 17.8255 16.1624C17.8253 14.9679 16.8568 13.9998 15.6624 14C14.4679 14.0002 13.4998 14.9687 13.5 16.1632Z"
      fill="white"
    />,
    <path
      key="4"
      d="M13.7958 31.9689H17.5266V19.9662H13.7958V31.9689Z"
      fill="white"
    />
  ]
});
