import { HStack, Icon, Link, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { AdvantageType } from '../../types/AdvantageType';
import { ModalDetailAdvantage } from '../modals/ModalDetailAdvantage';

type SectionCheckoutProps = {
  advantages: AdvantageType[];
  idProduto: number;
};

export const SectionResumo = ({
  advantages,
  idProduto
}: SectionCheckoutProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [content, setContent] = useState<AdvantageType>();

  const handleModal = (content: AdvantageType) => {
    setContent(content);
    setIsOpenModal(true);
  };

  return (
    <HStack
      w="100%"
      bg="#FFFFFF"
      p={['36px 0', '40px 0']}
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Text
        mb={['30px', '50px']}
        color="#323232"
        fontSize={['1rem', '1.5rem']}
        lineHeight={['1.5rem', '2rem']}
        fontWeight="700"
      >
        <span style={{ color: '#FF5000' }}>+ benefícios </span>grátis inclusos
        no seu plano
      </Text>
      <HStack
        m="0 !important"
        w="100%"
        maxW="665px"
        flexWrap="wrap"
        justify="center"
        align="start"
        gap="95px"
      >
        {advantages.map((advantage, index) => {
          return (
            <HStack
              key={index}
              m="0 !important"
              maxW="95px"
              flexDirection="column"
              align="center"
              gap={['10px', '15px']}
            >
              <Icon
                cursor="pointer"
                as={advantage.icon}
                w={['65px', '95px']}
                h={['65px', '95px']}
                bg="#FF5000"
                borderRadius="50%"
                onClick={() => handleModal(advantage)}
                _hover={{
                  bg: '#001E64'
                }}
              />
              <Text
                m="0 !important"
                color="#323232"
                fontSize="1rem"
                lineHeight="1.5rem"
                fontWeight="700"
              >
                {advantage.label}
              </Text>
              {isOpenModal && (
                <ModalDetailAdvantage
                  content={content}
                  handleClose={() => setIsOpenModal(false)}
                />
              )}
            </HStack>
          );
        })}
      </HStack>
      <Text
        m={['10px 0 16px !important', '20px 0 0 0 !important']}
        p={['0 78px', '0']}
        color="#323232"
        fontSize={['0.75rem', '1rem']}
        lineHeight={['1rem', '1.5rem']}
        fontWeight="400"
        textAlign={['center', 'start']}
      >
        Para saber mais sobre os benefícios?&nbsp;
        <Link
          color="#146CFA"
          href={`/documentos/guia_beneficios_${idProduto}.pdf`}
          target="_blank"
        >
          Veja o Guia de Benefícios
        </Link>
      </Text>
    </HStack>
  );
};
