import { createIcon } from '@chakra-ui/react';

export const PencilIconOrange = createIcon({
  displayName: 'PencilIconOrange',
  viewBox: '0 0 26 24',
  path: [
    <path
      key="1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.545 0C20.2117 0 19.865 0.133333 19.6117 0.386667L17.1717 2.82667L22.1717 7.82667L24.6117 5.38667C25.1317 4.86667 25.1317 4.02667 24.6117 3.50667L21.4917 0.386667C21.225 0.12 20.8917 0 20.545 0ZM15.745 8.02667L16.9717 9.25333L4.89167 21.3333H3.665V20.1067L15.745 8.02667ZM0.998337 19L15.745 4.25333L20.745 9.25333L5.99834 24H0.998337V19Z"
      fill="#FF5000"
    />
  ]
});
