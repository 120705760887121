import { createIcon } from '@chakra-ui/react';

export const DoencasGravesIcon = createIcon({
  displayName: 'DoencasGravesIcon',
  viewBox: '0 0 50 44',
  path: [
    <path
      key="1"
      d="M11.6303 18.874C14.2093 18.874 16.3145 16.7689 16.3145 14.1898C16.3145 11.6107 14.2094 9.50551 11.6303 9.50551C9.05121 9.50551 6.94602 11.6106 6.94602 14.1898C6.94602 16.7688 9.05111 18.874 11.6303 18.874ZM11.6303 11.4532C13.1564 11.4532 14.3672 12.6636 14.3672 14.1901C14.3672 15.7163 13.1568 16.9271 11.6303 16.9271C10.1041 16.9271 8.89331 15.7167 8.89331 14.1901C8.89331 12.6636 10.1038 11.4532 11.6303 11.4532Z"
      fill="#146CFA"
    />,
    <path
      key="2"
      d="M32.6317 8.87019H34.8423C35.2109 8.87019 35.5791 8.65982 35.737 8.29122L36.1581 7.34403L37.8949 11.2914C38.0528 11.66 38.4214 11.8704 38.7896 11.8704C39.1582 11.8704 39.5265 11.66 39.6843 11.2914L40.7369 8.92308H42.3159C42.8424 8.92308 43.2631 8.50198 43.2631 7.9759C43.2631 7.44943 42.842 7.02871 42.3159 7.02871H40.1054C39.7368 7.02871 39.3686 7.23908 39.2107 7.60768L38.7896 8.55487L37.0527 4.60747C36.8949 4.23887 36.5263 4.0285 36.158 4.0285C35.7894 4.0285 35.4212 4.23887 35.2633 4.60747L34.2108 6.97581H32.6317C32.1053 6.97581 31.6846 7.39692 31.6846 7.923C31.6846 8.44909 32.0528 8.87019 32.6317 8.87019Z"
      fill="#146CFA"
    />,
    <path
      key="3"
      d="M15.5264 19.5489C15.6318 20.8647 16.3686 22.0751 17.4737 22.7594L21.3686 25.0753C22.3158 25.6543 23.4212 25.97 24.5267 25.97H45.0005C46.4217 25.97 47.5796 24.8121 47.5796 23.3384C47.5796 22.0226 46.6324 20.9172 45.3166 20.7594L29.0533 18.4964C28.4743 18.4439 27.9478 18.286 27.4218 18.0753L21.0003 15.549C19.6845 15.0226 18.1584 15.2858 17.0529 16.128C16 16.9698 15.4211 18.2331 15.5264 19.5489ZM18.2634 17.6541C18.8423 17.1805 19.6317 17.0752 20.316 17.3384L26.7897 19.8121C27.474 20.0753 28.158 20.2332 28.8948 20.3386L45.158 22.6016C45.4738 22.6541 45.737 22.9173 45.737 23.233C45.737 23.6016 45.4213 23.9174 45.0527 23.9174H24.5788C23.7895 23.9174 23.0527 23.707 22.3683 23.3384L18.4734 21.0225C17.8945 20.6539 17.5262 20.0753 17.4733 19.391C17.4208 18.7067 17.6844 18.0752 18.2634 17.6541Z"
      fill="#146CFA"
    />,
    <path
      key="4"
      d="M30.7376 15.3422H44.1588C45.6325 15.3422 46.7904 14.1843 46.7904 12.7106V3.1316C46.7904 1.6579 45.6325 0.5 44.1588 0.5H30.7376C29.264 0.5 28.106 1.6579 28.106 3.1316V12.7106C28.106 14.1314 29.264 15.3422 30.7376 15.3422ZM30.0537 3.1316C30.0537 2.763 30.3694 2.44727 30.738 2.44727H44.1592C44.5278 2.44727 44.8435 2.76301 44.8435 3.1316V12.7106C44.8435 13.0792 44.5278 13.3949 44.1592 13.3949L30.738 13.3946C30.3694 13.3946 30.0537 13.0788 30.0537 12.7103V3.1316Z"
      fill="#146CFA"
    />,
    <path
      key="5"
      d="M47.5793 28.0774H23.6844C22.5265 28.0774 21.3685 27.7616 20.3159 27.1827L6.00005 18.8668V17.3931C6.00005 16.1299 4.99994 15.1301 3.73706 15.1301H2.26299C0.999726 15.1301 0 16.1302 0 17.3931V43.1302C0 44.3934 1.0001 45.3932 2.26299 45.3932H3.73668C4.99994 45.3932 5.99967 44.3931 5.99967 43.1302L6.00004 35.4986H44.0003V43.0777C44.0003 44.341 45.0004 45.3407 46.2633 45.3407H47.737C49.0003 45.3407 50 44.3406 50 43.0777V30.4461C50.0004 29.1828 48.8951 28.0774 47.5793 28.0774ZM18.0001 28.0774H6.00005V21.1301L18.0001 28.0774ZM4.05266 43.1301C4.05266 43.288 3.89479 43.4459 3.73693 43.4459H2.26286C2.10499 43.4459 1.94712 43.288 1.94712 43.1301V17.3405C1.94712 17.1826 2.10499 17.0247 2.26286 17.0247H3.73655C3.89442 17.0247 4.05229 17.1826 4.05229 17.3405L4.05266 43.1301ZM5.99995 30.0248H47.5792C47.8425 30.0248 48.0528 30.2351 48.0528 30.4984V33.551H5.99987L5.99995 30.0248ZM47.7371 43.4459H46.2634C46.1056 43.4459 45.9477 43.2881 45.9477 43.1302L45.9473 35.4986H48.0524V43.0777C48.0528 43.2881 47.895 43.4459 47.7371 43.4459Z"
      fill="#146CFA"
    />
  ]
});
