import { createIcon } from '@chakra-ui/react';

export const TwitterIcon = createIcon({
  displayName: 'TwitterIcon',
  viewBox: '0 0 45 46',
  path: [
    <circle key="1" cx="22.5" cy="23" r="22.5" fill="#1D9BF0" />,
    <path
      key="2"
      d="M31.8967 18.6677C31.9104 18.8711 31.9104 19.0746 31.9104 19.2798C31.9104 25.5353 27.2669 32.7498 18.7761 32.7498V32.746C16.2678 32.7498 13.8117 32.013 11.7002 30.6237C12.0649 30.6687 12.4315 30.6912 12.7989 30.6921C14.8775 30.694 16.8967 29.9787 18.532 28.6616C16.5567 28.6232 14.8245 27.3024 14.2194 25.3741C14.9113 25.5109 15.6243 25.4828 16.3035 25.2925C14.1499 24.8463 12.6006 22.9058 12.6006 20.6522C12.6006 20.6316 12.6006 20.6119 12.6006 20.5922C13.2422 20.9588 13.9607 21.1622 14.6956 21.1847C12.6673 19.7945 12.0421 17.0272 13.2669 14.8636C15.6106 17.8212 19.0686 19.6192 22.7806 19.8095C22.4086 18.1652 22.9168 16.4422 24.1161 15.2864C25.9753 13.494 28.8995 13.5859 30.6472 15.4917C31.681 15.2826 32.6719 14.8936 33.5786 14.3424C33.234 15.4383 32.5128 16.3691 31.5494 16.9606C32.4644 16.85 33.3583 16.5988 34.2002 16.2154C33.5805 17.1678 32.7998 17.9974 31.8967 18.6677Z"
      fill="white"
    />
  ]
});
