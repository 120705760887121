import { Checkbox, HStack, Stack } from '@chakra-ui/react';

import { ProductTypes } from '../../types';
import { getNumberFormat } from '../../utils/getNumberFormat';
import { getInstallmentOptions } from '../../utils/money.util';
import { RegistrationInput } from '../inputs/RegistrationInput';
import { RegistrationSelect } from '../inputs/RegistrationSelect';

type FormDebitoAutomaticoProps = {
  price: number;
  isHolder: boolean;
  setIsHolder: (newState: boolean) => void;
  cpf: string;
  setCpf: (newState: string) => void;
  installments: string;
  productType: ProductTypes;
  setInstallments: (newState: string) => void;
  validity: string;
  setValidity: (newState: string) => void;
  bank: string;
  setBank: (newState: string) => void;
  bankAgency: string;
  setBankAgency: (newState: string) => void;
  bankAccount: string;
  setBankAccount: (newState: string) => void;
  verifyingDigit: string;
  setVerifyingDigit: (newState: string) => void;
};

export const FormDebitoAutomatico = ({
  price,
  isHolder,
  setIsHolder,
  cpf,
  setCpf,
  installments,
  productType,
  setInstallments,
  validity,
  setValidity,
  bank,
  setBank,
  bankAgency,
  setBankAgency,
  bankAccount,
  setBankAccount,
  verifyingDigit,
  setVerifyingDigit
}: FormDebitoAutomaticoProps) => {
  const optionsVencimento = Array.from(
    { length: 31 },
    (_, index) => index + 1
  ).map((el) => ({ option: String(el) }));

  const installmentOptions = getInstallmentOptions(price, productType);

  return (
    <Stack
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      p={['40px 20px', '40px 20px', '30px 50px 30px 30px']}
      bg="#FFFFFF"
      m="5px 0 0 0 !important"
      maxW="850px"
      w="100%"
      borderRadius="0 !important"
    >
      <HStack
        m="0 !important"
        w="100%"
        display="flex"
        gap={['0px', '0px', '30px']}
        flexDirection={['column', 'column', 'row']}
        alignItems="end"
      >
        <RegistrationSelect
          id="dcc-codigo-banco"
          placeholder={'Banco*'}
          textLabel={bank ? 'Banco*' : ''}
          textErrorMessage={bank === '' ? 'Campo Obrigatório' : ''}
          value={bank}
          setValue={setBank}
          options={[
            {
              option: '033 - Banco Santander (Brasil) S.A.'
            },
            {
              option: '001 - Banco do Brasil S.A.'
            },
            {
              option: '037 - Banco do Estado do Pará S.A.'
            },
            {
              option: '237 - Banco Bradesco S.A.'
            },
            {
              option: '341 - Itaú Unibanco S.A.'
            },
            {
              option: '756 - Banco Cooperativo do Brasil S/A'
            }
          ]}
        />
        <RegistrationInput
          id="dcc-numero-cpf"
          type={'text'}
          mask={'999.999.999.99'}
          placeholder={'CPF*'}
          textLabel={cpf ? 'CPF*' : ''}
          textErrorMessage={
            cpf === ''
              ? 'Campo Obrigatório'
              : cpf.replaceAll('_', '').replaceAll('.', '').length < 11
              ? 'CPF inválido'
              : ''
          }
          value={cpf}
          setValue={setCpf}
        />
        <Checkbox
          id="dcc-titular-conta"
          width="100%"
          isChecked={isHolder}
          onChange={() => setIsHolder(!isHolder)}
          m={['30px 0 0 0 !important', '30px 0 0 0 !important', '0 !important']}
          colorScheme="orange"
          color="black"
          fontSize="1rem"
          lineHeight="1.5rem"
          fontWeight="400"
          display="flex"
          _hover={{}}
          _focus={{
            '&:focus': {
              boxShadow: 'none !important'
            }
          }}
        >
          Titular da conta
        </Checkbox>
      </HStack>
      <HStack
        m={['0 !important', '0 !important', '28px 0 !important']}
        w="100%"
        display="flex"
        gap={['0px', '0px', '0px', '30px']}
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <RegistrationInput
          id="dcc-agencia"
          type={'text'}
          mask={'9999'}
          placeholder={'Agência (sem dígito)*'}
          textLabel={bankAgency ? 'Agência (sem dígito)*' : ''}
          textErrorMessage={
            bankAgency === ''
              ? 'Campo Obrigatório'
              : bankAgency.replaceAll('_', '').length < 4
              ? 'Agência inválida'
              : ''
          }
          value={bankAgency.replace(/[^0-9,]/gi, '')}
          setValue={setBankAgency}
        />
        <RegistrationInput
          id="dcc-numero-conta"
          type={'text'}
          mask={''}
          placeholder={'Conta (sem dígito)*'}
          textLabel={bankAccount ? 'Conta (sem dígito)*' : ''}
          textErrorMessage={
            bankAccount === ''
              ? 'Campo Obrigatório'
              : bankAccount.replaceAll('_', '').length < 5
              ? 'Conta inválida'
              : ''
          }
          value={bankAccount.replace(/[^0-9,]/gi, '')}
          setValue={setBankAccount}
        />
        <RegistrationInput
          id="dcc-digito-verificador"
          type={'text'}
          mask={'9'}
          placeholder={'Dígito verificador*'}
          textLabel={verifyingDigit ? 'Dígito verificador*' : ''}
          textErrorMessage={verifyingDigit === '' ? 'Campo Obrigatório' : ''}
          value={verifyingDigit.replace(/[^0-9,]/gi, '')}
          setValue={setVerifyingDigit}
        />
      </HStack>
      <HStack
        p="0 0 10px"
        m="0 !important"
        w="100%"
        display="flex"
        gap={['0px', '0px', '30px']}
        flexDirection={['column', 'column', 'row']}
      >
        <RegistrationSelect
          id="dcc-data-vencimento"
          width={['100%', '100%', '50%']}
          placeholder={'Data do Vencimento*'}
          textLabel={validity ? 'Data do Vencimento*' : ''}
          textErrorMessage={validity === '' ? 'Campo Obrigatório' : ''}
          value={validity}
          setValue={setValidity}
          options={optionsVencimento}
        />
        <RegistrationSelect
          id="dcc-parcelamento"
          placeholder={'Parcelamento*'}
          textLabel={installments ? 'Parcelamento*' : ''}
          textErrorMessage={installments === '' ? 'Campo Obrigatório' : ''}
          value={installments}
          setValue={setInstallments}
          options={installmentOptions}
        />
      </HStack>
    </Stack>
  );
};
